import React, { useState, useEffect } from 'react';

import { Form, Header, Message, Select } from "semantic-ui-react";

import moment from "moment";
import axios from "axios";
import Global from "../../../../utils/Global";
import { getLocalStorage } from '../../../../utils/localStorage';
import { validateEmail , validateNumber } from '../../../../utils/Validations';
import { dniOptions } from '../../../../utils/ValuesOptions';

export default function PacientesUser(props) {

    const { infoUser, selectedUser } = props;

    useEffect(() => {
        document.getElementById("form-control-modify-patient-doctor").focus();
    }, []);

    const [doneMsg, setDoneMsg] = useState(false);
    const [isOK, setIsOK] = useState("messageUserData__fail");
    const [msg, setMsg] = useState("");

    const [loading, setLoading] = useState(false);

    const [resultsSearch, setResultsSearch] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [searching, setSearching] = useState(false);
    const [gotValues, setGotValues] = useState(false);
    const [selectedPatient, setSelectedPatient] = useState(null);

    const [formError, setFormError] = useState({});
    const [formData, setFormData] = useState({
        "_id": "",
        "name": "",
        "secondName": "",
        "lastname": "",
        "secondLastname": "",
        "dateBirth": "",
        "sex": "",
        "typeDNI": "",
        "dni": "",
        "obrasocial": "",
        "nro": "",
        "cellphone": "",
        "email": ""
    });

    const searchPatient = value => {
        setSearchValue(value);
        setSearching(true);
        setGotValues(false);
        setTimeout(function() {
            axios.post(Global.url + 'search-patient-doctor', { search: value, selectedUser: selectedUser }, { headers: { "authorization" : `Bearer ${getLocalStorage()}`} })
                .then(res => {
                    setResultsSearch(res.data.results);
                }).catch(err => {
                    setResultsSearch([]);
                }).finally(() => {
                    setSearching(false);
                    setGotValues(true);
            });
        }, 300);
    }

    const selectPatient = item => {
        setSearchValue("");
        setSelectedPatient(item);
        setGotValues(false);
        setResultsSearch([]);
        setFormData({
            "_id": item._id,
            "name": item.name,
            "secondName": item.secondName,
            "lastname": item.lastname,
            "secondLastname": item.secondLastname,
            "dateBirth": moment(item.dateBirth, "DD/MM/YYYY").format("YYYY-MM-DD"),
            "sex": item.sex,
            "typeDNI": item.typeDNI,
            "dni": item.dni,
            "obrasocial": item.obrasocial,
            "nro": item.nro,
            "cellphone": item.cellphone,
            "email": item.email
        });
    }

    const saveData = () => {
        if(selectedPatient === null) {
            setFormError({ patient: "Debes seleccionar un paciente. "});
        } else if(!formData.name) {
            setFormError({ name: "Este campo es obligatorio. "});
        } else if(!formData.lastname) {
            setFormError({ lastname: "Este campo es obligatorio. "});
        } else if(!formData.dni) {
            setFormError({ dni: "Este campo es obligatorio. "});
        } else if(formData.email && !validateEmail(formData.email)) {
            setFormError({ emailJob: "El email no es correcto. Si no desea introducir nada, déjelo en blanco. "});
        } else if(formData.cellphone && !validateNumber(formData.cellphone)) {
            setFormError({ cellphone: "El teléfono de celular no es válido. Si no desea introducir nada, déjelo en blanco. "});
        } else {
            setLoading(true);
            axios.post(Global.url + 'modify-patient-doctor', { formData: formData, selectedUser: selectedUser }, { headers: { "authorization" : `Bearer ${getLocalStorage()}`} })
                .then(res => {
                    setMsg(res.data.message);
                    if(res.data.status) {
                        setIsOK("messageUserData__success");
                    } else {
                        setIsOK("messageUserData__fail");
                    }
                }).catch(err => {
                    setIsOK("messageUserData__fail");
                    console.log(err);
                }).finally(() => {
                    setDoneMsg(true);
                    setLoading(false);
                });
        }
    }
    
    return (
        <>
            <Header as='h3' className="headerUserPanel">
                Modificar paciente existente
            </Header>
            <Form>
                <Form.Input
                    id="form-control-modify-patient-doctor"
                    onChange={e => searchPatient(e.target.value)}
                    fluid label='Paciente' 
                    name="patient"
                    value={searchValue}
                    error={formError.patient}
                    icon={searching ? "circle notch" : "search"}
                    placeholder='Buscar paciente (nombre, apellido o DNI)'
                />
                {gotValues &&
                    <div className="listValuesFound listValuesFound__Patients">
                        {resultsSearch.length === 0 && 
                            <div className="aValueFound">No se encontró ningún paciente que coincida. </div>
                        }
                        {resultsSearch.map((item) => (
                            <div 
                                onClick={() => selectPatient(item)} 
                                className="aValueFound"
                                key={item._id}
                            >
                                {item.name} {item.lastname} - <strong>DNI</strong>: {item.dni} - <strong>Obra social</strong>: {item.obrasocial}
                            </div>
                        ))}
                    </div>
                }
                {selectedPatient !== null &&
                    <div className="infoSelectedPatient">
                        <span className="textSelectedPatient"><strong>Paciente:</strong> <span>{selectedPatient.name} {selectedPatient.lastname}</span></span>
                        <span className="textSelectedPatient"><strong>Documento:</strong> <span>{selectedPatient.typeDNI} {selectedPatient.dni}</span></span>
                        <span className="textSelectedPatient"><strong>Obra social:</strong> <span>{selectedPatient.obrasocial} - {selectedPatient.nro}</span></span>
                    </div>
                }
                <Form.Group widths='equal'>
                    <Form.Input 
                        fluid label='Nombre' 
                        name="name"
                        required
                        value={formData.name}
                        placeholder='Nombre'
                        onChange={e => setFormData({...formData, [e.target.name]: e.target.value}) }
                    />
                    <Form.Input 
                        fluid label='Apellido'
                        name="lastname"
                        required
                        value={formData.lastname}
                        placeholder='Apellido'
                        onChange={e => setFormData({...formData, [e.target.name]: e.target.value}) }
                    />
                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.Field 
                        control={Select}
                        options={dniOptions}
                        search
                        required
                        label="Tipo de documento"
                        placeholder="Tipo de documento"
                        name="typeDNI"
                        value={formData.typeDNI}
                        error={formError.typeDNI}
                        onChange={(e, {value}) => setFormData({...formData, typeDNI: value}) }
                    />
                    <Form.Input 
                        fluid label='DNI'
                        name="dni"
                        required
                        value={formData.dni}
                        placeholder='Documento de identidad'
                        onChange={e => setFormData({...formData, [e.target.name]: e.target.value}) }
                    />
                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.Input 
                        fluid name="obrasocial" 
                        label='Obra social (si posee)' 
                        value={formData.obrasocial}
                        error={formError.obrasocial}
                        placeholder='Si no posee obra social, déjelo en blanco'
                        onChange={e => setFormData({...formData, [e.target.name]: e.target.value}) }
                    />
                    <Form.Input 
                        fluid 
                        name="nro" 
                        label='Número de obra social (si posee)' 
                        error={formError.nro}
                        value={formData.nro}
                        placeholder='Si no posee obra social, déjelo en blanco'
                        onChange={e => setFormData({...formData, [e.target.name]: e.target.value}) }
                    />
                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.Input 
                        fluid name="email"
                        label='Email de contacto (para enviarle las recetas)'
                        placeholder='Email de contacto'
                        error={formError.email}
                        value={formData.email}
                        type="email"
                        onChange={e => setFormData({...formData, [e.target.name]: e.target.value}) }
                    />
                    <Form.Input 
                        fluid name="cellphone"
                        label='Teléfono celular de contacto'
                        placeholder='Teléfono celular de contacto'
                        error={formError.cellphone}
                        value={formData.cellphone}
                        onChange={e => setFormData({...formData, [e.target.name]: e.target.value}) }
                    />
                </Form.Group>
                {doneMsg && 
                    <div className="messageDiv">
                        <Message className={isOK === "messageUserData__fail" ? "messageUserData messageUserData__fail" : "messageUserData messageUserData__success"}>
                            {msg}
                        </Message>
                    </div>
                }
                <Form.Button loading={loading} disabled={loading} onClick={() => saveData()} className="btnUpdatePatient btnChangePersonalDate">Modificar paciente</Form.Button>
            </Form>
        </>
    )
}
