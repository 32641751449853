import React, { useState, useEffect } from 'react';
import { Card, Table, Menu, Icon } from "semantic-ui-react";
import "./Home.scss";
import moment from "moment";
import 'moment/locale/es';
import axios from "axios";
import Global from "../../../utils/Global";
import { getLocalStorage } from '../../../utils/localStorage';
import { saniziteNumbers } from '../../../utils/saniziteValues';
import StastiticSuscription from '../StastiticSuscription';

export default function Home(props) {

  const { infoUser, setActiveItem } = props;

  const [totalPatients, setTotalPatients] = useState(0);
  const [totalRecets, setTotalRecets] = useState(0);
  const [totalCreatedRecets, setTotalCreatedRecets] = useState(0);
  const [duplicatedRecets, setDuplicatedRecets] = useState(0);
  const [allRecets, setAllRecets] = useState([]);
  const [loading, setLoading] = useState(false);

  const [offset, setOffset] = useState(0);
  const [countPages, setCountPages] = useState(0);
  const [actualPage, setActualPage] = useState(1);
  const [startLimit, setStartLimit] = useState(1);
  
  useEffect(() => {
    setLoading(true);
    axios.post(Global.url + 'count-recets', { duplicated: true }, { headers: { "authorization" : `Bearer ${getLocalStorage()}`} })
      .then(res => {
        setTotalRecets(res.data.total);
        setTotalCreatedRecets(res.data.created);
        setDuplicatedRecets(res.data.duplicated);
        setCountPages(res.data.result);
      }).catch(err => {
        setCountPages(1);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    axios.post(Global.url + 'count-all-patients', null, { headers: { "authorization" : `Bearer ${getLocalStorage()}`} })
      .then(res => {
        setTotalPatients(res.data.total);
      }).catch(err => {
        setTotalPatients(0);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    axios.post(Global.url + 'load-all-recets', { offset: offset }, { headers: { "authorization" : `Bearer ${getLocalStorage()}`} })
      .then(res => {
        setAllRecets(res.data.result);
      }).catch(err => {
        setAllRecets([]);
      }).finally(() => {
        setLoading(false);
      })
  }, [offset]);

  const movePage = direction => {
    if(direction) {
      if(actualPage !== countPages) {
        setOffset(offset + 10);
        setActualPage(actualPage + 1);
        setStartLimit(startLimit + 1);
      }
    } else {
      if(actualPage > 1) {
        setOffset(offset - 10);
        setActualPage(actualPage - 1);
        setStartLimit(startLimit - 1);
      }
    }
  }

  const goToPage = (result, page) => {
    setOffset(result);
    setActualPage(page);
  }


    return (
        <>
        <Card.Group centered className="cardsGroup">
            <StastiticSuscription infoUser={infoUser} setActiveItem={setActiveItem} />
            <Card className="infoCard infoCard__size infoCard__red">
                <Card.Content>
                    <Card.Header>Total de pacientes</Card.Header>
                <Card.Meta>
                    <span className='date'>Desde {moment(infoUser?.created).locale('es').format("MMMM YYYY")}</span>
                </Card.Meta>
                <Card.Description>
                    <div className="numberCard">{totalPatients}</div>
                </Card.Description>
                </Card.Content>
            </Card>
            <Card className="infoCard infoCard__size infoCard__green">
                <Card.Content>
                    <Card.Header>Total de recetas</Card.Header>
                <Card.Meta>
                    <span className='date'>Desde {moment(infoUser?.created).locale('es').format("MMMM YYYY")}</span>
                </Card.Meta>
                <Card.Description>
                    <div className="numberCard">{totalRecets}</div>
                </Card.Description>
                </Card.Content>
            </Card>
            <Card className="infoCard infoCard__size infoCard__blue">
                <Card.Content>
                    <Card.Header>Recetas creadas</Card.Header>
                <Card.Meta>
                    <span className='date'>Desde {moment(infoUser?.created).locale('es').format("MMMM YYYY")}</span>
                </Card.Meta>
                <Card.Description>
                    <div className="numberCard">{totalCreatedRecets}</div>
                </Card.Description>
                </Card.Content>
            </Card>
            <Card className="infoCard infoCard__size infoCard__teal">
                <Card.Content>
                    <Card.Header>Recetas duplicadas</Card.Header>
                <Card.Meta>
                    <span className='date'>Desde {moment(infoUser?.created).locale('es').format("MMMM YYYY")}</span>
                </Card.Meta>
                <Card.Description>
                    <div className="numberCard">{duplicatedRecets}</div>
                </Card.Description>
                </Card.Content>
            </Card>
            {/* <Card className="infoCard infoCard__size infoCard__brown">
                <Card.Content>
                    <Card.Header>Planillas generadas</Card.Header>
                <Card.Meta>
                    <span className='date'>Desde {moment(infoUser?.created).locale('es').format("MMMM YYYY")}</span>
                </Card.Meta>
                <Card.Description>
                    En construcción
                </Card.Description>
                </Card.Content>
            </Card> */}
        </Card.Group>
        <Table celled>
        <Table.Header>
      <Table.Row>
        <Table.HeaderCell colSpan='6'>Últimas recetas duplicadas añadidas</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Estado</Table.HeaderCell>
        <Table.HeaderCell collapsing>Fecha de inicio</Table.HeaderCell>
        <Table.HeaderCell>Nombre y apellido</Table.HeaderCell>
        <Table.HeaderCell>Documento de identidad</Table.HeaderCell>
        <Table.HeaderCell>CIE10</Table.HeaderCell>
        <Table.HeaderCell collapsing>Fecha vencimiento</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {allRecets.length ?
        allRecets.map((recet) => (
          <RowTable key={recet._id} recet={recet} saniziteNumbers={saniziteNumbers} />
        )) :
        <Table.Row>
          <Table.Cell colSpan="6"><div className="patientsNotFoundTable">No tenés ninguna receta cargada. </div></Table.Cell>
        </Table.Row>
      }
    </Table.Body>

    <Table.Footer>
      <Table.Row>
        <Table.HeaderCell colSpan='6'>
          <Menu floated='right' pagination>
            <Menu.Item as='a' icon onClick={() => movePage(false)}>
              <Icon name='chevron left' />
            </Menu.Item>
            {Array.from(Array(2), (e, i) => (
              ((startLimit+i) !== 0 && (startLimit+i) <= countPages) &&
              (<Menu.Item as='a' key={startLimit+i} onClick={() => goToPage(((startLimit+i)*10-10), startLimit+i)} className={actualPage === (startLimit+i) ? "activeItemMenu" : "ItemMenu" }>{startLimit+i}</Menu.Item>)
            ))}
            <Menu.Item as='a' icon onClick={() => movePage(true)}>
              <Icon name='chevron right' />
            </Menu.Item>
          </Menu>
        </Table.HeaderCell>
      </Table.Row>
    </Table.Footer>
  </Table>
        </>
    )
}



function RowTable(props) {
  const { recet, saniziteNumbers } = props;

  let colorDefault = "tableWaitingRecet";
  let stateRecet = "En revisión";
  if(recet.approved === "APPROVED") {
    colorDefault = "tableApprovedRecet";
    stateRecet = "Aprobada";
  } else if(recet.approved === "CANCELED") {
    colorDefault = "tableCanceledRecet";
    stateRecet = "Anulada";
  } else if(moment().isAfter(moment(recet.startDate).add(recet.duration, "days"))) {
    colorDefault = "tableExpiredRecet";
    stateRecet = "Expirada";
  }

  return(
    <Table.Row className={colorDefault}>
      <Table.Cell>{stateRecet}</Table.Cell>
      <Table.Cell>{moment(recet.startDate).format("DD/MM/YYYY")}</Table.Cell>
      <Table.Cell>{recet.patient.name} {recet.patient.secondName} {recet.patient.lastname} {recet.patient.secondLastname}</Table.Cell>
      <Table.Cell>{recet.patient.typeDNI} {saniziteNumbers(recet.patient.dni)}</Table.Cell>
      <Table.Cell>{recet.diagnostic}</Table.Cell>
      <Table.Cell>{recet.approved !== "CANCELED" ? moment(recet.startDate).add(recet.duration, "days").format("DD/MM/YYYY") : "-" }</Table.Cell>
    </Table.Row>
  )
}