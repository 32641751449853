import React from 'react';
import "./CallMenuHeaderMobile.scss";
import { isMobile } from "react-device-detect";
import { Icon, Image } from "semantic-ui-react";
import logo from "../../assets/images/logo.png";

export default function CallMenuHeaderMobile(props) {

    const { setSidebarVisible } = props;
    
    return (
        <>
            {isMobile &&
                <div className="menuHeaderMobile">
                    <div><Image src={logo} className="logoMobile" /></div>
                    <div className="menuItemMobile openMenuDiv" onClick={() => setSidebarVisible(prevValue => !prevValue)}>
                        <Icon name="align justify" />
                    </div>
                </div>
            }
        </>
    )
}
