import React, { useState, useEffect } from 'react';
import "./Dashboard.scss";
import { Container, Grid, Menu } from "semantic-ui-react";
import { isMobile } from "react-device-detect";
import axios from "axios";
import Global from "../../utils/Global";
import { getLocalStorage } from '../../utils/localStorage';
import { useParams } from "react-router-dom";
//components dashboard
import Home from "../../components/Dashboard/Home";
import User from "../../components/Dashboard/User";
import Pacientes from "../../components/Dashboard/Pacientes";
import Recetas from "../../components/Dashboard/Recetas";
// import Planillas from "../../components/Dashboard/Planillas";
import UpdateSuscription from "../../components/Dashboard/UpdateSuscription";
import LoadingPage from "../../components/LoadingPage";


export default function Dashboard(props) {

    const { isLogged, isLoaded } = props;

    let { path } = useParams();

    const [infoUser, setInfoUser] = useState(null);

    useEffect(() => {
        if(!isLogged && isLoaded) {
            props.history.push("/login");
        }
    }, [isLogged, isLoaded]);

    useEffect(() => {
        if(isLogged) {
            axios.post(Global.url + 'get-data-personal', null, { headers: { "authorization" : `Bearer ${getLocalStorage()}`} })
            .then(res => {
                setInfoUser(res.data.result);
            }).catch(err => {
                console.log(err);
            });
        }
    }, [isLogged]);

    useEffect(() => {
        if(isLogged) {
            axios.post(Global.url + 'check-pharmacy', null, { headers: { "authorization" : `Bearer ${getLocalStorage()}`} })
            .then(res => {
                if(res.data.status) {
                    props.history.push("/farmacia");
                }
            }).catch(err => {
                console.log(err);
            });
        }
    }, [isLogged]);

    useEffect(() => {
        window.scroll({top: 0, left: 0, behavior: 'smooth' });
    }, []);

    const [activeItem, setActiveItem] = useState(path !== undefined ? path : "home");

    if(isLogged && isLoaded && infoUser !== null) {
        return (
            <Container className={isMobile ? "bgDashboard bgDashboard__Mobile" : "bgDashboard"}>
                <Menu secondary pointing className={isMobile ? "menuDashboard menuDashboardMobile" : "menuDashboard"} style={{ height: isMobile ? "100%" : "50px !important" }}>
                    <Menu.Item
                        name='Inicio'
                        active={activeItem === "home"}
                        onClick={() => setActiveItem("home")}
                        className={isMobile ? "itemMobile" : "itemNoMobile"}
                    />
                    <Menu.Item
                        name='Cuenta'
                        active={activeItem === "user"}
                        onClick={() => setActiveItem("user")}
                        className={isMobile ? "itemMobile" : "itemNoMobile"}
                    />
                    <Menu.Item
                        name='Pacientes'
                        active={activeItem === "patients"}
                        onClick={() => setActiveItem("patients")}
                        className={isMobile ? "itemMobile" : "itemNoMobile"}
                    />
                    <Menu.Item
                        name='Recetas'
                        active={activeItem === "recets"}
                        onClick={() => setActiveItem("recets")}
                        className={isMobile ? "itemMobile" : "itemNoMobile"}
                    />
                    {/* <Menu.Item
                        name='Planillas'
                        active={activeItem === "documents"}
                        onClick={() => setActiveItem("documents")}
                        className={isMobile ? "itemMobile" : "itemNoMobile"}
                    /> */}
                    {/* { !isMobile &&
                        <Menu.Menu position="right">
                            <Menu.Item
                                name='Salir'
                                active={activeItem === "logout"}
                                onClick={() => setActiveItem("logout")}
                                className={isMobile ? "itemMobile" : "itemNoMobile"}
                            />
                        </Menu.Menu>
                    } */}
                </Menu>
                <Grid className="gridDashboard">
                    <Grid.Column width={16} className="dashboardContent">
                        {(() => {
                            if (activeItem === "home") {
                                return <Home infoUser={infoUser} setActiveItem={setActiveItem} />
                            } else if (activeItem === "user") {
                                return <User infoUser={infoUser} setInfoUser={setInfoUser} setActiveItem={setActiveItem} />
                            } else if(activeItem === "patients") {
                                return <Pacientes infoUser={infoUser} setInfoUser={setInfoUser} />
                            } else if(activeItem === "recets") {
                                return <Recetas infoUser={infoUser} setInfoUser={setInfoUser} />
                            //} else if(activeItem === "documents") {
                                //return <Planillas infoUser={infoUser} setInfoUser={setInfoUser} />
                            } else if(activeItem === "update-suscription") {
                                return <UpdateSuscription infoUser={infoUser} setInfoUser={setInfoUser} setActiveItem={setActiveItem} />
                            } else {
                                return <Home infoUser={infoUser} />
                            }
                        })()}
                    </Grid.Column>
                </Grid>
            </Container>
        )
    } else {
        return <LoadingPage />;
    }
}
