import React from 'react';

import moment from "moment";

import TB from "../../assets/images/TB.png";

import { List, Grid, Image, Icon } from "semantic-ui-react";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import ScrollToTop from "react-scroll-up";

import "./Footer.scss";

export default function Footer() {

    // const goUp = () => {
    //     window.scroll({top: 0, left: 0, behavior: 'smooth' });
    // }

    return (
        <div id="footer">
            <Grid className="gridFooter" columns="equal">
                <Grid.Column width={isMobile ? 16 : 8} className="gridList">
                    <div className="divListFooter">
                        <List className="listFooter">
                            <List.Item className="listLinkFooter" as={Link} to="/contacto">Contacto</List.Item>
                            <List.Item className="listLinkFooter" as={Link} to="/mapsite">Mapa de sitio</List.Item>
                            <List.Item className="listLinkFooter" as={Link} to="/faq">Preguntas frecuentes</List.Item>
                            <List.Item className="listLinkFooter" as={Link} to="/tos">Términos y condiciones del uso</List.Item>
                        </List>
                    </div>
                </Grid.Column>
                <Grid.Column width={isMobile ? 16 : 8}>
                    AFIP LOGO
                </Grid.Column>
            </Grid>
            <ScrollToTop showUnder={2000} style={{ zIndex: 100 }} duration={1000}>
                <div id="iconUp">
                    <Icon name="angle up" size="big" />
                </div>
            </ScrollToTop>
            <div className="copyright">Copyright © - {moment().format("YYYY")} All rights reserved</div>
            <div className={isMobile ? "developedBy developedBy__Mobile" : "developedBy"}>Website developed by <a href="https://www.tomasbusquets.com/" rel="noopener noreferrer" target="_blank"><Image src={TB} title="Tomás Busquets" alt="Tomás Busquets" className="imgTB" /></a></div>
        </div>
    )
}
