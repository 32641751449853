import React, { useState } from 'react';

import "./Home.scss";

import { Header, Input, Radio, Button, Table, Message } from "semantic-ui-react";

import moment from "moment";
import axios from "axios";
import Global from "../../../utils/Global";
import { getLocalStorage } from "../../../utils/localStorage";
import ModalComponent from "../../ModalComponent/ModalComponent";

export default function Home(props) {

    const { infoPharmacy } = props;

    const [loading, setLoading] = useState(false);
    const [contentInput, setContentInput] = useState('');
    const [radioValue, setRadioValue] = useState('id');
    const [error, setError] = useState({});

    const [done, setDone] = useState(true);
    const [status, setStatus] = useState(false);
    const [msg, setMsg] = useState(false);
    const [results, setResults] = useState([]);

    const [openModal, setOpenModal] = useState(false);
    const [openModalResult, setOpenModalResult] = useState(false);
    const [contentModal, setContentModal] = useState("");
    const [loadingModal, setLoadingModal] = useState(false);
    const [recetToApprov, setRecetToApprov] = useState(null);

    const [approved, setApproved] = useState(false);
    const [doneApprov, setDoneApprov] = useState(false);

    const searchReceta = () => {
        if(contentInput === "") {
            setError({ receta: "El campo a buscar no puede estar vacío. " });
        } else {
            setLoading(true);
            axios.post(Global.url + 'search-receta', { criteria: radioValue, input: contentInput }, { headers: { "authorization" : `Bearer ${getLocalStorage()}`} })
                .then(res => {
                    setMsg(res.data.message);
                    setStatus(res.data.status);
                    setResults(res.data.results);
                }).catch(err => {
                    setMsg("Hubo un error en el servidor. Por favor intente más tarde. ");
                    setStatus(false);
                    setResults([]);
                }).finally(() => {
                    setDone(true);
                    setLoading(false);
                });
        }
    }

    const approveRecet = recet => {
        setContentModal(<ApproveRecetModal recet={recet} approved={approved} doneApprov={doneApprov} />);
        setOpenModal(true);
        setRecetToApprov(recet);
    }
    
    const confirmApprovRecet = () => {
        setLoadingModal(true);
        axios.post(Global.url + 'approve-receta', { recet: recetToApprov._id }, { headers: { "authorization" : `Bearer ${getLocalStorage()}`} })
                .then(res => {
                    setOpenModal(false);
                    setApproved(res.data.status);
                    setDoneApprov(true);
                    searchReceta();
                }).catch(err => {
                    setOpenModal(false);
                    setApproved(false);
                    setDoneApprov(false);
                }).finally(() => {
                    setOpenModalResult(true);
                    setLoadingModal(false);
        });
    }

    return (
        <>
            <div className="divFarmaciaDashboard">
                <Header as="h2">Buscador de recetas</Header>
                <div className="radioRecetaDiv">
                    <Radio
                        label='Buscar por ID'
                        name='criteria'
                        className="radioGroupFarmacia"
                        value='id'
                        checked={radioValue === 'id'}
                        onChange={(e, { value }) => setRadioValue(value)}
                    />
                    <Radio
                        label='Buscar por DNI'
                        name='criteria'
                        className="radioGroupFarmacia"
                        value='dni'
                        checked={radioValue === 'dni'}
                        onChange={(e, { value }) => setRadioValue(value)}
                    />
                </div>
                <div className="inputRecetaDiv">
                    <Input
                        name="receta"
                        error={error.receta}
                        value={contentInput}
                        maxLength="24"
                        onChange={e => setContentInput(e.target.value)}
                        className="inputFarmaciaStyle"
                        fluid
                    />
                </div>
                <div>
                    <Button
                        className="btnSearchReceta"
                        color="blue"
                        size="big"
                        loading={loading}
                        disabled={contentInput === "" ? true : false}
                        onClick={() => searchReceta()}
                    >
                        Buscar
                    </Button>
                </div>
                {done &&
                    <Table celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell colSpan='11'>Recetas encontradas</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Estado</Table.HeaderCell>
                            <Table.HeaderCell collapsing>Fecha</Table.HeaderCell>
                            <Table.HeaderCell>Nombre y apellido</Table.HeaderCell>
                            <Table.HeaderCell>DNI</Table.HeaderCell>
                            <Table.HeaderCell>Obra social</Table.HeaderCell>
                            <Table.HeaderCell>Número</Table.HeaderCell>
                            <Table.HeaderCell>CIE10</Table.HeaderCell>
                            <Table.HeaderCell>Medicamento 1</Table.HeaderCell>
                            <Table.HeaderCell>Medicamento 2</Table.HeaderCell>
                            <Table.HeaderCell>Fecha Vencimiento</Table.HeaderCell>
                            <Table.HeaderCell>Opciones</Table.HeaderCell>
                        </Table.Row>
                        </Table.Header>
                        <Table.Body>
                        {results?.length ?
                            results?.map((recet) => (
                                <RowTable key={recet._id} recet={recet} approveRecet={approveRecet} />
                            )) :
                            <Table.Row>
                                <Table.Cell colSpan="11"><div className="patientsNotFoundTable">{msg}</div></Table.Cell>
                            </Table.Row>
                        }
                        </Table.Body>
                    </Table>
                }
            </div>
            <ModalComponent loading={loadingModal} openModal={openModal} setOpenModal={setOpenModal} titleModal="Aprobar receta" size="tiny" contentYes="Aprobar" functionToDo={confirmApprovRecet}>
                {contentModal}
            </ModalComponent>
            <ModalComponent openModal={openModalResult} setOpenModal={setOpenModalResult} titleModal={approved ? "Receta aprobada" : "Falló al aprobar la receta"} size="tiny" contentYes="OK">
                <Message color={approved ? "success" : "error"} className="segmentApproveRecet">{approved ? "La receta fue aprobada exitosamente." : "Hubo un error al aprobar la receta, por favor intente más tarde. "}</Message>
            </ModalComponent>
        </>
    )
}


function RowTable(props) {

    const { recet, approveRecet } = props;
  
    let colorDefault = "tableWaitingRecet";
    let stateRecet = "En revisión";
    if(recet.approved === "APPROVED") {
      colorDefault = "tableApprovedRecet";
      stateRecet = "Aprobada";
    } else if(recet.approved === "CANCELED") {
      colorDefault = "tableCanceledRecet";
      stateRecet = "Anulada";
    } else if(moment().isAfter(moment(recet.startDate).add(30, "days"))) {
      colorDefault = "tableExpiredRecet";
      stateRecet = "Expirada";
    }
    
    return(
      <Table.Row className={colorDefault}>
        <Table.Cell>{stateRecet}</Table.Cell>
        <Table.Cell>{moment(recet.startDate).format("DD/MM/YYYY")}</Table.Cell>
        <Table.Cell>{recet.patient?.name} {recet.patient?.secondName} {recet.patient?.lastname} {recet.patient?.secondLastname}</Table.Cell>
        <Table.Cell>{recet.patient?.typeDNI} {recet.patient?.dni}</Table.Cell>
        <Table.Cell>{recet.patient?.obrasocial}</Table.Cell>
        <Table.Cell>{recet.patient?.nro}</Table.Cell>
        <Table.Cell>{recet.diagnostic}</Table.Cell>
        <Table.Cell>{recet.remedio1}</Table.Cell>
        <Table.Cell>{recet.remedio2}</Table.Cell>
        <Table.Cell>{recet.approved !== "CANCELED" ? moment(recet.startDate).add(30, "days").format("DD/MM/YYYY") : "-" }</Table.Cell>
        <Table.Cell>{recet.approved === "WAITING" ? 
            <Button className="btnApprovRecet" color="green" onClick={() => approveRecet(recet)}>Aprobar</Button> : "-" }
        </Table.Cell>
      </Table.Row>
    )
  }


function ApproveRecetModal(props) {

    const { recet } = props;

    return(
        <>
            <Message warning className="segmentApproveRecet">Usted está por aprobar la siguiente receta. Recuerde que esta opción es irreversible. </Message>
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell colSpan='2' className="titleHeaderTableApproveRecet">Receta a aprobar</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell><strong>Nombre y apellido</strong></Table.Cell>
                        <Table.Cell>{recet.patient?.name} {recet.patient?.secondName} {recet.patient?.lastname} {recet.patient?.secondLastname}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell><strong>{recet.patient?.typeDNI}</strong></Table.Cell>
                        <Table.Cell>{recet.patient?.dni}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell><strong>Obra social</strong></Table.Cell>
                        <Table.Cell>{recet.patient?.obrasocial}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell><strong>Número</strong></Table.Cell>
                        <Table.Cell>{recet.patient?.nro}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell><strong>Medicamento 1</strong></Table.Cell>
                        <Table.Cell>{recet?.remedio1}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell><strong>Medicamento 2</strong></Table.Cell>
                        <Table.Cell>{recet?.remedio2}</Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
        </>
    )
}
