import React, { useState, useEffect } from 'react';

import { Header, Form, Message } from "semantic-ui-react";

import { isMobile } from "react-device-detect";

import axios from "axios";
import Global from "../../../../utils/Global";
import { getLocalStorage } from "../../../../utils/localStorage";

import "./ModifyObraSocial.scss";

export default function ModifyObraSocial(props) {

    const { infoUser } = props;

    const [doneMsg, setDoneMsg] = useState(false);
    const [isOK, setIsOK] = useState("messageUserData__fail");
    const [msg, setMsg] = useState("");

    const [loading, setLoading] = useState(false);

    const [options, setOptions] = useState([]);
    const [currentValues, setCurrentValues] = useState([]);

    const [resultsSearch, setResultsSearch] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [searching, setSearching] = useState(false);
    const [gotValues, setGotValues] = useState(false);
    const [selectedObraSocial, setSelectedObraSocial] = useState(null);

    const [formError, setFormError] = useState({});
    const [formData, setFormData] = useState({
        "_id": "",
        "name": "",
        "plans": []
    });

    const searchObraSocial = value => {
        setSearchValue(value);
        setSearching(true);
        setGotValues(false);
        setTimeout(function() {
            axios.post(Global.url + 'search-obrasocial', { search: value }, { headers: { "authorization" : `Bearer ${getLocalStorage()}`} })
                .then(res => {
                    setResultsSearch(res.data.results);
                }).catch(err => {
                    setResultsSearch([]);
                }).finally(() => {
                    setSearching(false);
                    setGotValues(true);
            });
        }, 300);
    }

    const selectObraSocial = item => {
        setSearchValue("");
        setSelectedObraSocial(item);
        setGotValues(false);
        setResultsSearch([]);
        setCurrentValues(item.plans);
        setFormData({
            "_id": item._id,
            "name": item.name,
            "plans": item.plans
        });
        // hacer reestructura de dropdown
    }

    const setSelectedDropdown = (e, { value }) => {
        setCurrentValues(value);
    }

    const renderLabel = (label) => ({
        color: 'blue',
        content: `${label.text}`,
        icon: 'check',
    });
    
    const addItemDropdown = (e, { value }) => {
        let auxOptions = options;
        auxOptions.push({
            "key": value,
            "text": value,
            "value": value
        });
        setOptions(auxOptions);
    }

    const saveData = () => {
        if(!formData.name) {
            setFormError({ name: "Este campo es obligatorio. "});
        } else {
            formData.plans = currentValues;
            setLoading(true);
            axios.post(Global.url + 'modify-obrasocial', { formData: formData }, { headers: { "authorization" : `Bearer ${getLocalStorage()}`} })
                .then(res => {
                    setMsg(res.data.message);
                    if(res.data.status) {
                        setIsOK("messageUserData__success");
                        setFormData({
                            "name": "",
                            "plans": []
                        });
                    } else {
                        setIsOK("messageUserData__fail");
                    }
                }).catch(err => {
                    setIsOK("messageUserData__fail");
                    console.log(err);
                }).finally(() => {
                    setDoneMsg(true);
                    setLoading(false);
                });
        }
    }

    return (
        <>
        <Header as='h3' className="headerUserPanel">
            Modificar obra social
        </Header>
        <Form>
            <Form.Input
                onChange={e => searchObraSocial(e.target.value)}
                fluid label='Obra social' 
                name="obrasocial"
                value={searchValue}
                error={formError.obrasocial}
                icon={searching ? "circle notch" : "search"}
                placeholder='Buscar obra social'
            />
            {gotValues &&
                <div className="listValuesFound listValuesFound__Patients">
                    {resultsSearch.length === 0 && 
                        <div className="aValueFound">No se encontró ninguna obra social que coincida. </div>
                    }
                    {resultsSearch.map((item) => (
                        <div 
                            onClick={() => selectObraSocial(item)} 
                            className="aValueFound"
                            key={item._id}
                        >
                            {item.name}
                        </div>
                    ))}
                </div>
            }
            {selectedObraSocial !== null &&
                <div className="infoSelectedPatient">
                    <span className="textSelectedPatient"><strong>Obra social:</strong> <span>{selectedObraSocial.name}</span></span>
                    <span className="textSelectedPatient"><strong>Planes:</strong> //ACÁ UN LISTADO DE PLANES</span>
                </div>
            }
            <Form.Group widths='equal'>
                <Form.Input 
                    fluid label='Nombre de la obra social' 
                    name="name"
                    required
                    value={formData.name}
                    placeholder='Nombre de la obra social'
                    onChange={e => setFormData({...formData, [e.target.name]: e.target.value}) }
                />
            </Form.Group>
            <Form.Group widths='equal'>
                <Form.Dropdown
                    options={options}
                    fluid label='Planes de la obra social'
                    name="plans"
                    search
                    multiple
                    selection
                    allowAdditions
                    value={currentValues}
                    placeholder='Planes de la obra social'
                    onAddItem={addItemDropdown}
                    renderLabel={renderLabel}
                    onChange={e => setSelectedDropdown(e) }
                />
            </Form.Group>
            {doneMsg && 
                <div className="messageDiv">
                    <Message className={isOK === "messageUserData__fail" ? "messageUserData messageUserData__fail" : "messageUserData messageUserData__success"}>
                        {msg}
                    </Message>
                </div>
            }
            <Form.Button loading={loading} disabled={loading} onClick={() => saveData()} className="btnSubmit btnChangePersonalDate">Modificar obra social existente</Form.Button>
            {/* falta uno que diga borrar obra social */}
        </Form>
        </>
    )
}
