import React, { useState, useEffect } from 'react';

import "./ConfigPayment.scss";

import axios from "axios";
import Global from "../../../../utils/Global";
import { getLocalStorage } from "../../../../utils/localStorage";

import { Header, Form, Divider } from "semantic-ui-react";

import StastiticSuscription from '../../StastiticSuscription';

export default function ConfigPayment(props) {

    const { infoUser, setInfoUser, setActiveItem } = props;

    const [renovationStatus, setRenovationStatus] = useState(infoUser?.renovation);
    const [loading, setLoading] = useState(false);
    const [loadingPayment, setLoadingPayment] = useState(false);

    // useEffect(() => {
    //     axios.post(Global.url + 'check-suscription', null, { headers: { "authorization" : `Bearer ${getLocalStorage()}`} })
    //         .then(res => {
    //             if(res.data.status) {
    //                 setRenovationStatus(res.data.renovation);
    //             }
    //         }).catch(err => {
    //             console.log(err);
    //         });
    // }, []);

    const changeRenovation = () => { // CAMBIA LA RENOVACION, ADEMÁS DEL INFOUSER
        setLoading(true);
        axios.post(Global.url + "change-automatic-suscription", { renovationStatus: renovationStatus }, { headers: { "authorization" : `Bearer ${getLocalStorage()}`} })
            .then(res => {
                if(res.data.status) {
                    setRenovationStatus(res.data.renovation);
                    setInfoUser({ ...infoUser, renovation: res.data.renovation });
                }
            }).catch(err => {
                console.log(err);
            }).finally(() => {
                setLoading(false);
            });
    }

    const changePayment = () => {
        setLoadingPayment(true);
        axios.post(Global.url + "change-payment", null, { headers: { "authorization" : `Bearer ${getLocalStorage()}`} })
            .then(res => {
                if(res.data.status) {
                    setTimeout(function() {
                        window.location.replace(res.data.result);
                    }, 500);
                } else {
                    alert(res.data.message);
                }
            }).catch(err => {
                console.log(err);
            }).finally(() => {
                setLoadingPayment(false);
            });
    }

    return(
        <>
            <Header as="h3">
                Estado de su suscripción
            </Header>
            <StastiticSuscription infoUser={infoUser} setActiveItem={setActiveItem} />
            {infoUser.typeSuscription !== "BASIC" &&
                <>
                    <Divider />
                    <Header as="h3">
                        Renovación de suscripción
                    </Header>
                    <Form>
                        <div className="statusRenovation">
                            <div className="titleStatusRenovation">Renovación automática: </div>
                            <div className="resultStatusRenovation">{renovationStatus ? "ACTIVADA" : "DESACTIVADA"}</div>
                        </div>
                        <Form.Button 
                            inline
                            label={"Activar renovación automática"}
                            name="renovationBtn"
                            className="renovationBtn"
                            color={renovationStatus ? "blue" : "red"}
                            onClick={() => changeRenovation()}
                            loading={loading}
                            disabled={loading}
                        >
                            {renovationStatus ? "Desactivar" : "Activar"}
                        </Form.Button>
                    </Form>
                    <Divider />
                    <Header as="h3">
                        Cambiar método de pago
                    </Header>
                    <Form.Button 
                        inline
                        label={"Cambiar método de pago"}
                        name="changePayment"
                        className="changePayment"
                        color="green"
                        onClick={() => changePayment()}
                        loading={loadingPayment}
                        disabled={loadingPayment}
                    >
                        Cambiar
                    </Form.Button>
                </>
            }
        </>
    )

}