import React, { useState, useEffect } from 'react';

import "./ConfigUser.scss";

import axios from "axios";
import Global from "../../../../utils/Global";
import { getLocalStorage } from '../../../../utils/localStorage';

import { validateEmail } from '../../../../utils/Validations';

import { Header, Icon, Form, Divider, Message } from "semantic-ui-react";

export default function ConfigUser(props) {

    const { infoUser } = props;

    const [doneMsgPass, setDoneMsgPass] = useState(false);
    const [doneMsg, setDoneMsg] = useState(false);
    const [isOK, setIsOK] = useState("messageUserData__fail");
    const [msg, setMsg] = useState("");

    const [loadingEmail, setLoadingEmail] = useState(false);
    const [loadingPass, setLoadingPass] = useState(false);

    const [visibleActualPass, setVisibleActualPass] = useState(false);
    const [visibleNewPass, setVisibleNewPass] = useState(false);

    const [formError, setFormError] = useState({});
    const [formDataEmail, setFormDataEmail] = useState({
        "email": "",
        "emailRepeat": ""
    });
    const [formDataPassword, setFormDataPassword] = useState({
        "password": "",
        "passwordNew": "",
        "passwordRepeat": ""
    });

    const changeDetails = sufix => {
        let formData = formDataEmail;
        let isOk = true;
        if(sufix === "password") {
            formData = formDataPassword;
            if(!formDataPassword.password.length) {
                setFormError({ password: "Debes introducir tu contraseña actual. "});
                isOk = false;
            } else if(!formDataPassword.passwordNew.length) {
                setFormError({ passwordNew: "La contraseña no puede estar vacía. "});
                isOk = false;
            } else if(formDataPassword.passwordNew.length < 8) {
                setFormError({ passwordNew: "La contraseña debe contener al menos 8 carácteres. "});
                isOk = false;
            } else if(formDataPassword.passwordNew !== formDataPassword.passwordRepeat) {
                setFormError({ passwordRepeat: "Las nuevas contraseñas no coinciden. "});
                isOk = false;
            }
        } else {
            if(formDataEmail.email === infoUser.email) {
                setFormError({ email: "El email es igual al que tenés actualmente. "});
                isOk = false;
            } else if(!validateEmail(formDataEmail.email) || !validateEmail(formDataEmail.emailRepeat)) {
                setFormError({ email: "El nuevo email no es correcto. "});
                isOk = false;
            }
        }
        if(isOk) {
            if(sufix === "password") {
                setLoadingPass(true);
            } else {
                setLoadingEmail(true);
            }
            axios.post(Global.url + 'change-'+sufix, formData, { headers: { "authorization" : `Bearer ${getLocalStorage()}`} })
            .then(res => {
                if(res.data.status) {
                    setIsOK("messageUserData__success");
                }
                setMsg(res.data.message);
            }).catch(err => {
                setMsg("Hubo un error interno en el servidor. Por favor, intente más tarde. ");
                setIsOK("messageUserData__fail");
            }).finally(() => {
                if(sufix === "password") {
                    setDoneMsgPass(true);
                    setLoadingPass(false);
                } else {
                    setDoneMsg(true);
                    setLoadingEmail(false);
                }
            });
        }
    }

    return(
        <>
        <Header as="h3">
            Cambiar email
        </Header>
        <div className="infoUserDiv">
            <strong>Tu email actual</strong>: {infoUser.email}
        </div>
        <Form>
            <Form.Group widths='equal'>
                <Form.Input
                    inline
                    fluid
                    label="Tu nuevo email"
                    name="email"
                    type="email"
                    value={formDataEmail.email}
                    error={formError.email}
                    className="formEmailConfigUser"
                    placeholder="Tu nuevo email"
                    onChange={e => setFormDataEmail({ ...formDataEmail, [e.target.name]: e.target.value })}
                />
                <Form.Input
                    inline
                    fluid
                    label="Repita el nuevo email"
                    name="emailRepeat"
                    type="email"
                    value={formDataEmail.emailRepeat}
                    error={formError.email}
                    className="formEmailConfigUser"
                    placeholder="Repita el nuevo email"
                    onChange={e => setFormDataEmail({ ...formDataEmail, [e.target.name]: e.target.value })}
                />
            </Form.Group>
            <Form.Button loading={loadingEmail} disabled={loadingEmail} onClick={() => changeDetails("email")} className="btnSubmitForm">Cambiar email</Form.Button>
            {doneMsg && 
                <div className="messageDiv">
                    <Message className={isOK === "messageUserData__fail" ? "messageUserData messageUserData__fail" : "messageUserData messageUserData__success"}>
                        {msg}
                    </Message>
                </div>
            }
        </Form>
        <Divider />
        <Header as="h3">
            Cambiar contraseña
        </Header>
        <Form>
            <Form.Input
                    inline
                    fluid
                    label="Tu contraseña actual"
                    name="password"
                    type={visibleActualPass ? "text" : "password"}
                    value={formDataPassword.password}
                    error={formError.password}
                    icon={<Icon name={visibleActualPass ? 'eye slash' : 'eye'} link onClick={() => setVisibleActualPass(prevValue => !prevValue)} />}
                    className="formEmailConfigUser"
                    placeholder="Escriba tu contraseña actual"
                    onChange={e => setFormDataPassword({ ...formDataPassword, [e.target.name]: e.target.value })}
            />
            <Form.Group widths='equal'>
                <Form.Input
                    inline
                    fluid
                    label="Tu nueva contraseña"
                    name="passwordNew"
                    type={visibleNewPass ? "text" : "password"}
                    value={formDataPassword.passwordNew}
                    error={formError.passwordNew}
                    icon={<Icon name={visibleNewPass ? 'eye slash' : 'eye'} link onClick={() => setVisibleNewPass(prevValue => !prevValue)} />}
                    className="formEmailConfigUser"
                    placeholder="Escriba tu nueva contraseña"
                    onChange={e => setFormDataPassword({ ...formDataPassword, [e.target.name]: e.target.value })}
                />
                <Form.Input
                    inline
                    fluid
                    label="Repita tu nueva contraseña"
                    name="passwordRepeat"
                    type={visibleNewPass ? "text" : "password"}
                    value={formDataPassword.passwordRepeat}
                    error={formError.passwordRepeat}
                    icon={<Icon name={visibleNewPass ? 'eye slash' : 'eye'} link onClick={() => setVisibleNewPass(prevValue => !prevValue)} />}
                    className="formEmailConfigUser"
                    placeholder="Repita la nueva contraseña"
                    onChange={e => setFormDataPassword({ ...formDataPassword, [e.target.name]: e.target.value })}
                />
            </Form.Group>
            <Form.Button loading={loadingPass} disabled={loadingPass} onClick={() => changeDetails("password")} className="btnSubmitForm">Cambiar contraseña</Form.Button>
            {doneMsgPass && 
                <div className="messageDiv">
                    <Message className={isOK === "messageUserData__fail" ? "messageUserData messageUserData__fail" : "messageUserData messageUserData__success"}>
                        {msg}
                    </Message>
                </div>
            }
        </Form>
        </>
    )

}