import React from 'react';

import img from "../../../assets/images/1.jpg";
import img1 from "../../../assets/images/figure1.jpg";
import img2 from "../../../assets/images/figure2.jpg";
import img3 from "../../../assets/images/figure3.jpg";
import img4 from "../../../assets/images/figure4.jpg";

import { Grid, Header, Image } from "semantic-ui-react";
import ScrollAnimation from 'react-animate-on-scroll';
import { isMobile } from "react-device-detect";
import doctor from "../../../assets/images/doctora-home.png";

import "./Start.scss";

export default function Start() {
    return (
        <div className={isMobile ? "homeStart homeStart__Mobile" : "homeStart homeStart__NotMobile" }>
            <Grid className="gridHome">
                <Grid.Column width={isMobile ? 16 : 8} className="columnHomeGrid">
                    <ScrollAnimation animateIn="slideInLeft" animateOnce={true} initiallyVisible={true}>
                        <Header className={isMobile ? "titlePageGrid titlePageGrid__Mobile" : "titlePageGrid"}>Soluciones médicas</Header>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="fadeIn" animateOnce={true} delay={900} initiallyVisible={isMobile ? true : false}>
                        <div className={isMobile ? "subtitlePageGrid subtitlePageGrid__Mobile" : "subtitlePageGrid"}>
                            Recetas al instante. Optimice su trabajo como médico.
                        </div>
                        <div className="subtitlePageGridSub">Solo para médicos y médicas matriculados en la República Argentina. </div>
                    </ScrollAnimation>
                </Grid.Column>
                <Grid.Column width={isMobile ? 16 : 8}>
                    <Image src={doctor} className="doctorImg" />
                    {/* <div className="collegePhotos">
                        <div className="group">
                            <Image src={img4} className="figurePhoto1" />
                            <Image src={img1} className="figurePhoto2" />
                        </div>
                        <div className="group">
                            <Image src={img2} className="figurePhoto3" />
                            <Image src={img3} className="figurePhoto4" />
                        </div>
                    </div> */}
                </Grid.Column>
            </Grid>
        </div>
    )
}

//<Image src={img} />
                
