import React, { useState, useEffect } from 'react';

import { Grid, Menu, Input } from "semantic-ui-react";

import { isMobile } from "react-device-detect";

import axios from "axios";
import Global from "../../../utils/Global";
import { getLocalStorage } from "../../../utils/localStorage";

import "./ObrasSociales.scss";

//components
import AddObraSocial from "./AddObraSocial";
import ModifyObraSocial from "./ModifyObraSocial";
import ListObrasSociales from "./ListObrasSociales";

export default function ObrasSociales(props) {

    const { infoUser } = props;

    const [menuObraSocial, setMenuObraSocial] = useState("addobrasocial");

    return (
        <>
            <Grid className="gridUser gridUser__Admin">
                <Grid.Column className={isMobile ? "menuVertical" : "menuUserGrid"} width={isMobile ? 16 : 4}>
                    <Menu fluid vertical tabular={isMobile ? false : true} style={{ minHeight: isMobile ? "auto" : "67vh", height: "100%" }}>
                        <Menu.Item 
                            name="Agregar obra social"
                            content="Agregar obra social"
                            active={menuObraSocial === "addobrasocial"}
                            onClick={() => setMenuObraSocial("addobrasocial")}
                        />
                        <Menu.Item 
                            name="Modificar obra social"
                            content="Modificar obra social"
                            active={menuObraSocial === "modifyobrasocial"}
                            onClick={() => setMenuObraSocial("modifyobrasocial")}
                        />
                        <Menu.Item 
                            name="Listado de obras sociales"
                            content="Listado de obras sociales"
                            active={menuObraSocial === "listobrassociales"}
                            onClick={() => setMenuObraSocial("listobrassociales")}
                        />
                    </Menu>
                </Grid.Column>
                <Grid.Column className="contentUserGrid" width={isMobile ? 16 : 12}>
                    {menuObraSocial === "addobrasocial" &&
                        <AddObraSocial infoUser={infoUser} />
                    }
                    {menuObraSocial === "modifyobrasocial" &&
                        <ModifyObraSocial infoUser={infoUser} />
                    }
                    {menuObraSocial === "listobrassociales" &&
                        <ListObrasSociales infoUser={infoUser} />
                    }
                </Grid.Column>
            </Grid>
        </>
    )
}
