var Global = {
    url: 'https://rpdigitales.com.ar/api/v1/' //getUrl(window.location.hostname)
};

export default Global;

function getUrl(url) {
    switch(url) {
        case "rpdigitales.com.ar": return 'https://rpdigitales.com.ar/api/v1/'
        case "recetasmedicas.tomasbusquets.com": return 'https://recetasmedicas.tomasbusquets.com/api/v1/'
        default: return 'http://localhost:3090/api/v1/'
    }
}
