import React, { useState } from 'react';
import { Header, Form, Input, Button, Message, Label, Table, Menu, Icon } from "semantic-ui-react";
import moment from "moment";
import "./Import.scss";
import axios from "axios";
import Global from "../../../../utils/Global";
import { saniziteNumbers } from '../../../../utils/saniziteValues';
import { getLocalStorage } from '../../../../utils/localStorage';

export default function Import(props) {

  const { infoUser, setInfoUser } = props;

  const [loading, setLoading] = useState(false);
  const [doneMsg, setDoneMsg] = useState(false);
  const [msg, setMsg] = useState("Hubo un error al subir el archivo. Intente más tarde. ");
  const [isOK, setIsOK] = useState("messageUserData__fail");
  const [formError, setFormError] = useState({});
  const [fileExcel, setFileExcel] = useState(undefined);
  const [patients, setPatients] = useState({});
  const [total, setTotal] = useState(0);
  const [valid, setValid] = useState(false);

  const [offset, setOffset] = useState(0);
  const [countPages, setCountPages] = useState(0);
  const [actualPage, setActualPage] = useState(1);
  const [startLimit, setStartLimit] = useState(1);

  const setFileToUpload = e => {
    let xt = e.target.files[0].name.split('.').pop();
    if(xt !== "xlsx" && xt !== "xls") {
      setFormError({ excelFile: "El archivo debe tener extensión .xlsx, .xls o .csv" });
    }
    if(e.target.files[0].size < 50000000) {
      setFileExcel(e.target.files[0]);
    } else {
      setFormError({ excelFile: "El archivo adjunto no debe superar los 50 Mb" });
    }
  }

  const uploadExcel = check => {
    let jsonSend;
    let urlPath;
    if(!check) {
      urlPath = "import-patients";
      jsonSend = new FormData();
      jsonSend.append('file', fileExcel);
    } else {
      urlPath = "save-patients";
      jsonSend = { patients: patients, total: total }
    }
    if(fileExcel) {
      setFormError({});
      setLoading(true);
      setDoneMsg(false);
      axios.post(Global.url + urlPath, jsonSend, { headers: { "authorization" : `Bearer ${getLocalStorage()}`} })
      .then(res => {
        if(res.data.status) {
          if(!check) {
            setTotal(res.data.total);
            setPatients(res.data.result);
            setCountPages(res.data.count);
          } else {
            setInfoUser(prevValue => ({...prevValue, "limitUser": res.data.infoUserUpdated }));
            setPatients({});
            setFileExcel(undefined);
          }
        } else {
          setPatients({});
        }
        setValid(res.data.valid);
        setIsOK(res.data.status);
        setMsg(res.data.message);
      }).catch(err => {
        setIsOK(false);
        setMsg("Hubo un error al subir el archivo. Intente más tarde. ");
      }).finally(() => {
        setLoading(false);
        setDoneMsg(true);
      });
    } else {
      setFormError({ excelFile: "Debes subir un archivo de Excel. "});
    }
  }

  const DownloadModelExcel = () => {
    axios.post(Global.url + 'download-excel', null, { headers: { "authorization" : `Bearer ${getLocalStorage()}`}, responseType: 'blob' })
      .then(res => {
        const url = window.URL.createObjectURL(res.data);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `ModeloPacientesRpDigitales.xlsx`);
        document.body.appendChild(link);
        link.click();
      }).catch(err => {
        console.log(err);
      });
  } 

  const movePage = direction => {
    if(direction) {
      if(actualPage !== countPages) {
        setOffset(offset + 10);
        setActualPage(actualPage + 1);
        setStartLimit(startLimit + 1);
      }
    } else {
      if(actualPage > 1) {
        setOffset(offset - 10);
        setActualPage(actualPage - 1);
        setStartLimit(startLimit - 1);
      }
    }
  }

  const goToPage = (result, page) => {
    setOffset(result);
    setActualPage(page);
  }

    return (
      <>
        <Header as='h3' className="headerUserPanel headerImport">
          Importar pacientes
        </Header>
        <Header as="h5" className="subHeaderImport">
          Para una óptima importación, recomendamos descargar el siguiente documento de Excel haciendo <span className="linkDownloadExcel" onClick={() => DownloadModelExcel()}>clic aquí</span> y completar los campos necesarios. Los que figuran de color amarillo son los campos obligatorios a rellenar. 
        </Header>
        <Form.Field
          className="form-control-xls"
          id='form-control-xls'
          control={Input}
          label="Subir archivo Excel"
          name="excelFile"
          type="file"
          accept=".xlsx"
          required
          error={formError.excelFile}
          onChange={e => setFileToUpload(e)}
        />
        { !patients.length &&
          <Button loading={loading} disabled={loading} onClick={() => uploadExcel(false)} className="btnExcel">Subir archivo Excel</Button>
        }
        {!patients.length && doneMsg && 
          <div className="messageDiv">
            <Message className={isOK ? "messageUserData messageUserData__success" : "messageUserData messageUserData__fail"}>
                {msg}
            </Message>
          </div>
        }
        {patients.length && doneMsg && 
          <div className="messageDiv">
            <Message className={isOK ? "messageUserData messageUserData__success" : "messageUserData messageUserData__fail"}>
                {msg}
            </Message>
          </div>
        }
        { patients.length && 
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan='8'>Pacientes a importar</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell collapsing>Nombre y apellido</Table.HeaderCell>
                <Table.HeaderCell collapsing>Documento</Table.HeaderCell>
                <Table.HeaderCell collapsing>Fecha de nacimiento</Table.HeaderCell>
                <Table.HeaderCell collapsing>Sexo</Table.HeaderCell>
                <Table.HeaderCell collapsing>Obra social</Table.HeaderCell>
                <Table.HeaderCell collapsing>Número</Table.HeaderCell>
                <Table.HeaderCell collapsing>Teléfono celular</Table.HeaderCell>
                <Table.HeaderCell collapsing>Email</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {patients?.length ?
                patients.map((patient, i) => {
                  if(i >= offset && i <= (offset+10)) {
                    return <RowTable key={i} patient={patient} saniziteNumbers={saniziteNumbers} />
                  } else {
                    return null
                  }
                }) :
                <Table.Row>
                  <Table.Cell colSpan="8"><div className="patientsNotFoundTable">El Excel no contiene ningún paciente. </div></Table.Cell>
                </Table.Row>
              }
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan='8'>
                  <Menu floated='right' pagination>
                  <Menu.Item as='a' icon onClick={() => movePage(false)}>
                      <Icon name='chevron left' />
                    </Menu.Item>
                    {Array.from(Array(2), (e, i) => (
                      ((startLimit+i) !== 0 && (startLimit+i) <= countPages) &&
                      (<Menu.Item as='a' key={startLimit+i} onClick={() => goToPage(((startLimit+i)*10-10), startLimit+i)} className={actualPage === (startLimit+i) ? "activeItemMenu" : "ItemMenu" }>{startLimit+i}</Menu.Item>)
                    ))}
                    <Menu.Item as='a' icon onClick={() => movePage(true)}>
                      <Icon name='chevron right' />
                    </Menu.Item>
                  </Menu>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
        </Table>
        }
        { patients.length &&
          <div className="divOKUploadPatients">
            <Label>Si todos los datos están correctos, por favor haga clic al siguiente botón</Label>
            <Button loading={loading} disabled={loading} onClick={() => uploadExcel(true)} className="btnExcel">Guardar datos</Button>
          </div>
        }
      </>
    )
}


function RowTable(props) {
  const { patient, saniziteNumbers } = props;
  return(
    <Table.Row>
      <Table.Cell>{patient?.name} {patient?.secondName} {patient?.lastname} {patient?.secondLastname}</Table.Cell>
      <Table.Cell>{patient?.typeDNI} {saniziteNumbers(patient?.dni)}</Table.Cell>
      <Table.Cell>{patient?.dateBirth}</Table.Cell>
      <Table.Cell>{patient?.sex}</Table.Cell>
      <Table.Cell>{patient?.obrasocial}</Table.Cell>
      <Table.Cell>{patient?.nro}</Table.Cell>
      <Table.Cell>{patient?.cellphone}</Table.Cell>
      <Table.Cell>{patient?.email}</Table.Cell>
    </Table.Row>
  )
}