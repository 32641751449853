import React from 'react';
import "./HeaderMenuMobile.scss";
import { NavLink } from 'react-router-dom';
import { Menu, Icon } from "semantic-ui-react";

export default function HeaderMenuMobile(props) {

    const { isLogged, isLoaded, setSidebarVisible } = props;

    return (
        <>
            <Menu.Item as={NavLink} exact to="/" onClick={() => setSidebarVisible(false)}>
              <div className="flexMenuMobile">
                <Icon name='home' /> Inicio
              </div>
            </Menu.Item>
            {isLoaded && isLogged &&
              <>
                <Menu.Item as={NavLink} exact to="/dashboard" onClick={() => setSidebarVisible(false)}>
                  <div className="flexMenuMobile">
                    <Icon name='table' /> Tablero
                  </div>
                </Menu.Item>
                <Menu.Item as={NavLink} exact to="/logout" onClick={() => setSidebarVisible(false)}>
                  <div className="flexMenuMobile">
                    <Icon name='log out' /> Cerrar sesión
                  </div>
                </Menu.Item>
              </>
            }
            {isLoaded && !isLogged &&
              <>
                <Menu.Item as={NavLink} exact to="/login" onClick={() => setSidebarVisible(false)}>
                  <div className="flexMenuMobile">
                    <Icon name='sign-in' /> Iniciar sesión
                  </div>
                </Menu.Item>
                <Menu.Item as={NavLink} exact to="/register" onClick={() => setSidebarVisible(false)}>
                  <div className="flexMenuMobile">
                    <Icon name='user plus' /> Registrarse
                  </div>
                </Menu.Item>
              </>
            }
        </>
    )
}
