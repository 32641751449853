import React, { useRef, useState, useEffect } from 'react';

import { Form, Select, Image, Header, Message } from "semantic-ui-react";

import axios from "axios";
import Global from "../../../../utils/Global";
import { getLocalStorage } from '../../../../utils/localStorage';
import { validateEmail , validateNumber } from '../../../../utils/Validations';
import { dataDoctorValuesOptions, countriesOptions, stateOptions, sexOptions } from '../../../../utils/ValuesOptions';

export default function Personal(props) {

    const { selectedUser, setSelectedUser } = props;

    const [signature, setSignature] = useState(undefined);
    const [loading, setLoading] = useState(false);

    const [isOK, setIsOK] = useState("messageUserData__fail");
    const [msg, setMsg] = useState("");
    const [doneMsg, setDoneMsg] = useState(false);

    const [formError, setFormError] = useState({});
    const [formData, setFormData] = useState({
        "name": selectedUser ? selectedUser.name : "",
        "lastname": selectedUser ? selectedUser.lastname : "",
        "sex": selectedUser ? selectedUser.sex : "",
        "state": selectedUser ? selectedUser.state : "",
        "address": selectedUser ? selectedUser.address : "",
        "zipcode": selectedUser ? selectedUser.zipcode : "",
        "phone": selectedUser ? selectedUser.phone : "",
        "emailJob": selectedUser ? selectedUser.emailJob : "",
        "cellphone": selectedUser ? selectedUser.cellphone : "",
        "country": selectedUser ? selectedUser.country : "",
        "speciality": selectedUser ? selectedUser.speciality : "",
        "dataDoctorValues": selectedUser ? selectedUser.dataDoctorValues : "", //CORRECTO
    });

    useEffect(() => {
        axios.post(Global.url + 'get-signature-doctor', { selectedUser: selectedUser }, { headers: { "authorization" : `Bearer ${getLocalStorage()}`}, responseType: 'blob' })
            .then(res => {
                var imageUrl = URL.createObjectURL(res.data);
                setSignature(imageUrl);
            }).catch(err => {
                console.log(err);
            })
    }, [selectedUser]);

    const saveData = () => {
        if(!formData.name) {
            setFormError({ name: "Este campo es obligatorio. "});
        } else if(!formData.lastname) {
            setFormError({ lastname: "Este campo es obligatorio. "});
        } else if(!formData.sex) {
            setFormError({ sex: "Este campo es obligatorio. "});
        } else if(formData.emailJob && !validateEmail(formData.emailJob)) {
            setFormError({ emailJob: "El email no es correcto. Si no desea introducir nada, déjelo en blanco. "});
        } else if(formData.phone && !validateNumber(formData.phone)) {
            setFormError({ phone: "El teléfono fijo no es válido. Si no desea introducir nada, déjelo en blanco. "});
        } else if(formData.cellphone && !validateNumber(formData.cellphone)) {
            setFormError({ cellphone: "El teléfono celular no es válido. Si no desea introducir nada, déjelo en blanco. "});
        } else if(formData.state === "" || formData.state === null) {
            setFormError({ state: "Este campo es obligatorio. "});
        } else if(!formData?.dataDoctorValues[stateOptions[formData?.country][formData?.state]?.mandatory] || !validateNumber(formData?.dataDoctorValues[stateOptions[formData.country][formData?.state]?.mandatory])) {
            setFormError({ [formData?.dataDoctorValues[stateOptions[formData?.country][formData?.state]?.mandatory]]: "Debes completar los campos con asterisco en rojo. "});
        } else {
            setLoading(true);
            axios.put(Global.url + 'change-data-personal-doctor', { formData: formData, selectedUser: selectedUser }, { headers: { "authorization" : `Bearer ${getLocalStorage()}`} })
                .then(res => {
                    setMsg(res.data.message);
                    if(res.data.status) {
                        setSelectedUser(res.data.result);
                        setIsOK("messageUserData__success");
                    } else {
                        setIsOK("messageUserData__fail");
                    }
                }).catch(err => {
                    setIsOK("messageUserData__fail");
                    console.log(err);
                }).finally(() => {
                    setDoneMsg(true);
                    setLoading(false);
                });
        }
    }
    
    return (
        <Form>
            <Form.Group widths='equal'>
                <Form.Input 
                    fluid label='Nombre' 
                    name="name"
                    required
                    value={formData.name}
                    placeholder='Nombre'
                    onChange={e => setFormData({...formData, [e.target.name]: e.target.value}) }
                />
                <Form.Input 
                    fluid label='Apellido'
                    name="lastname"
                    required
                    value={formData.lastname}
                    placeholder='Apellido'
                    onChange={e => setFormData({...formData, [e.target.name]: e.target.value}) }
                />
                <Form.Field 
                    control={Select}
                    options={sexOptions}
                    search
                    required
                    label="Sexo"
                    placeholder="Sexo"
                    name="sex"
                    value={formData.sex}
                    error={formError.sex}
                    onChange={(e, {value}) => setFormData({...formData, sex: value}) }
                />
            </Form.Group>
            <Form.Input 
                control={Select}
                options={countriesOptions}
                search
                required
                fluid name="country"
                label={"País donde se encuentra "+(formData.sex === "M" ? "matriculado" : "matriculada")}
                placeholder={"País donde se encuentra "+(formData.sex === "M" ? "matriculado" : "matriculada")}
                value={formData.country}
                onChange={(e, {value}) => setFormData({...formData, country: value}) }
            />
            <Form.Group widths='equal'>
                <Form.Field 
                    control={Select}
                    options={stateOptions[formData.country]}
                    search
                    required
                    label="Provincia donde trabaja"
                    name="state"
                    value={formData.state}
                    error={formError.state}
                    placeholder="Provincia donde trabaja"
                    onChange={(e, {value}) => setFormData({...formData, state: value}) }
                />
                <Form.Input 
                    fluid
                    name="address" 
                    label='Domicilio (Consultorio, clínica u hospital)' 
                    placeholder='Domicilio' 
                    value={formData.address}
                    onChange={e => setFormData({...formData, [e.target.name]: e.target.value}) }
                />
                <Form.Input 
                    fluid 
                    name="zipcode" 
                    label='Código postal' 
                    value={formData.zipcode}
                    placeholder='Código postal'
                    onChange={e => setFormData({...formData, [e.target.name]: e.target.value}) }
                />
            </Form.Group>
            <Form.Group widths='equal'>
                <Form.Input 
                    fluid name="emailJob" 
                    label='Correo electrónico' 
                    type="email" 
                    value={formData.emailJob}
                    error={formError.emailJob}
                    placeholder='Correo electrónico'
                    onChange={e => setFormData({...formData, [e.target.name]: e.target.value}) }
                />
                <Form.Input 
                    fluid 
                    name="phone" 
                    label='Teléfono fijo' 
                    error={formError.phone}
                    value={formData.phone}
                    placeholder='Teléfono fijo'
                    onChange={e => setFormData({...formData, [e.target.name]: e.target.value}) }
                />
                <Form.Input 
                    fluid 
                    name="cellphone" 
                    label='Teléfono celular' 
                    error={formError.cellphone}
                    value={formData.cellphone}
                    placeholder='Teléfono celular'
                    onChange={e => setFormData({...formData, [e.target.name]: e.target.value}) }
                />
            </Form.Group>
            <Form.Input 
                fluid name="speciality"
                label='Especialidad'
                placeholder='Especialidad'
                value={formData.speciality}
                onChange={e => setFormData({...formData, [e.target.name]: e.target.value}) }
            />
            <Form.Group widths='equal'>
                {Object.keys(formData?.dataDoctorValues).map((element, i) => (
                    <Form.Input
                        fluid name={element}
                        label={dataDoctorValuesOptions[formData.country][element]}
                        placeholder={dataDoctorValuesOptions[formData.country][element]}
                        error={formError[element]}
                        value={formData.dataDoctorValues[element]}
                        required={stateOptions[formData.country][formData?.state]?.mandatory === element ? true : false}
                        onChange={e => setFormData({...formData, [e.target.name]: e.target.value}) }
                    />
                ))}
            </Form.Group>
            <div>
                <div><strong>Previsualización de la firma digital</strong></div>
                <div className="signaturePhoto">
                    {signature && 
                        <Image src={signature} />
                    }
                </div>
            </div>
            {doneMsg && 
                <div className="messageDiv">
                    <Message className={isOK === "messageUserData__fail" ? "messageUserData messageUserData__fail" : "messageUserData messageUserData__success"}>
                        {msg}
                    </Message>
                </div>
            }
            <Form.Button loading={loading} disabled={loading} onClick={() => saveData()} className="btnSubmit btnChangePersonalDate">Guardar datos</Form.Button>
        </Form>
    )
}
