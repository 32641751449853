import React, { useState, useEffect } from 'react';
import { Header, Icon, Message, Menu, Table, Button } from "semantic-ui-react";
import { isMobile } from "react-device-detect";
import axios from "axios";
import Global from "../../../../utils/Global";
import { getLocalStorage } from "../../../../utils/localStorage";
import ModalComponent from "../../../ModalComponent/ModalComponent";
import "./ListPlanillas.scss";

export default function ListPlanillas(props) {

    const { infoUser } = props;

    const [allDiscounts, setAllDiscounts] = useState([]);
    const [loading, setLoading] = useState(false);
  
    const [doneMsg, setDoneMsg] = useState(false);
    const [isOK, setIsOK] = useState("messageUserData__fail");
    const [msg, setMsg] = useState("");
  
    const [discountToDelete, setDiscountToDelete] = useState(null);
    const [loadingDelete, setLoadingDelete] = useState(false);
  
    const [openModal, setOpenModal] = useState(false);
    const [contentModal, setContentModal] = useState("");
  
    const [offset, setOffset] = useState(0);
    const [countPages, setCountPages] = useState(0);
    const [actualPage, setActualPage] = useState(1);
    const [startLimit, setStartLimit] = useState(1);
  
    useEffect(() => {
      setLoading(true);
      axios.post(Global.url + 'count-all-discounts', null, { headers: { "authorization" : `Bearer ${getLocalStorage()}`} })
        .then(res => {
          setCountPages(res.data.result);
        }).catch(err => {
          setCountPages(0);
        });
    }, []);
  
    useEffect(() => {
      setLoading(true);
      axios.post(Global.url + 'load-all-discounts', { offset: offset }, { headers: { "authorization" : `Bearer ${getLocalStorage()}`} })
        .then(res => {
            setAllDiscounts(res.data.result);
        }).catch(err => {
            setAllDiscounts([]);
        }).finally(() => {
          setLoading(false);
        });
    }, [offset]);
  
    const movePage = direction => {
      if(direction) {
        if(actualPage !== countPages) {
          setOffset(offset + 10);
          setActualPage(actualPage + 1);
          setStartLimit(startLimit + 1);
        }
      } else {
        if(actualPage > 1) {
          setOffset(offset - 10);
          setActualPage(actualPage - 1);
          setStartLimit(startLimit - 1);
        }
      }
    }
  
    const goToPage = (result, page) => {
        setOffset(result);
        setActualPage(page);
    }
  
    const DeleteDiscount = (discount) => {
        setDiscountToDelete(discount);
        setContentModal(<div className="textModalContent">Se eliminará el descuento. ¿Estás {infoUser.sex === "M" ? "seguro" : "segura"}?</div>);
        setOpenModal(true);
    }
  
    const ConfirmDelete = () => {
        setLoadingDelete(true);
        axios.post(Global.url + 'delete-discount', { discount: discountToDelete }, { headers: { "authorization" : `Bearer ${getLocalStorage()}`} })
        .then(res => {
          setMsg(res.data.message);
          if(res.data.status) {
              setIsOK("messageUserData__success");
          } else {
              setIsOK("messageUserData__fail");
          }
          setDiscountToDelete(null);
          setOpenModal(false);
        }).catch(err => {
          setMsg("Hubo un error interno en el servidor, por favor intente más tarde. ");
          setIsOK("messageUserData__fail");
          console.log(err);
        }).finally(() => {
          setDoneMsg(true);
          setLoadingDelete(false);
        });
    }

    return (
        <>
        <Header as='h3' className="headerUserPanel">
            Listado de descuentos
        </Header>
        {doneMsg && 
            <div className="messageDiv">
                <Message className={isOK === "messageUserData__fail" ? "messageUserData messageUserData__fail" : "messageUserData messageUserData__success"}>
                    {msg}
                </Message>
            </div>
        }
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan='8'>Últimos descuentos añadidos</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell collapsing>Nombre</Table.HeaderCell>
              <Table.HeaderCell>Precio original</Table.HeaderCell>
              <Table.HeaderCell>Obra social</Table.HeaderCell>
              <Table.HeaderCell>Descuento aplicado (en %)</Table.HeaderCell>
              <Table.HeaderCell collapsing>Opciones</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {allDiscounts?.length ?
              allDiscounts.map((discount) => (
                <RowTable key={discount._id} discount={discount} DeleteDiscount={DeleteDiscount} />
              )) :
              <Table.Row>
                <Table.Cell colSpan="8"><div className="patientsNotFoundTable">No tenés ningún descuento cargado. </div></Table.Cell>
              </Table.Row>
            }
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan='8'>
                <Menu floated='right' pagination>
                <Menu.Item as='a' icon onClick={() => movePage(false)}>
                    <Icon name='chevron left' />
                  </Menu.Item>
                  {Array.from(Array(2), (e, i) => (
                    ((startLimit+i) !== 0 && (startLimit+i) <= countPages) &&
                    (<Menu.Item as='a' key={startLimit+i} onClick={() => goToPage(((startLimit+i)*10-10), startLimit+i)} className={actualPage === (startLimit+i) ? "activeItemMenu" : "ItemMenu" }>{startLimit+i}</Menu.Item>)
                  ))}
                  <Menu.Item as='a' icon onClick={() => movePage(true)}>
                    <Icon name='chevron right' />
                  </Menu.Item>
                </Menu>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
      </Table>
      <ModalComponent openModal={openModal} setOpenModal={setOpenModal} titleModal="Eliminar descuento" size="tiny" contentYes="Eliminar descuento" functionToDo={ConfirmDelete} loading={loadingDelete}>
        {contentModal}
      </ModalComponent>
      </>
    )
}


function RowTable(props) {

    // FALTA AGREGAR EL REMEDIO Y OBRA SOCIAL

  const { discount, DeleteDiscount } = props;
  
  return(
    <Table.Row>
      <Table.Cell>{discount.discount}</Table.Cell>
      <Table.Cell>{discount.obs}</Table.Cell>
      <Table.Cell>
        <Button className="tableCancelBtn" onClick={() => DeleteDiscount(discount)}>Eliminar</Button>
      </Table.Cell>
    </Table.Row>
  )
}