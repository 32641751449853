import React, { useState, useEffect } from 'react';

import { Card, Header } from "semantic-ui-react";

import moment from "moment";
import 'moment/locale/es';
import axios from "axios";
import Global from "../../../../utils/Global";
import { getLocalStorage } from  '../../../../utils/localStorage';

import StastiticSuscription from '../../../Dashboard/StastiticSuscription';

export default function StatisticsUser(props) {

    const { infoUser, selectedUser } = props;

    const [totalPatients, setTotalPatients] = useState(0);
    const [totalRecets, setTotalRecets] = useState(0);
    const [totalCreatedRecets, setTotalCreatedRecets] = useState(0);
    const [duplicatedRecets, setDuplicatedRecets] = useState(0);
    //const [allRecets, setAllRecets] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(selectedUser !== null) {
            setLoading(true);
            axios.post(Global.url + 'count-recets-doctor', { duplicated: true, selectedUser: selectedUser }, { headers: { "authorization" : `Bearer ${getLocalStorage()}`} })
            .then(res => {
                setTotalRecets(res.data.total);
                setTotalCreatedRecets(res.data.created);
                setDuplicatedRecets(res.data.duplicated);
                //setCountPages(res.data.result);
            }).catch(err => {
                //setCountPages(1);
            });
        }
    }, [selectedUser]);
    
      useEffect(() => {
        if(selectedUser !== null) {
            setLoading(true);
            axios.post(Global.url + 'count-all-patients-doctor', { selectedUser: selectedUser }, { headers: { "authorization" : `Bearer ${getLocalStorage()}`} })
            .then(res => {
                setTotalPatients(res.data.total);
            }).catch(err => {
                setTotalPatients(0);
            });
        }
      }, [selectedUser]);
    
    return (
        <>
        {selectedUser !== null && 
            <Card.Group centered className="cardsGroup">
                <StastiticSuscription infoUser={selectedUser} />
                <Card className="infoCard infoCard__size infoCard__red">
                    <Card.Content>
                        <Card.Header>Total de pacientes</Card.Header>
                    <Card.Meta>
                        <span className='date'>Desde {moment(selectedUser?.created).locale('es').format("MMMM YYYY")}</span>
                    </Card.Meta>
                    <Card.Description>
                        <div className="numberCard">{totalPatients}</div>
                    </Card.Description>
                    </Card.Content>
                </Card>
                <Card className="infoCard infoCard__size infoCard__green">
                    <Card.Content>
                        <Card.Header>Total de recetas</Card.Header>
                    <Card.Meta>
                        <span className='date'>Desde {moment(selectedUser?.created).locale('es').format("MMMM YYYY")}</span>
                    </Card.Meta>
                    <Card.Description>
                        <div className="numberCard">{totalRecets}</div>
                    </Card.Description>
                    </Card.Content>
                </Card>
                <Card className="infoCard infoCard__size infoCard__blue">
                    <Card.Content>
                        <Card.Header>Recetas creadas</Card.Header>
                    <Card.Meta>
                        <span className='date'>Desde {moment(selectedUser?.created).locale('es').format("MMMM YYYY")}</span>
                    </Card.Meta>
                    <Card.Description>
                        <div className="numberCard">{totalCreatedRecets}</div>
                    </Card.Description>
                    </Card.Content>
                </Card>
                <Card className="infoCard infoCard__size infoCard__brown">
                    <Card.Content>
                        <Card.Header>Recetas duplicadas</Card.Header>
                    <Card.Meta>
                        <span className='date'>Desde {moment(selectedUser?.created).locale('es').format("MMMM YYYY")}</span>
                    </Card.Meta>
                    <Card.Description>
                        <div className="numberCard">{duplicatedRecets}</div>
                    </Card.Description>
                    </Card.Content>
                </Card>
            </Card.Group>
        }
        {selectedUser === null &&
            <Header>
                Ningún médico seleccionado. 
            </Header>
        }
        </>
    )
}
