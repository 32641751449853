import React, { useState, useEffect } from 'react';
import { Header, Table, Menu, Icon, Button, Message } from "semantic-ui-react";
import moment from "moment";
import "./List.scss";
import axios from "axios";
import Global from "../../../../utils/Global";
import { saniziteNumbers } from '../../../../utils/saniziteValues';
import { getLocalStorage } from '../../../../utils/localStorage';

import ModalComponent from "../../../ModalComponent/ModalComponent";

export default function List(props) {

  const { infoUser } = props;

  const [allPatients, setAllPatients] = useState([]);
  const [loading, setLoading] = useState(false);

  const [doneMsg, setDoneMsg] = useState(false);
  const [isOK, setIsOK] = useState("messageUserData__fail");
  const [msg, setMsg] = useState("");

  const [loadingDelete, setLoadingDelete] = useState(false);
  const [patientToDelete, setPatientToDelete] = useState(null);
  const [deletedPatient, setDeletedPatient] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [contentModal, setContentModal] = useState("");

  const [offset, setOffset] = useState(0);
  const [countPages, setCountPages] = useState(0);
  const [actualPage, setActualPage] = useState(1);
  const [startLimit, setStartLimit] = useState(1);

  useEffect(() => {
    setLoading(true);
    axios.post(Global.url + 'count-all-patients', null, { headers: { "authorization" : `Bearer ${getLocalStorage()}`} })
      .then(res => {
        setCountPages(res.data.result);
      }).catch(err => {
        setCountPages(0);
      });
  }, [deletedPatient]);

  useEffect(() => {
    setLoading(true);
    axios.post(Global.url + 'load-all-patients', { offset: offset }, { headers: { "authorization" : `Bearer ${getLocalStorage()}`} })
      .then(res => {
        setAllPatients(res.data.result);
      }).catch(err => {
        setAllPatients([]);
      }).finally(() => {
        setLoading(false);
      });
  }, [deletedPatient, offset]);

  const movePage = direction => {
    if(direction) {
      if(actualPage !== countPages) {
        setOffset(offset + 10);
        setActualPage(actualPage + 1);
        setStartLimit(startLimit + 1);
      }
    } else {
      if(actualPage > 1) {
        setOffset(offset - 10);
        setActualPage(actualPage - 1);
        setStartLimit(startLimit - 1);
      }
    }
  }

  const goToPage = (result, page) => {
    setOffset(result);
    setActualPage(page);
  }

  const DeletePatient = (patient) => {
    setPatientToDelete(patient);
    let totalRecetas = parseInt(patient.newRecetas) + parseInt(patient.duplicatedRecetas);
    let msgRecetas = "posee "+ totalRecetas + " recetas. Se ocultará el paciente y no figurará más en su listado de pacientes, pero sus recetas se conservarán. ";
    if(totalRecetas === 0) {
      msgRecetas = "no posee ninguna receta. Se ocultará el paciente y no figurará más en su listado de pacientes. ";
    }
    setContentModal(<ContentModalHidePatient msgRecetas={msgRecetas} infoUser={infoUser} />);
    setOpenModal(true);
  }

  const ConfirmDelete = () => {
      setLoadingDelete(true);
      axios.post(Global.url + 'delete-patient', { patient: patientToDelete }, { headers: { "authorization" : `Bearer ${getLocalStorage()}`} })
      .then(res => {
        setMsg(res.data.message);
        if(res.data.status) {
            setIsOK("messageUserData__success");
        } else {
            setIsOK("messageUserData__fail");
        }
        setPatientToDelete(null);
        setDeletedPatient(prevValue => !prevValue);
        setOpenModal(false);
      }).catch(err => {
        setMsg("Hubo un error interno en el servidor, por favor intente más tarde. ");
        setIsOK("messageUserData__fail");
        console.log(err);
      }).finally(() => {
        setDoneMsg(true);
        setLoadingDelete(false);
      });
  }

    return (
        <>
        <Header as='h3' className="headerUserPanel">
            Listado de pacientes
        </Header>
        {doneMsg && 
            <div className="messageDiv">
                <Message className={isOK === "messageUserData__fail" ? "messageUserData messageUserData__fail" : "messageUserData messageUserData__success"}>
                    {msg}
                </Message>
            </div>
        }
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan='10'>Últimos pacientes añadidos</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell collapsing>Fecha</Table.HeaderCell>
              <Table.HeaderCell>Nombre y apellido</Table.HeaderCell>
              <Table.HeaderCell>Documento</Table.HeaderCell>
              <Table.HeaderCell collapsing>Fecha de nacimiento</Table.HeaderCell>
              <Table.HeaderCell collapsing>Sexo</Table.HeaderCell>
              <Table.HeaderCell>Obra social</Table.HeaderCell>
              <Table.HeaderCell>Número</Table.HeaderCell>
              <Table.HeaderCell>Recetas creadas</Table.HeaderCell>
              <Table.HeaderCell>Recetas duplicadas</Table.HeaderCell>
              {/* <Table.HeaderCell>Planillas generadas</Table.HeaderCell> */}
              <Table.HeaderCell collapsing>Opciones</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {allPatients?.length ?
              allPatients.map((patient) => (
                <RowTable key={patient._id} patient={patient} DeletePatient={DeletePatient} saniziteNumbers={saniziteNumbers} />
              )) :
              <Table.Row>
                <Table.Cell colSpan="10"><div className="patientsNotFoundTable">No tenés ningún paciente cargado. </div></Table.Cell>
              </Table.Row>
            }
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan='10'>
                <Menu floated='right' pagination>
                <Menu.Item as='a' icon onClick={() => movePage(false)}>
                    <Icon name='chevron left' />
                  </Menu.Item>
                  {Array.from(Array(2), (e, i) => (
                    ((startLimit+i) !== 0 && (startLimit+i) <= countPages) &&
                    (<Menu.Item as='a' key={startLimit+i} onClick={() => goToPage(((startLimit+i)*10-10), startLimit+i)} className={actualPage === (startLimit+i) ? "activeItemMenu" : "ItemMenu" }>{startLimit+i}</Menu.Item>)
                  ))}
                  <Menu.Item as='a' icon onClick={() => movePage(true)}>
                    <Icon name='chevron right' />
                  </Menu.Item>
                </Menu>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
      </Table>
      <ModalComponent openModal={openModal} setOpenModal={setOpenModal} titleModal="Ocultar paciente" size="tiny" contentYes="Ocultar paciente" functionToDo={ConfirmDelete} loading={loadingDelete}>
        {contentModal}
      </ModalComponent>
      </>
    )
}


function RowTable(props) {

  const { patient, DeletePatient, saniziteNumbers } = props;
  
  return(
    <Table.Row>
      <Table.Cell>{moment(patient.created).format("DD/MM/YYYY")}</Table.Cell>
      <Table.Cell>{patient.name} {patient.secondName} {patient.lastname} {patient.secondLastname}</Table.Cell>
      <Table.Cell>{patient.typeDNI} {saniziteNumbers(patient.dni)}</Table.Cell>
      <Table.Cell>{patient.dateBirth}</Table.Cell>
      <Table.Cell>{patient.sex}</Table.Cell>
      <Table.Cell>{patient.obrasocial}</Table.Cell>
      <Table.Cell>{patient.nro}</Table.Cell>
      <Table.Cell>{patient.newRecetas}</Table.Cell>
      <Table.Cell>{patient.duplicatedRecetas}</Table.Cell>
      {/* <Table.Cell>{patient.planillasGenerated}</Table.Cell> */}
      <Table.Cell>
        <Button className="tableCancelBtn" onClick={() => DeletePatient(patient)}>Ocultar</Button>
      </Table.Cell>
    </Table.Row>
  )
}

function ContentModalHidePatient(props) {

  const { msgRecetas, infoUser } = props;
  
  return(
    <>
      <Message warning>
        <Message.Header>Advertencia</Message.Header>
        <Message.Content>Esta opción es irreversible. </Message.Content>
      </Message>
      <div className="textModalContent">Este paciente {msgRecetas} ¿Estás {infoUser.sex === "M" ? "seguro" : "segura"}?</div>
    </>
  )
}