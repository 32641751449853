import React, { useState, useEffect } from 'react';
import { Grid, Menu, Input } from "semantic-ui-react";
import { isMobile } from "react-device-detect";
import axios from "axios";
import Global from "../../../utils/Global";
import { getLocalStorage } from "../../../utils/localStorage";
import "./Users.scss";
//components
import Personal from "./Personal";
import ConfigUser from "./ConfigUser";
import StatisticsUser from "./StatisticsUser";
import PacientesUser from "./PacientesUser";
import VerRecetas from "./VerRecetas";
import Privacity from "./Privacity";

export default function Users(props) {

    const { infoUser } = props;

    const [menuUser, setMenuUser] = useState("statistics");

    const [formError, setFormError] = useState({});
    const [resultsSearch, setResultsSearch] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [searching, setSearching] = useState(false);
    const [gotValues, setGotValues] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);

    useEffect(() => {
        document.getElementById("form-control-doctor-input").focus();
    }, []);

    const selectUser = item => {
        setSearchValue("");
        setSelectedUser(item);
        setGotValues(false);
        setResultsSearch([]);
    }

    const searchDoctor = value => {
        setSearchValue(value);
        setSearching(true);
        setGotValues(false);
        setTimeout(function() {
            axios.post(Global.url + 'search-doctor', { search: value }, { headers: { "authorization" : `Bearer ${getLocalStorage()}`} })
                .then(res => {
                    setResultsSearch(res.data.results);
                }).catch(err => {
                    setResultsSearch([]);
                }).finally(() => {
                    setSearching(false);
                    setGotValues(true);
            });
        }, 300);
    }

    return (
        <>
            <Input
                className="doctorInputSearch__Admin"
                id="form-control-doctor-input"
                onChange={e => searchDoctor(e.target.value)}
                fluid label='Buscar médico' 
                name="doctor"
                value={searchValue}
                error={formError.doctor}
                icon={searching ? "circle notch" : "search"}
                placeholder='Buscar médico (nombre, apellido o DNI)'
            />
            {gotValues &&
                <div className="listValuesFoundUser">
                    {resultsSearch.length === 0 && 
                        <div className="aValueFound">No se encontró ningún médico que coincida. </div>
                    }
                    {resultsSearch.map((item) => (
                        <div 
                            onClick={() => selectUser(item)} 
                            className="aValueFound"
                            key={item._id}
                        >
                            {item.name} {item.lastname} - <strong>DNI</strong>: {item.dni} - <strong>Email</strong>: {item.email}
                        </div>
                    ))}
                </div>
            }
            {selectedUser !== null &&
                <div className="infoSelectedDoctor">
                    <span className="textSelectedPatient"><strong>Médico:</strong> <span>{selectedUser.name} {selectedUser.lastname}</span></span>
                    <span className="textSelectedPatient"><strong>DNI:</strong> <span>{selectedUser.dni}</span></span>
                    <span className="textSelectedPatient"><strong>Email:</strong> <span>{selectedUser.email}</span></span>
                    <span className="textSelectedPatient"><strong>Usuario:</strong> <span>{selectedUser.user}</span></span>
                </div>
            }
            <Grid className="gridUser gridUser__Admin" columns="equal">
                <Grid.Column className={isMobile ? "menuVertical" : "menuUserGrid"} width={isMobile ? 16 : 4}>
                    <Menu fluid vertical tabular={isMobile ? false : true} style={{ minHeight: isMobile ? "auto" : "67vh", height: "100%" }}>
                        <Menu.Item 
                            name="Estadísticas del médico"
                            content="Estadísticas del médico"
                            active={menuUser === "statistics"}
                            disabled={selectedUser === null ? true : false}
                            onClick={() => setMenuUser("statistics")}
                        />
                        <Menu.Item 
                            name="Modificar datos personales"
                            content="Modificar datos personales"
                            active={menuUser === "personal"}
                            disabled={selectedUser === null ? true : false}
                            onClick={() => setMenuUser("personal")}
                        />
                        <Menu.Item 
                            name="Privacidad del médico"
                            content="Privacidad del médico"
                            active={menuUser === "privacity"}
                            disabled={selectedUser === null ? true : false}
                            onClick={() => setMenuUser("privacity")}
                        />
                        <Menu.Item 
                            name="Configuración de cuenta"
                            content="Configuración de cuenta"
                            active={menuUser === "config"}
                            disabled={selectedUser === null ? true : false}
                            onClick={() => setMenuUser("config")}
                        />
                        <Menu.Item 
                            name="Modificar pacientes"
                            content="Modificar pacientes"
                            active={menuUser === "patients"}
                            disabled={selectedUser === null ? true : false}
                            onClick={() => setMenuUser("patients")}
                        />
                        <Menu.Item 
                            name="Ver recetas"
                            content="Ver recetas"
                            active={menuUser === "recets"}
                            disabled={selectedUser === null ? true : false}
                            onClick={() => setMenuUser("recets")}
                        />
                    </Menu>
                </Grid.Column>
                <Grid.Column className="contentUserGrid">
                    {(() => {
                        if (menuUser === "personal") {
                            return <Personal infoUser={infoUser} selectedUser={selectedUser} setSelectedUser={setSelectedUser} />
                        } else if (menuUser === "config") {
                            return <ConfigUser infoUser={infoUser} selectedUser={selectedUser} />
                        } else if(menuUser === "privacity") {
                            return <Privacity infoUser={infoUser} selectedUser={selectedUser} />
                        } else if(menuUser === "statistics") {
                            return <StatisticsUser infoUser={infoUser} selectedUser={selectedUser} />
                        } else if(menuUser === "patients") {
                            return <PacientesUser infoUser={infoUser} selectedUser={selectedUser} />
                        } else if(menuUser === "recets") {
                            return <VerRecetas infoUser={infoUser} selectedUser={selectedUser} />
                        } else {
                            return <StatisticsUser infoUser={infoUser} selectedUser={selectedUser} />
                        }
                    })()}
                </Grid.Column>
            </Grid>
        </>
    )
}
