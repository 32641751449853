import React, { useState, useEffect } from 'react';
import Global from "../../../utils/Global";
import axios from "axios";
import { validateEmail, validateUser } from "../../../utils/Validations";
import { dniOptionsRegister, countriesOptions } from "../../../utils/ValuesOptions";
import { Form, Icon, Input, Button, Message, Dropdown, Label, Select } from "semantic-ui-react";
import ModalComponent from '../../../components/ModalComponent/ModalComponent';
import "./Step1.scss";

export default function Step1(props) {

    const { setStepRegister, setSavedUser } = props;

    useEffect(() => {
        document.getElementById("form-input-control-user").focus();
    }, []);

    const [openModalTOS, setOpenModalTOS] = useState(false);
    const [visiblePass, setVisiblePass] = useState(false);
    const [visibleRepeatPass, setVisibleRepeatPass] = useState(false);
    const [formError, setFormError] = useState({});
    const [status, setStatus] = useState(false);
    const [statusRes, setStatusRes] = useState("failMessage");
    const [contentMessage, setContentMessage] = useState("");

    const [loading, setLoading] = useState(false);

    const [formData, setFormData] = useState({
        "user": "",
        "password": "",
        "repeatPassword": "",
        "repeatEmail": "",
        "email": "",
        "typeDNI": "DNI",
        "country": "AR",
        "dni": "",
        "tos": false
    });

    const registerNow = () => {
        if(!formData.dni) {
            setFormError({ "dni": "El campo DNI es obligatorio. " });
        } else if(!formData.user) {
            setFormError({ "user": "El campo usuario es obligatorio. " });
        } else if(!validateUser(formData.user)) {
            setFormError({ "user": "El campo usuario solo puede contener letras, números y guiones y debe tener al menos 4 caracteres. " });
        } else if(!formData.email) {
            setFormError({ "email": "El campo email es obligatorio. " });
        } else if (!validateEmail(formData.email)) {
            setFormError({ "email": "El email no es correcto. " });
        } else if(formData.email !== formData.repeatEmail) {
            setFormError({ "repeatEmail": "Los emails no coinciden. " });
        } else if(formData.password !== formData.repeatPassword) {
            setFormError({ "password": "Las contraseñas no coinciden. " });
        } else if(!formData.password || !formData.repeatPassword) {
            setFormError({ "password": "Los campos Contraseña son obligatorios. " });
        } else if(formData.password !== formData.repeatPassword) {
            setFormError({ "password": "Las contraseñas no coinciden. " });
        } else if(formData.password.length < 8) {
            setFormError({ "password": "La contraseña debe tener al menos 8 carácteres." });
        } else if(formData.tos === false) {
            setFormError({ "tos": "Debes aceptar los términos y condiciones de uso. " });
        } else {
            setFormError({});
            setLoading(true);
            axios.post(Global.url + "check-sign-up", { formData })
                .then(res => {
                    if(res.data.status) {
                        setStatusRes("doneMessage");
                        setSavedUser(res.data.savedUser);
                        setStepRegister(2);
                    } else {
                        setContentMessage(res.data.message);
                    }
                }).catch(err => {
                    setContentMessage(err.data);
                    setStatusRes("failMessage");
                    setSavedUser("");
                }).finally(() => {
                    setStatus(true);
                    setLoading(false);
                });
        }
    }

    return (
        <div className="contentRegister">
            <Form className="formRegister">
                <Form.Field 
                    control={Select}
                    options={countriesOptions}
                    search
                    required
                    label="Seleccione el país donde está matriculado"
                    placeholder="País donde tiene su matrícula"
                    name="country"
                    value={formData.country}
                    error={formError.country}
                    onChange={(e, {value}) => setFormData({...formData, country: value}) }
                />
                <Message
                    className="messageHelp"
                    color='yellow'
                >
                    Si no figura tu país, por favor colaboranos enviando un email a <a href="mailto:contacto@rpdigitales.com.ar">contacto@rpdigitales.com.ar</a>
                </Message>
                <Label className="labelForm">Escriba su documento de identidad</Label>
                <Input
                    id='form-input-control-dni'
                    label={<Dropdown defaultValue={formData.typeDNI} options={dniOptionsRegister[formData.country]} name="typeDNI" onChange={(e, { value }) => setFormData({...formData, typeDNI: value})} />}
                    labelPosition='left'
                    placeholder='Documento de identidad'
                    required
                    type="number"
                    min="0"
                    name="dni"
                    error={formError.dni}
                    onChange={e => setFormData({ ...formData, [e.target.name]: e.target.value })}
                />
                <Form.Field
                    id='form-input-control-user'
                    control={Input}
                    label='Escriba un nombre de usuario para iniciar sesión'
                    placeholder='Usuario'
                    required
                    name="user"
                    error={formError.user}
                    icon={<Icon name="user" />}
                    onChange={e => setFormData({ ...formData, [e.target.name]: e.target.value })}
                />
                <Form.Field
                    id='form-input-control-email'
                    control={Input}
                    label='Escriba tu email para recibir el mail de activación de cuenta'
                    placeholder='Email'
                    required
                    name="email"
                    error={formError.email}
                    icon={<Icon name="at" />}
                    onChange={e => setFormData({ ...formData, [e.target.name]: e.target.value })}
                />
                <Form.Field
                    id='form-input-control-email'
                    control={Input}
                    label='Repita el email'
                    placeholder='Repita el email'
                    required
                    name="repeatEmail"
                    error={formError.repeatEmail}
                    icon={<Icon name="at" />}
                    onChange={e => setFormData({ ...formData, [e.target.name]: e.target.value })}
                />
                <Form.Field
                    id='form-input-control-password'
                    control={Input}
                    label='Contraseña'
                    placeholder='Contraseña (debe tener al menos 8 carácteres)'
                    required
                    type={visiblePass ? "text" : "password"}
                    name="password"
                    error={formError.password}
                    icon={<Icon name={visiblePass ? 'eye slash' : 'eye'} link onClick={() => setVisiblePass(prevValue => !prevValue)} />}
                    onChange={e => setFormData({ ...formData, [e.target.name]: e.target.value })}
                />
                <Form.Field
                    id='form-input-control-repeat-password'
                    control={Input}
                    label='Repita contraseña'
                    placeholder='Repita contraseña (debe tener al menos 8 carácteres)'
                    required
                    type={visibleRepeatPass ? "text" : "password"}
                    name="repeatPassword"
                    error={formError.password}
                    icon={<Icon name={visibleRepeatPass ? 'eye slash' : 'eye'} link onClick={() => setVisibleRepeatPass(prevValue => !prevValue)} />}
                    onChange={e => setFormData({ ...formData, [e.target.name]: e.target.value })}
                />
                <div className="readTOS" onClick={() => setOpenModalTOS(true)}>Leer los términos y condiciones de uso. </div>
                <Form.Checkbox 
                    id="form-input-control-checkbox"
                    name="tos"
                    className="checkRegister"
                    checked={formData.tos}
                    error={formError.tos}
                    label='Acepto los términos y condiciones de uso' 
                    onChange={e => setFormData({ ...formData, [e.target.name]: e.target.checked })}
                />
                <div className="btnRegisterNow">
                    <Button color='blue' loading={loading} disabled={formData.tos === false ? true : loading} onClick={() => registerNow()}>
                        Registrarse e ir al paso 2
                    </Button>
                </div>
                {status && 
                    <Message className={statusRes}>
                        {contentMessage}
                    </Message>
                }
            </Form>
            <ModalComponent includeActions={false} titleModal="Términos y condiciones de uso" contentYes="Acepto" size="large" openModal={openModalTOS} setOpenModal={setOpenModalTOS}>
                TERMINOS Y CONDICIONES DEL USO
            </ModalComponent>
        </div>
    )
}
