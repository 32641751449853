import React from 'react';

import { Card, Grid, Container, Icon } from "semantic-ui-react";
import ScrollAnimation from 'react-animate-on-scroll';
import { isMobile } from "react-device-detect";

import "./Howworks.scss";

export default function Howworks() {
    return (
        <div className="howworks">
            <div className="figure5 figure5__left" />
            <div className="figure5 figure5__right" />
            <div className={isMobile ? "titleHowworks titleHowworks__Mobile" : "titleHowworks"}>
                ¿Cómo funciona?
            </div>
            <div className="cardsShow">
                {!isMobile &&
                    <Container>
                        <Grid columns="equal">
                            <Grid.Row className="rowHowWorks rowHowWorks__1">
                                <Grid.Column width="4" className="columnDivIcon">
                                    <ScrollAnimation animateIn="fadeInRight" animateOnce={true}>
                                        <div className="divIconFather divIconFather__1">
                                        <Icon name="user md" size="huge" className="iconDivHowWorks iconDivHowWorks__pd1 iconDivHowWorks__impar" />
                                            <div className="lineDivIcon" />
                                            <div className="circleDivIcon" />
                                        </div>
                                    </ScrollAnimation>
                                </Grid.Column>
                                <Grid.Column className="separateColumn">
                                    <ScrollAnimation animateIn="fadeInLeft" animateOnce={true}>
                                        <Card className="cardHowWorks">
                                            <Card.Header className="header__impar">1. Completá tus datos como médico</Card.Header>
                                            <Card.Content>
                                                Para poder utilizar el sistema RP Digitales como médico, firmá y completá tus datos profesionales. ¡Esto solo lo tendrás que hacer por única vez!
                                            </Card.Content>
                                        </Card>
                                    </ScrollAnimation>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row className="rowHowWorks rowHowWorks__2">
                                <Grid.Column width="4" className="columnDivIcon">
                                    <ScrollAnimation animateIn="fadeInRight" animateOnce={true} delay={100}>
                                        <div className="divIconFather divIconFather__2">
                                        <Icon name="add user" size="huge" className="iconDivHowWorks iconDivHowWorks__pd2 iconDivHowWorks__par" />
                                            <div className="lineDivIcon" />
                                            <div className="circleDivIcon" />
                                        </div>
                                    </ScrollAnimation>
                                </Grid.Column>
                                <Grid.Column className="separateColumn">
                                    <ScrollAnimation animateIn="fadeInLeft" animateOnce={true} delay={100}>
                                        <Card className="cardHowWorks">
                                            <Card.Header className="header__par">2. Creá paciente</Card.Header>
                                            <Card.Content>
                                                Para crear una receta o una planilla de un paciente por 1º vez en el tablero digital, debes crear un paciente y completar los datos correspondientes del mismo, de acuerdo a la validación de datos personales.
                                            </Card.Content>
                                        </Card>
                                    </ScrollAnimation>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row className="rowHowWorks rowHowWorks__3">
                                <Grid.Column width="4" className="columnDivIcon">
                                    <ScrollAnimation animateIn="fadeInRight" animateOnce={true} delay={200}>
                                        <div className="divIconFather divIconFather__3">
                                        <Icon name="pills" size="huge" className="iconDivHowWorks iconDivHowWorks__pd3 iconDivHowWorks__impar" />
                                            <div className="lineDivIcon" />
                                            <div className="circleDivIcon" />
                                        </div>
                                    </ScrollAnimation>
                                </Grid.Column>
                                <Grid.Column className="separateColumn">
                                    <ScrollAnimation animateIn="fadeInLeft" animateOnce={true} delay={200}>
                                        <Card className="cardHowWorks">
                                            <Card.Header className="header__impar">3. Creá receta</Card.Header>
                                            <Card.Content>
                                                Después de crear el paciente, ingresá a Recetas según lo que se necesita y seleccioná el paciente correspondiente. Tras cargarse sus datos, escribís la receta (o duplicás una receta ya existente) o la planilla con la información necesaria. Antes de crearla, se previsualizará el documento para asegurarte de que toda la información ingresada sea correcta. 
                                            </Card.Content>
                                        </Card>
                                    </ScrollAnimation>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row className="rowHowWorks rowHowWorks__4">
                                <Grid.Column width="4" className="columnDivIcon">
                                    <ScrollAnimation animateIn="fadeInRight" animateOnce={true} delay={300}>
                                        <div className="divIconFather divIconFather__4">
                                        <Icon name="file pdf outline" size="huge" className="iconDivHowWorks iconDivHowWorks__pd4 iconDivHowWorks__par" />
                                            <div className="lineDivIcon" />
                                            <div className="circleDivIcon" />
                                        </div>
                                    </ScrollAnimation>
                                </Grid.Column>
                                <Grid.Column className="separateColumn">
                                    <ScrollAnimation animateIn="fadeInLeft" animateOnce={true} delay={300}>
                                        <Card className="cardHowWorks">
                                            <Card.Header className="header__par">4. Enviá receta a tus pacientes</Card.Header>
                                            <Card.Content>
                                                Una vez confirmada la creación de la receta, se generará un documento .PDF para que puedas descargarlo o enviarlo a tus pacientes por email. Con eso el paciente podrá ir a la farmacia. ¡Listo, así de fácil y sin necesidad de contacto físico o escribir a mano algún documento! Además podrás ver el historial de tus pacientes, acceder a tu listado de recetas creadas y duplicadas y generar documentos .PDF cuando quieras, desde tu computadora o celular.
                                            </Card.Content>
                                        </Card>
                                    </ScrollAnimation>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Container>
                }
                {isMobile &&
                    <>
                    <ScrollAnimation animateIn="fadeInDown" animateOnce={true}>
                        <Card className="cardHowWorks cardHowWorks__Mobile">
                            <Card.Header className="header__impar">1. Completá tus datos como médico</Card.Header>
                            <Card.Content>
                                Para poder utilizar el sistema RP Digitales como médico, firmá y completá tus datos profesionales. ¡Esto solo lo tendrás que hacer por única vez!
                            </Card.Content>
                        </Card>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="fadeInLeft" animateOnce={true} delay={100}>
                        <Card className="cardHowWorks cardHowWorks__Mobile">
                            <Card.Header className="header__par">2. Creá paciente</Card.Header>
                            <Card.Content>
                                Para crear una receta o una planilla de un paciente por 1º vez en el tablero digital, debes crear un paciente y completar los datos correspondientes del mismo, de acuerdo a la validación de datos personales.
                            </Card.Content>
                        </Card>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="fadeInRight" animateOnce={true} delay={200}>
                        <Card className="cardHowWorks cardHowWorks__Mobile">
                            <Card.Header className="header__impar">3. Creá receta</Card.Header>
                            <Card.Content>
                                Después de crear el paciente, ingresá a Recetas según lo que se necesita y seleccioná el paciente correspondiente. Tras cargarse sus datos, escribís la receta (o duplicás una receta ya existente) o la planilla con la información necesaria. Antes de crearla, se previsualizará el documento para asegurarte de que toda la información ingresada sea correcta. 
                            </Card.Content>
                        </Card>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={300}>
                        <Card className="cardHowWorks cardHowWorks__Mobile">
                            <Card.Header className="header__par">4. Enviá receta a tus pacientes</Card.Header>
                            <Card.Content>
                                Una vez confirmada la creación de la receta, se generará un documento .PDF para que puedas descargarlo o enviarlo a tus pacientes por email. Con eso el paciente podrá ir a la farmacia. ¡Listo, así de fácil y sin necesidad de contacto físico o escribir a mano algún documento! Además podrás ver el historial de tus pacientes, acceder a tu listado de recetas creadas y duplicadas y generar documentos .PDF cuando quieras, desde tu computadora o celular.
                            </Card.Content>
                        </Card>
                    </ScrollAnimation>
                    </>
                }
            </div>
        </div>
    )
}
