import React, { useState, useEffect } from 'react';

import "./Step3.scss";

import axios from "axios";
import Global from "../../../utils/Global";
import moment from "moment";

import { Grid, Image, Button } from "semantic-ui-react";

//payments
import Visa from "../../../assets/images/payments/VISA.svg";
import Mastercard from "../../../assets/images/payments/MASTERCARD.svg";
import AMEX from "../../../assets/images/payments/AMEX.svg";
import Cabal from "../../../assets/images/payments/cabal.svg";
import Naranja from "../../../assets/images/payments/naranja.svg";

export default function Step3(props) {

    const { setStepRegister, savedUser, selectedSuscription } = props;

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(savedUser === "") {
            setStepRegister(1);
        }
    }, []);

    const paySuscription = () => {
        setLoading(true);
        axios.post(Global.url + "create-subscriber", { token: savedUser, subscription: selectedSuscription })
            .then(res => {
                if(res.data.status) {
                    setTimeout(function() {
                        setLoading(false);
                        window.location.replace(res.data.result.sourceUrl);
                    }, 2000);
                } else {
                    //FAIL
                }
        }).catch(err => {
            console.log(err);
            setLoading(false);
        });
    }

    return (
        <>
            <div className="allMethods">
                <Grid columns="equal">
                    <Grid.Column className="gridColumnPayment">
                        <Image src={Visa} />
                        <div className="titleCardPayment">
                            Visa débito y crédito
                        </div>
                    </Grid.Column>
                    <Grid.Column className="gridColumnPayment">
                        <Image src={Mastercard} />
                        <div className="titleCardPayment">
                            Mastercard débito y crédito
                        </div>
                    </Grid.Column>
                    <Grid.Column className="gridColumnPayment">
                        <Image src={AMEX} />
                        <div className="titleCardPayment">
                            American Express
                        </div>
                    </Grid.Column>
                    <Grid.Column className="gridColumnPayment">
                        <Image src={Cabal} />
                        <div className="titleCardPayment">
                            Cabal débito y crédito
                        </div>
                    </Grid.Column>
                    <Grid.Column className="gridColumnPayment">
                        <Image src={Naranja} />
                        <div className="titleCardPayment">
                            Naranja
                        </div>
                    </Grid.Column>
                </Grid>
            </div>
            <Grid className="gridBtns">
                <Grid.Column floated="left" width={8}>
                    <div className="goBackStep" onClick={() => setStepRegister(2)}>Volver al paso anterior</div>
                </Grid.Column>
                <Grid.Column floated="right" width={8} className="btnPayGrid">
                    <Button content='Pagar' icon='payment' labelPosition='left' color='red' size='big' loading={loading} disabled={loading}
                        onClick={() => paySuscription()}
                    />
                </Grid.Column>
            </Grid>
        </>
    )
}
