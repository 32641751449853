import React, { useState, useEffect } from 'react';

import { Header, Form, Message } from "semantic-ui-react";

import { isMobile } from "react-device-detect";

import axios from "axios";
import Global from "../../../../utils/Global";
import { getLocalStorage } from "../../../../utils/localStorage";

import "./AddDiscount.scss";

export default function AddDiscount(props) {

    const { infoUser } = props;

    const [doneMsg, setDoneMsg] = useState(false);
    const [isOK, setIsOK] = useState("messageUserData__fail");
    const [msg, setMsg] = useState("");

    const [loading, setLoading] = useState(false);

    const [resultsSearchObraSocial, setResultsSearchObraSocial] = useState([]);
    const [searchValueObraSocial, setSearchValueObraSocial] = useState("");
    const [searchingObraSocial, setSearchingObraSocial] = useState(false);
    const [gotValuesObraSocial, setGotValuesObraSocial] = useState(false);
    const [selectedObraSocial, setSelectedObraSocial] = useState(null);

    const [resultsSearch, setResultsSearch] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [searching, setSearching] = useState(false);
    const [gotValues, setGotValues] = useState(false);
    const [selectedRemedio, setSelectedRemedio] = useState(null);

    const [formError, setFormError] = useState({});
    const [formData, setFormData] = useState({
        "plan": "",
        "discount": 0,
        "obs": ""
    });

    const searchRemedio = value => {
        setSearchValue(value);
        setSearching(true);
        setGotValues(false);
        setTimeout(function() {
            axios.post(Global.url + 'search-remedio', { search: value }, { headers: { "authorization" : `Bearer ${getLocalStorage()}`} })
                .then(res => {
                    setResultsSearch(res.data.results);
                }).catch(err => {
                    setResultsSearch([]);
                }).finally(() => {
                    setSearching(false);
                    setGotValues(true);
            });
        }, 300);
    }

    const searchObraSocial = value => {
        setSearchValueObraSocial(value);
        if(value.length > 2) {
            setSearchingObraSocial(true);
            setGotValuesObraSocial(false);
            setTimeout(function() {
                axios.post(Global.url + 'search-obrasocial', { search: value }, { headers: { "authorization" : `Bearer ${getLocalStorage()}`} })
                    .then(res => {
                        setResultsSearchObraSocial(res.data.results);
                    }).catch(err => {
                        setResultsSearchObraSocial([]);
                    }).finally(() => {
                        setSearchingObraSocial(false);
                        setGotValuesObraSocial(true);
                });
            }, 300);
        }
    }

    const selectObraSocial = item => {
        setSearchValueObraSocial("");
        setSelectedObraSocial(item);
        setGotValuesObraSocial(false);
        setResultsSearchObraSocial([]);
    }

    const selectRemedio = item => {
        setSearchValue("");
        setSelectedRemedio(item);
        setGotValues(false);
        setResultsSearch([]);
        setDoneMsg(false);
        setIsOK("messageUserData__fail");
    }

    const saveData = () => {
        if(!selectedRemedio) {
            setFormError({ remedio: "Este campo es obligatorio. "});
        } else if(!selectedObraSocial) {
            setFormError({ obrasocial: "Este campo es obligatorio. "});
        } else {
            setLoading(true);
            //TENEMOS QUE ENVIAR EL ID DE REMEDIO Y OBRA SOCIAL
            axios.post(Global.url + 'add-new-discount', { formData: formData, remedio: selectedRemedio, obrasocial: selectedObraSocial }, { headers: { "authorization" : `Bearer ${getLocalStorage()}`} })
                .then(res => {
                    setMsg(res.data.message);
                    if(res.data.status) {
                        setIsOK("messageUserData__success");
                        setFormData({
                            "plan": "",
                            "discount": "",
                            "obs": ""
                        });
                    } else {
                        setIsOK("messageUserData__fail");
                    }
                }).catch(err => {
                    setIsOK("messageUserData__fail");
                    console.log(err);
                }).finally(() => {
                    setDoneMsg(true);
                    setLoading(false);
                });
        }
    }

    return (
        <>
        <Header as='h3' className="headerUserPanel">
            Agregar nuevo descuento
        </Header>
        <Form>
            <Form.Input
                onChange={e => searchRemedio(e.target.value)}
                fluid label='Remedio' 
                name="remedio"
                value={searchValue}
                error={formError.remedio}
                icon={searching ? "circle notch" : "search"}
                placeholder='Buscar remedio'
            />
            {gotValues &&
                <div className="listValuesFound listValuesFound__Patients">
                    {resultsSearch.length === 0 && 
                        <div className="aValueFound">No se encontró ningún remedio que coincida. </div>
                    }
                    {resultsSearch.map((item) => (
                        <div 
                            onClick={() => selectRemedio(item)} 
                            className="aValueFound"
                            key={item._id}
                        >
                            {item.name} - <strong>Precio</strong>: {item.price}
                        </div>
                    ))}
                </div>
            }
            {selectedRemedio !== null &&
                <div className="infoSelectedPatient">
                    <span className="textSelectedPatient"><strong>Remedio:</strong> <span>{selectedRemedio.name}</span></span>
                    <span className="textSelectedPatient"><strong>Precio:</strong> <span>{selectedRemedio.price}</span></span>
                    <span className="textSelectedPatient"><strong>Observaciones:</strong> <span>{selectedRemedio.obs}</span></span>
                </div>
            }
                <Form.Input
                    onChange={e => searchObraSocial(e.target.value)}
                    fluid label='Obra social que aplique descuento' 
                    name="obrasocial"
                    value={searchValueObraSocial}
                    error={formError.obrasocial}
                    icon={searchingObraSocial ? "circle notch" : "search"}
                    placeholder='Buscar obra social'
                />
                {gotValuesObraSocial &&
                    <div className="listValuesFound listValuesFound__Cie10">
                        {resultsSearchObraSocial.length === 0 && 
                            <div className="aValueFound">No se encontró ninguna obra social que coincida. </div>
                        }
                        {resultsSearchObraSocial.map((item) => (
                            <div 
                                onClick={() => selectObraSocial(item)} 
                                className="aValueFound"
                                key={item._id}
                            >
                                {item.name}
                            </div>
                        ))}
                    </div>
                }
                {selectedObraSocial !== null &&
                    <div className="infoSelectedCie10">
                        <span className="textSelectedCie10"><strong>Obra social:</strong> <span>{selectedObraSocial.name}</span></span>
                    </div>
                } 
            <Form.Group widths='equal'>
                <Form.Input 
                    fluid label='Descuento en %' 
                    name="discount"
                    required
                    type="number"
                    value={formData.discount}
                    error={formError.discount}
                    placeholder='Descuento en %'
                    onChange={e => setFormData({...formData, [e.target.name]: e.target.value}) }
                />
            </Form.Group>
            <Form.Group widths='equal'>
                <Form.TextArea
                    label='Observaciones del descuento'
                    name="obs"
                    value={formData.obs}
                    placeholder='Observaciones del descuento'
                    onChange={e => setFormData({...formData, [e.target.name]: e.target.value}) }
                />
            </Form.Group>
            {doneMsg && 
                <div className="messageDiv">
                    <Message className={isOK === "messageUserData__fail" ? "messageUserData messageUserData__fail" : "messageUserData messageUserData__success"}>
                        {msg}
                    </Message>
                </div>
            }
            <Form.Button loading={loading} disabled={loading} onClick={() => saveData()} className="btnSubmit btnChangePersonalDate">Agregar nuevo descuento</Form.Button>
        </Form>
        </>
    )
}
