import React, { useEffect } from 'react';

import { setLocalStorage } from "../../utils/localStorage";

import { Redirect } from "react-router-dom";

function Logout(props) {

    const { setIsLogged } = props;

    useEffect(() => {
        setLocalStorage(null);
        setIsLogged(false);
    }, []);

    return <Redirect to="/" />;
}

export default Logout;
