import React, { useState, useEffect } from 'react';

import "./Contacto.scss";

import { Grid, Segment, Header, Form, Input, TextArea, Button } from "semantic-ui-react";

import Global from "../../utils/Global";
import axios from "axios";
import { validateEmail } from "../../utils/Validations";

export default function Contacto() {

    useEffect(() => {
        window.scroll({top: 0, left: 0, behavior: 'smooth' });
    }, []);

    const [loading, setLoading] = useState(false);
    const [contentMsg, setContentMsg] = useState("Hubo un error interno en el servidor. Por favor intente más tarde. ");
    const [okMsg, setOkMsg] = useState("statusForm statusForm__fail");
    const [doneSent, setDoneSent] = useState(false);
    const [formError, setFormError] = useState({});
    const [formData, setFormData] = useState({
        "fullname": "",
        "email": "",
        "phone": "",
        "message": ""
    });

    const formSubmit = () => {
        if(!formData.fullname) {
            setFormError({ fullname: "Este campo es obligatorio. "});
        } else if(!validateEmail(formData.email)) {
            setFormError({ email: "El email no es correcto. "});
        } else if(!formData.email) {
            setFormError({ email: "Este campo es obligatorio. "});
        } else if(!formData.message) {
            setFormError({ message: "El mensaje no puede estar vacio. "});
        } else {
            setLoading(true);
            axios.post(Global.url + 'form-contact', { formData: formData })
                .then(res => {
                    setContentMsg(res.data.message);
                    if(res.data.status) {
                        setOkMsg("statusForm statusForm__success");
                    } else {
                        setOkMsg("statusForm statusForm__fail");
                    }
                }).catch(err => {
                    setContentMsg("Hubo un error interno en el servidor. Por favor intente más tarde. ");
                    setOkMsg("statusForm statusForm__fail");
                }).finally(() => {
                    setDoneSent(true);
                    setLoading(false);
                });
        }
    }

    return (
        <div className="divFormContact">
            <Segment>
                Si tenés comentarios, sugerencias, dudas o simplemente querés contactarnos, podés usar el siguiente formulario o escribirnos un email a contacto@rpdigitales.com.ar
            </Segment>
            <Grid columns="equal">
                <Grid.Column>
                    <Header as="h2" className="formContactTitle">
                        Formulario de contacto
                    </Header>
                    <Form className="formStyleContact">
                        <Form.Field
                            required
                            fluid
                            id='inputName'
                            control={Input}
                            label="Nombre y apellido"
                            placeholder="Nombre y apellido"
                            className="inputStyle"
                            name="fullname"
                            error={formError.fullname}
                            onChange={e => setFormData({ ...formData, [e.target.name]: e.target.value })}
                        />
                        <Form.Field
                            required
                            fluid
                            id='inputEmail'
                            control={Input}
                            label="Email"
                            placeholder="Email"
                            className="inputStyle"
                            name="email"
                            error={formError.email}
                            onChange={e => setFormData({ ...formData, [e.target.name]: e.target.value })}
                        />
                        <Form.Field
                            fluid
                            id='inputPhone'
                            control={Input}
                            label="Teléfono"
                            placeholder="Teléfono"
                            className="inputStyle"
                            name="phone"
                            error={formError.phone}
                            onChange={e => setFormData({ ...formData, [e.target.name]: e.target.value })}
                        />
                        <Form.Field
                            required
                            fluid
                            id='inputMessage'
                            control={TextArea}
                            label="Mensaje"
                            placeholder="Mensaje"
                            className="inputStyle"
                            name="message"
                            error={formError.message}
                            onChange={e => setFormData({ ...formData, [e.target.name]: e.target.value })}
                        />
                    </Form>
                    <Button loading={loading} disabled={loading} onClick={() => formSubmit()} className="formButtonContact">Enviar mensaje</Button>
                    {doneSent && 
                        <div className={okMsg}>
                            {contentMsg}
                        </div>
                    }
                </Grid.Column>
                {/* <Grid.Column>
                    
                </Grid.Column> */}
            </Grid>
        </div>
    )
}
