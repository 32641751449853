import React, { useState, useEffect } from 'react';
import "./Register.scss";
import { Icon, Step } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import LoadingPage from "../../components/LoadingPage";
//steps
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import { isMobile } from "react-device-detect";

function Register(props) {

    const { isLogged, isLoaded } = props;

    useEffect(() => {
        if(isLogged) {
            props.history.push("/dashboard");
        }
    }, [isLogged]);

    const [stepRegister, setStepRegister] = useState(1);

    useEffect(() => {
        switch(stepRegister) {
            case 1:
                setTitleRegister("Registro de Usuario - Ingrese los datos necesarios");
                break;
            case 2:
                setTitleRegister("Seleccione una suscripción");
                break;
            case 3:
                setTitleRegister("Aceptamos los siguientes métodos de pago");
                break;
            case 4:
                setTitleRegister("¡Felicidades! Se creó exitosamente el usuario. ");
                break;
            default:
                setTitleRegister("Registro de Usuario - Ingrese los datos necesarios");
                break;
        }
    }, [stepRegister]);

    const [selectedSuscription, setSelectedSuscription] = useState("month");
    const [savedUser, setSavedUser] = useState({});
    const [titleRegister, setTitleRegister] = useState("Registro de Usuario - Ingrese los datos necesarios");

    if(!isLogged && isLoaded) {
        return (
            <>
            <div className="thanksRegister">
                ¡Gracias por elegirnos! Por favor, siga los pasos necesarios para registrarse y completar la suscripción.
            </div>
            <div className="stepsMenu">
                <Step.Group widths={4}>
                    <Step completed={stepRegister > 1 ? true : false} active={stepRegister === 1 ? true : false}>
                    <Icon name='user doctor'  />
                    <Step.Content>
                        <Step.Title>Registro</Step.Title>
                        <Step.Description>Completar los datos de cuenta</Step.Description>
                    </Step.Content>
                    </Step>

                    <Step completed={stepRegister > 2 ? true : false} active={stepRegister === 2 ? true : false}>
                    <Icon name='calendar alternate outline' />
                    <Step.Content>
                        <Step.Title>Suscripción</Step.Title>
                        <Step.Description>Seleccionar tipo de suscripción</Step.Description>
                    </Step.Content>
                    </Step>

                    <Step completed={stepRegister > 3 ? true : false} active={stepRegister === 3 ? true : false}>
                    <Icon name='payment' />
                    <Step.Content>
                        <Step.Title>Pago</Step.Title>
                        <Step.Description>Seleccionar método de pago</Step.Description>
                    </Step.Content>
                    </Step>

                    <Step completed={stepRegister > 4 ? true : false} active={stepRegister === 4 ? true : false}>
                    <Icon name='check circle' />
                    <Step.Content>
                        <Step.Title>¡Listo!</Step.Title>
                        <Step.Description>Envío de activación de cuenta.</Step.Description>
                    </Step.Content>
                    </Step>
                </Step.Group>
            </div>
            <div id="registerDiv" className={isMobile ? "registerDivMobile" : "registerDiv"}>
                <div className="titleRegister">{titleRegister}</div>
                { stepRegister === 1 && 
                    <Step1 setStepRegister={setStepRegister} setSavedUser={setSavedUser} />
                }
                { stepRegister === 2 && 
                    <Step2 setStepRegister={setStepRegister} setSelectedSuscription={setSelectedSuscription} savedUser={savedUser} />
                }
                { stepRegister === 3 && 
                    <Step3 setStepRegister={setStepRegister} selectedSuscription={selectedSuscription} savedUser={savedUser} />
                }
            </div>
            </>
        )
    } else {
        return <LoadingPage />;
    }
}


export default withRouter(Register);
