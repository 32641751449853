import React, { useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
//components
import Header from "../Header";
import Footer from "../Footer";
//pages
import Test from "../../pages/Test/Test";
import Home from "../../pages/Home";
import Login from "../../pages/Login";
import Register from "../../pages/Register";
import Done from "../../pages/Done";
import Logout from "../../pages/Logout";
import Dashboard from "../../pages/Dashboard";
import Admin from "../../pages/Admin";
import Farmacias from "../../pages/Farmacias";
import ConfirmData from "../../pages/ConfirmData";
import RecoverPassword from '../../pages/RecoverPassword';
import Contacto from '../../pages/Contacto';
import Tos from '../../pages/Tos';
import HeaderMenuMobile from "../../components/HeaderMenuMobile";
import UserLogged from "../../utils/UserLogged";
import { isMobile } from "react-device-detect";
import { Sidebar, Menu } from "semantic-ui-react";
import CallMenuHeaderMobile from "../../components/CallMenuHeaderMobile";


export default function Router() {

    const { setIsLogged, isLogged, isLoaded } = UserLogged();

    const [sidebarVisible, setSidebarVisible] = useState(false);

    const manipulateSidebar = () => {
        if(sidebarVisible) {
            setSidebarVisible(false);
        }
    }

    return (
        <BrowserRouter>
            { !isMobile && 
                <Header isLogged={isLogged} isLoaded={isLoaded} />
            }
            <Sidebar.Pushable>
                <Sidebar
                    as={Menu}
                    animation='overlay'
                    icon='labeled'
                    inverted
                    vertical
                    visible={sidebarVisible}
                    width='wide'
                    className="sidebarDiv"
                >
                {isMobile &&
                    <HeaderMenuMobile setSidebarVisible={setSidebarVisible} isLogged={isLogged} isLoaded={isLoaded} />
                }
            </Sidebar>
                <Sidebar.Pusher>
                    <section style={{ marginTop: 60, marginBottom: 60 }} onClick={() => manipulateSidebar()}>
                        {isMobile &&
                            <CallMenuHeaderMobile setSidebarVisible={setSidebarVisible} />
                        }
                        <Switch>
                            {/* SOLO ESTARÁ UNA VEZ Y LISTO */}
                            <Route exact path="/install" component={Test} />

                            <Route exact path="/" component={Home} />
                            <Route exact path="/login" render={(props) => <Login {...props} isLoaded={isLoaded} isLogged={isLogged} setIsLogged={setIsLogged} />} />
                            <Route exact path="/register" render={(props) => <Register {...props} isLoaded={isLoaded} isLogged={isLogged} />} />
                            <Route exact path="/done" render={(props) => <Done {...props} isLoaded={isLoaded} isLogged={isLogged} />} />
                            <Route exact path="/logout" render={(props) => <Logout {...props} setIsLogged={setIsLogged} />} />
                            <Route exact path="/dashboard" render={(props) => <Dashboard {...props} isLogged={isLogged} isLoaded={isLoaded} />} />
                            <Route exact path="/dashboard/:path" render={(props) => <Dashboard {...props} isLogged={isLogged} isLoaded={isLoaded} />} />
                            <Route exact path="/dashboard/:path/:subpath" render={(props) => <Dashboard {...props} isLogged={isLogged} isLoaded={isLoaded} />} />
                            <Route exact path="/admin" render={(props) => <Admin {...props} isLogged={isLogged} isLoaded={isLoaded} />} />
                            <Route exact path="/admin/:path" render={(props) => <Admin {...props} isLogged={isLogged} isLoaded={isLoaded} />} />
                            <Route exact path="/admin/:path/:subpath" render={(props) => <Admin {...props} isLogged={isLogged} isLoaded={isLoaded} />} />
                            <Route exact path="/farmacia" render={(props) => <Farmacias {...props} isLogged={isLogged} isLoaded={isLoaded} />} />
                            <Route exact path="/farmacias" render={(props) => <Farmacias {...props} isLogged={isLogged} isLoaded={isLoaded} />} />

                            <Route exact path="/send/:type/:token" render={(props) => <ConfirmData {...props} />} />
                            <Route exact path="/recover-password/:token" render={(props) => <RecoverPassword {...props} isLogged={isLogged} isLoaded={isLoaded} />} />

                            <Route exact path="/contacto" render={(props) => <Contacto {...props} />} />
                            <Route exact path="/tos" render={(props) => <Tos {...props} />} />
                        </Switch>
                    </section>
                    <Footer />
                </Sidebar.Pusher>
            </Sidebar.Pushable>
            
        </BrowserRouter>
    )
}
