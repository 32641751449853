import React, { useState, useEffect } from 'react';
import "./Admin.scss";
import axios from "axios";
import Global from "../../utils/Global";
import { useParams } from "react-router-dom";
import { Container, Grid, Menu } from "semantic-ui-react";
import { isMobile } from "react-device-detect";
import { getLocalStorage } from '../../utils/localStorage';

//components dashboard
import Home from "../../components/Admin/Home";
import Users from "../../components/Admin/Users";
import Farmacias from "../../components/Admin/Farmacias";
import Remedios from "../../components/Admin/Remedios";
import ObrasSociales from "../../components/Admin/ObrasSociales";
import Discounts from "../../components/Admin/Discounts";
import Planillas from "../../components/Admin/Planillas";
import LoadingPage from "../../components/LoadingPage";

export default function Admin(props) {

    const { isLogged, isLoaded } = props;

    let { path } = useParams();

    const [infoUser, setInfoUser] = useState(null);
    const [adminLoaded, setAdminLoaded] = useState(false);
    const [activeItem, setActiveItem] = useState(path !== undefined ? path : "home");

    useEffect(() => {
        if(!isLogged && isLoaded) {
            props.history.push("/login");
        }
    }, [isLogged, isLoaded]);


    useEffect(() => {
        if(isLogged) {
            axios.post(Global.url + 'get-data-admin', null, { headers: { "authorization" : `Bearer ${getLocalStorage()}`} })
            .then(res => {
                if(res.data.status && res.data.authorized) {
                    setInfoUser(res.data.result);
                    setAdminLoaded(true);
                } else {
                    props.history.push("/dashboard");
                }
            }).catch(err => {
                props.history.push("/");
            });
        }
    }, [isLogged]);


    if(isLogged && isLoaded && adminLoaded) {
        return (
            <Container className="bgDashboard bgDashboard__Admin">
                <Menu secondary pointing className={isMobile ? "menuDashboard menuDashboardMobile" : "menuDashboard menuDashboard__Admin"} style={{ height: isMobile ? "100%" : "50px !important" }}>
                    <Menu.Item
                        name='Inicio de administración'
                        content="Inicio de administración"
                        active={activeItem === "home"}
                        onClick={() => setActiveItem("home")}
                        className={isMobile ? "itemMobile" : "itemNoMobile"}
                    />
                    <Menu.Item
                        name='Médicos'
                        content="Médicos"
                        active={activeItem === "users"}
                        onClick={() => setActiveItem("users")}
                        className={isMobile ? "itemMobile" : "itemNoMobile"}
                    />
                    <Menu.Item
                        name='Farmacias'
                        content="Farmacias"
                        active={activeItem === "pharmacy"}
                        onClick={() => setActiveItem("pharmacy")}
                        className={isMobile ? "itemMobile" : "itemNoMobile"}
                    />
                    <Menu.Item
                        name='Planillas'
                        content="Planillas"
                        active={activeItem === "documents"}
                        onClick={() => setActiveItem("documents")}
                        className={isMobile ? "itemMobile" : "itemNoMobile"}
                    />
                    <Menu.Item
                        name='Obras sociales'
                        content="Obras sociales"
                        active={activeItem === "insurances"}
                        onClick={() => setActiveItem("insurances")}
                        className={isMobile ? "itemMobile" : "itemNoMobile"}
                    />
                    <Menu.Item
                        name='Medicamentos'
                        active={activeItem === "medicaments"}
                        onClick={() => setActiveItem("medicaments")}
                        className={isMobile ? "itemMobile" : "itemNoMobile"}
                    />
                    <Menu.Item
                        name='Descuentos'
                        active={activeItem === "discounts"}
                        onClick={() => setActiveItem("discounts")}
                        className={isMobile ? "itemMobile" : "itemNoMobile"}
                    />
                    { !isMobile &&
                        <Menu.Menu position="right">
                            <Menu.Item
                                name='Salir'
                                active={activeItem === "logout"}
                                onClick={() => setActiveItem("logout")}
                                className={isMobile ? "itemMobile" : "itemNoMobile"}
                            />
                        </Menu.Menu>
                    }
                </Menu>
                <Grid className="gridDashboard">
                    <Grid.Column width={16} className="dashboardContent">
                        {(() => {
                            if (activeItem === "home") {
                                return <Home infoUser={infoUser} />
                            } else if (activeItem === "users") {
                                return <Users infoUser={infoUser} />
                            } else if(activeItem === "pharmacy") {
                                return <Farmacias infoUser={infoUser} />
                            } else if(activeItem === "medicaments") {
                                return <Remedios infoUser={infoUser} />
                            } else if(activeItem === "insurances") {
                                return <ObrasSociales infoUser={infoUser} />
                            } else if(activeItem === "discounts") {
                                return <Discounts infoUser={infoUser} />
                            } else if(activeItem === "documents") {
                                return <Planillas infoUser={infoUser} />
                            } else {
                                return <Home infoUser={infoUser} />
                            }
                        })()}
                    </Grid.Column>
                </Grid>
            </Container>
        )
    } else {
        return <LoadingPage />;
    }
    
}
