import React, { useState, useEffect } from 'react';
import "./Step2.scss";
import { Button, Card, List, Message } from "semantic-ui-react";
import { limitForFreeUsers } from "../../../utils/ValuesOptions";
import { withRouter } from 'react-router-dom';
import axios from "axios";
import Global from "../../../utils/Global";

function Step2(props) {

    const { setStepRegister, setSelectedSuscription, savedUser } = props;

    const [loading, setLoading] = useState(false);
    const [contentMessage, setContentMessage] = useState("");
    const [status, setStatus] = useState(false);

    const goToNextStep = value => {

        if(value === 'free') {
            setLoading(true);
            axios.post(Global.url + "free-sign-up", { savedUser })
                .then(res => {
                    if(res.data.status) {
                        props.history.push("/done");
                    } else {
                        setContentMessage(res.data.message);
                    }
                }).catch(err => {
                    setContentMessage(err.data);
                }).finally(() => {
                    setStatus(true);
                    setLoading(false);
            });
        }

        if(value === "month" || value === "year") {
            setSelectedSuscription(value);
            setStepRegister(3);
        }

    }

    useEffect(() => {
        if(savedUser === "") {
            setStepRegister(1);
        }
    }, []);

    return (
        <>
        <div className="selectOptionTimeSuscription">
            {status &&
                <div className="errorDivRegister">
                    <Message error className="errorMessageRegister">
                        <Message.Header>Error</Message.Header>
                        <Message.Content>{contentMessage}</Message.Content>
                    </Message>
                </div>
            }
            <div className="centerSuscriptions">
                <Card className="cardTypeSuscription cardTypeSuscription__free">
                    <Card.Content className="headerCard headerCard__free" header='Suscripción gratuita' />
                    <Card.Content className="contentCardRegister">
                        <List bulleted divided>
                            <List.Item className="listElement">Max. {limitForFreeUsers[0]} pacientes. </List.Item>
                            <List.Item className="listElement">Max. {limitForFreeUsers[1]} recetas por mes. </List.Item>
                            {/* <List.Item className="listElement">Max. {limitForFreeUsers[2]} planillas por mes. </List.Item> */}
                            <List.Item className="listElement">Con publicidad. </List.Item>
                            {/* <List.Item className="listElement">Sin posibilidad de envío por WhatsApp. </List.Item> */}
                        </List>
                        <div className="price price__free">
                            Gratis
                        </div>
                        <div className="btnSelectOption">
                            <Button
                                className="styleBtnSuscription"
                                onClick={() => goToNextStep("free")}
                                loading={loading}
                                disabled={loading}
                            >
                                Registrarse ahora
                            </Button>
                        </div>
                    </Card.Content>
                    <Card.Content extra className="smallFont extraSmall">
                        Se puede actualizar el plan de suscripción si luego cambiás de opinión.
                    </Card.Content>
                </Card>
                <Card className="cardTypeSuscription cardTypeSuscription__month">
                    <Card.Content className="headerCard headerCard__month" header='Suscripción mensual' />
                    <Card.Content className="contentCardRegister">
                        <List bulleted divided>
                            <List.Item className="listElement">Pacientes y recetas ilimitadas. </List.Item>
                            {/* <List.Item className="listElement">Acceso a nuestras planillas. </List.Item> */}
                            <List.Item className="listElement">Primeros 7 días gratuito. </List.Item>
                            <List.Item className="listElement">Soporte las 24/7. </List.Item>
                            {/* <List.Item className="listElement">Con posibilidad de enviar por WhatsApp. </List.Item> */}
                        </List>
                        <div className="price price__month">
                            ARS 319 <span className="smallFont">/mes</span>
                        </div>
                        <div className="btnSelectOption">
                            <Button
                                className="styleBtnSuscription"
                                onClick={() => goToNextStep("month")}
                                disabled={loading}
                            >
                                Elegir esta suscripción
                            </Button>
                        </div>
                    </Card.Content>
                    <Card.Content extra className="smallFont extraSmall">
                        Se puede desactivar la renovación automática en Config. de pagos en Mi Cuenta.
                    </Card.Content>
                </Card>
                <Card className="cardTypeSuscription cardTypeSuscription__year">
                    <Card.Content className="headerCard headerCard__year" header='Suscripción anual' />
                    <Card.Content className="contentCardRegister">
                        <List bulleted divided>
                            <List.Item className="listElement">Pacientes y recetas ilimitadas.</List.Item>
                            {/* <List.Item className="listElement">Acceso a nuestras planillas. </List.Item> */}
                            <List.Item className="listElement">Primeros 7 días gratuito. </List.Item>
                            <List.Item className="listElement">Soporte las 24/7. </List.Item>
                            {/* <List.Item className="listElement">Con posibilidad de enviar por WhatsApp. </List.Item> */}
                        </List>
                        <div className="price price__year">
                            ARS 2.499 <span className="smallFont">/año</span>
                        </div>
                        <div className="btnSelectOption">
                            <Button
                                className="styleBtnSuscription"
                                onClick={() => goToNextStep("year")}
                                disabled={loading}
                            >
                                Elegir esta suscripción
                            </Button>
                        </div>
                    </Card.Content>
                    <Card.Content extra className="smallFont extraSmall">
                        Se puede desactivar la renovación automática en Config. de pagos en Mi Cuenta.
                    </Card.Content>
                </Card>
            </div>
        </div>
        <div className="goBackStep" onClick={() => setStepRegister(1)}>Volver al paso anterior</div>
        </>
    )
}

export default withRouter(Step2);