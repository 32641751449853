import React, { useState, useEffect } from 'react';

import { Header, Form, Message } from "semantic-ui-react";

import { isMobile } from "react-device-detect";

import axios from "axios";
import Global from "../../../../utils/Global";
import { getLocalStorage } from "../../../../utils/localStorage";

import "./AddRemedio.scss";

export default function AddRemedio(props) {

    const { infoUser } = props;

    const [doneMsg, setDoneMsg] = useState(false);
    const [isOK, setIsOK] = useState("messageUserData__fail");
    const [msg, setMsg] = useState("");

    const [loading, setLoading] = useState(false);

    const [optionsBusiness, setOptionsBusiness] = useState([]);
    const [currentValues, setCurrentValues] = useState([]);

    const [formError, setFormError] = useState({});
    const [formData, setFormData] = useState({
        "name": "",
        "price": "",
        "business": [],
        "obs": ""
    });

    const setSelectedDropdown = (e, { value }) => {
        setCurrentValues(value);
    }

    const renderLabel = (label) => ({
        color: 'blue',
        content: `${label.text}`,
        icon: 'check',
    });
    
    const addItemDropdown = (e, { value }) => {
        let auxOptions = optionsBusiness;
        auxOptions.push({
            "key": value,
            "text": value,
            "value": value
        });
        setOptionsBusiness(auxOptions);
    }

    const saveData = () => {
        if(!formData.name) {
            setFormError({ name: "Este campo es obligatorio. "});
        } else if(!formData.price) {
            setFormError({ price: "Este campo es obligatorio, si no sabe déjelo en valor 0. "});
        } else {
            formData.business = currentValues;
            setLoading(true);
            axios.post(Global.url + 'add-new-remedio', formData, { headers: { "authorization" : `Bearer ${getLocalStorage()}`} })
                .then(res => {
                    setMsg(res.data.message);
                    if(res.data.status) {
                        setIsOK("messageUserData__success");
                        setFormData({
                            "name": "",
                            "price": "",
                            "business": [],
                            "discounts": [],
                            "obs": ""
                        });
                    } else {
                        setIsOK("messageUserData__fail");
                    }
                }).catch(err => {
                    setIsOK("messageUserData__fail");
                    console.log(err);
                }).finally(() => {
                    setDoneMsg(true);
                    setLoading(false);
                });
        }
    }

    return (
        <>
        <Header as='h3' className="headerUserPanel">
            Agregar nuevo remedio
        </Header>
        <Form>
            <Form.Group widths='equal'>
                <Form.Input 
                    label='Nombre' 
                    name="name"
                    required
                    value={formData.name}
                    error={formError.name}
                    placeholder='Nombre'
                    onChange={e => setFormData({...formData, [e.target.name]: e.target.value}) }
                />
                <Form.Input 
                    label='Precio'
                    name="price"
                    type="number"
                    required
                    error={formError.price}
                    value={formData.price}
                    placeholder='Precio'
                    onChange={e => setFormData({...formData, [e.target.name]: e.target.value}) }
                />
            </Form.Group>
            <Form.Group widths='equal'>
                <Form.Dropdown
                    options={optionsBusiness}
                    label='Empresas del remedio'
                    name="business"
                    search
                    multiple
                    selection
                    allowAdditions
                    value={currentValues}
                    placeholder='Empresas del remedio'
                    onAddItem={addItemDropdown}
                    renderLabel={renderLabel}
                    onChange={setSelectedDropdown}
                />
            </Form.Group>
            <Form.Group widths='equal'>
                <Form.TextArea
                    label='Observaciones'
                    name="obs"
                    value={formData.obs}
                    placeholder='Observaciones'
                    onChange={e => setFormData({...formData, [e.target.name]: e.target.value}) }
                />
            </Form.Group>
            {doneMsg && 
                <div className="messageDiv">
                    <Message className={isOK === "messageUserData__fail" ? "messageUserData messageUserData__fail" : "messageUserData messageUserData__success"}>
                        {msg}
                    </Message>
                </div>
            }
            <Form.Button loading={loading} disabled={loading} onClick={() => saveData()} className="btnSubmit btnChangePersonalDate">Agregar nuevo remedio</Form.Button>
        </Form>
        </>
    )
}
