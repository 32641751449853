import React, { useState, useEffect } from 'react';

import { Header, Table, Menu, Icon } from "semantic-ui-react";

import moment from "moment";

import axios from "axios";
import Global from "../../../../utils/Global";
import { getLocalStorage } from '../../../../utils/localStorage';

export default function VerRecetas(props) {

    const { infoUser, selectedUser } = props;

    const [allRecets, setAllRecets] = useState([]);
    const [loading, setLoading] = useState(false);

    const [offset, setOffset] = useState(0);
    const [countPages, setCountPages] = useState(0);
    const [actualPage, setActualPage] = useState(1);
    const [startLimit, setStartLimit] = useState(1);

    useEffect(() => { // CON PAGINACION Y OFFSET (TO-DO FALTA)
        setLoading(true);
        axios.post(Global.url + 'count-all-recets-doctor', { selectedUser: selectedUser }, { headers: { "authorization" : `Bearer ${getLocalStorage()}`} })
          .then(res => {
            setCountPages(res.data.result);
          }).catch(err => {
            setCountPages(0);
          });
      }, [selectedUser]);
    
    useEffect(() => { // CON PAGINACION Y OFFSET (TO-DO FALTA)
        setLoading(true);
        axios.post(Global.url + 'load-all-recets-doctor', { offset: offset, selectedUser: selectedUser }, { headers: { "authorization" : `Bearer ${getLocalStorage()}`} })
        .then(res => {
            setAllRecets(res.data.result);
        }).catch(err => {
            setAllRecets([]);
        }).finally(() => {
            setLoading(false);
        })
    }, [offset, selectedUser]);
    
      const movePage = direction => {
        if(direction) {
          if(actualPage !== countPages) {
            setOffset(offset + 10);
            setActualPage(actualPage + 1);
            setStartLimit(startLimit + 1);
          }
        } else {
          if(actualPage > 1) {
            setOffset(offset - 10);
            setActualPage(actualPage - 1);
            setStartLimit(startLimit - 1);
          }
        }
      }
    
      const goToPage = (result, page) => {
        setOffset(result);
        setActualPage(page);
      }
    
    return (
        <>
        <Header as='h3' className="headerUserPanel">
            Listado de recetas
        </Header>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan='10'>Últimas recetas añadidas</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Estado</Table.HeaderCell>
              <Table.HeaderCell collapsing>Fecha</Table.HeaderCell>
              <Table.HeaderCell>Nombre y apellido</Table.HeaderCell>
              <Table.HeaderCell>DNI</Table.HeaderCell>
              <Table.HeaderCell>Obra social</Table.HeaderCell>
              <Table.HeaderCell>Número</Table.HeaderCell>
              <Table.HeaderCell>CIE10</Table.HeaderCell>
              <Table.HeaderCell>Medicamento 1</Table.HeaderCell>
              <Table.HeaderCell>Medicamento 2</Table.HeaderCell>
              <Table.HeaderCell>Fecha Vencimiento</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {allRecets.length ?
              allRecets.map((recet) => (
                <RowTable key={recet._id} recet={recet} />
              )) :
              <Table.Row>
                <Table.Cell colSpan="10"><div className="patientsNotFoundTable">No tenés ninguna receta cargada. </div></Table.Cell>
              </Table.Row>
            }
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan='10'>
                <Menu floated='right' pagination>
                  <Menu.Item as='a' icon onClick={() => movePage(false)}>
                    <Icon name='chevron left' />
                  </Menu.Item>
                  {Array.from(Array(2), (e, i) => (
                    ((startLimit+i) !== 0 && (startLimit+i) <= countPages) &&
                    (<Menu.Item as='a' key={startLimit+i} onClick={() => goToPage(((startLimit+i)*10-10), startLimit+i)} className={actualPage === (startLimit+i) ? "activeItemMenu" : "ItemMenu" }>{startLimit+i}</Menu.Item>)
                  ))}
                  <Menu.Item as='a' icon onClick={() => movePage(true)}>
                    <Icon name='chevron right' />
                  </Menu.Item>
                </Menu>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
      </Table>
      </>
    )
}


function RowTable(props) {

    const { recet } = props;
  
    let colorDefault = "tableWaitingRecet";
    let stateRecet = "En revisión";
    if(recet.approved === "APPROVED") {
      colorDefault = "tableApprovedRecet";
      stateRecet = "Aprobada";
    } else if(recet.approved === "CANCELED") {
      colorDefault = "tableCanceledRecet";
      stateRecet = "Anulada";
    } else if(moment().isAfter(moment(recet.startDate).add(30, "days"))) {
      colorDefault = "tableExpiredRecet";
      stateRecet = "Expirada";
    }
    
    return(
      <Table.Row className={colorDefault}>
        <Table.Cell>{stateRecet}</Table.Cell>
        <Table.Cell>{moment(recet.startDate).format("DD/MM/YYYY")}</Table.Cell>
        <Table.Cell>{recet.patient.name} {recet.patient.secondName} {recet.patient.lastname} {recet.patient.secondLastname}</Table.Cell>
        <Table.Cell>{recet.patient.typeDNI} {recet.patient.dni}</Table.Cell>
        <Table.Cell>{recet.patient.obrasocial}</Table.Cell>
        <Table.Cell>{recet.patient.nro}</Table.Cell>
        <Table.Cell>{recet.diagnostic}</Table.Cell>
        <Table.Cell>{recet.remedio1}</Table.Cell>
        <Table.Cell>{recet.remedio2}</Table.Cell>
        <Table.Cell>{recet.approved !== "CANCELED" ? moment(recet.startDate).add(30, "days").format("DD/MM/YYYY") : "-" }</Table.Cell>
      </Table.Row>
    )
  }