import React, { useState } from 'react';
import { Form, Header, Message, Select } from "semantic-ui-react";
import PhoneInput from 'react-phone-input-2';
import es from 'react-phone-input-2/lang/es.json';
import moment from "moment";
import axios from "axios";
import Global from "../../../../utils/Global";
import { getLocalStorage } from '../../../../utils/localStorage';
import { saniziteNumbers } from '../../../../utils/saniziteValues';
import { validateEmail , validateNumber } from '../../../../utils/Validations';
import { dniOptions } from '../../../../utils/ValuesOptions';
import "./Update.scss";

export default function Update() {

    const [doneMsg, setDoneMsg] = useState(false);
    const [isOK, setIsOK] = useState("messageUserData__fail");
    const [msg, setMsg] = useState("");

    const [loading, setLoading] = useState(false);

    const [resultsSearch, setResultsSearch] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [searching, setSearching] = useState(false);
    const [gotValues, setGotValues] = useState(false);
    const [selectedPatient, setSelectedPatient] = useState(null);

    const [formError, setFormError] = useState({});
    const [formData, setFormData] = useState({
        "name": "",
        "secondName": "",
        "lastname": "",
        "secondLastname": "",
        "dateBirth": "",
        "sex": "",
        "typeDNI": "",
        "dni": "",
        "obrasocial": "",
        "nro": "",
        "cellphone": "",
        "email": "",
        "qrPhotoPami": "",
        "codePamiQR": ""
    });

    const sexOptions = [
        { key: "M", value: 'M', text: 'Masculino' },
        { key: "F", value: 'F', text: 'Femenino' }
    ];

    const searchPatient = value => {
        setSearchValue(value);
        setSearching(true);
        setGotValues(false);
        if(value.length >= 2) {
            setTimeout(function() {
                axios.post(Global.url + 'search-patient', { search: value }, { headers: { "authorization" : `Bearer ${getLocalStorage()}`} })
                    .then(res => {
                        setResultsSearch(res.data.results);
                    }).catch(err => {
                        setResultsSearch([]);
                    }).finally(() => {
                        setSearching(false);
                        setGotValues(true);
                });
            }, 300);
        } else {
            setSearching(false);
            setGotValues(false);
        }
    }

    const selectPatient = item => {
        setSearchValue("");
        setSelectedPatient(item);
        setGotValues(false);
        setResultsSearch([]);
        let selectedId = undefined;
        dniOptions.some(function(el) {
            if (el.text === item.typeDNI) {
                selectedId = el.value;
            }
        });
        let dateBirth = "";
        if(moment(item.dateBirth).isValid()) {
            dateBirth = moment(item.dateBirth, "DD/MM/YYYY").format("YYYY-MM-DD");
        }
        setFormData({
            "_id": item._id,
            "name": item.name,
            "secondName": item.secondName,
            "lastname": item.lastname,
            "secondLastname": item.secondLastname,
            "dateBirth": dateBirth,
            "sex": item.sex,
            "typeDNI": selectedId,
            "dni": item.dni,
            "obrasocial": item.obrasocial,
            "nro": item.nro,
            "cellphone": item.cellphone,
            "email": item.email,
            "qrPhotoPami": item.qrPhotoPami,
            "codePamiQR": item.codePamiQR
        });
    }

    const saveData = () => {
        if(selectedPatient === null) {
            setFormError({ patient: "Debes seleccionar un paciente. "});
        } else if(!formData.name) {
            setFormError({ name: "Este campo es obligatorio. "});
        } else if(!formData.lastname) {
            setFormError({ lastname: "Este campo es obligatorio. "});
        } else if(moment(formData.dateBirth).isValid() && moment(formData.dateBirth).isSameOrAfter(moment().format("YYYY-MM-DD"))) {
            setFormError({ dateBirth: "La fecha de nacimiento no puede ser posterior a "+moment().format("YYYY-MM-DD")});
        } else if(!formData.sex || (formData.sex !== "M" && formData.sex !== "F")) {
            setFormError({ sex: "El sexo introducido no es correcto. "});
        } else if(!formData.dni && !validateNumber(formData.dni)) {
            setFormError({ dni: "El DNI introducido no es correcto. Solo se debe introducir números. "});
        } else if(formData.email && !validateEmail(formData.email)) {
            setFormError({ emailJob: "El email no es correcto. Si no desea introducir nada, déjelo en blanco. "});
        } else if(formData.cellphone && !validateNumber(formData.cellphone)) {
            setFormError({ cellphone: "El teléfono de celular no es válido. Si no desea introducir nada, déjelo en blanco. "});
        } else {
            setLoading(true);
            axios.post(Global.url + 'modify-patient', formData, { headers: { "authorization" : `Bearer ${getLocalStorage()}`} })
                .then(res => {
                    setMsg(res.data.message);
                    if(res.data.status) {
                        setIsOK("messageUserData__success");
                    } else {
                        setIsOK("messageUserData__fail");
                    }
                }).catch(err => {
                    setIsOK("messageUserData__fail");
                    console.log(err);
                }).finally(() => {
                    setDoneMsg(true);
                    setLoading(false);
                });
        }
    }

    return (
        <>
        <Header as='h3' className="headerUserPanel">
            Modificar paciente existente
        </Header>
        <Form>
            <Form.Input
                onChange={e => searchPatient(e.target.value)}
                fluid label='Paciente' 
                name="patient"
                value={searchValue}
                error={formError.patient}
                icon={searching ? "circle notch" : "search"}
                placeholder='Buscar paciente (nombre, apellido o DNI)'
            />
            {gotValues &&
                <div className="listValuesFound listValuesFound__Patients">
                    {resultsSearch.length === 0 && 
                        <div className="aValueFound">No se encontró ningún paciente que coincida. </div>
                    }
                    {resultsSearch.map((item) => (
                        <div 
                            onClick={() => selectPatient(item)} 
                            className="aValueFound"
                            key={item._id}
                        >
                            {item.name} {item.secondName} {item.lastname} {item.secondLastname} - <strong>{item.typeDNI}</strong>: {saniziteNumbers(item.dni)} - <strong>Obra social</strong>: {item.obrasocial}
                        </div>
                    ))}
                </div>
            }
            {selectedPatient !== null &&
                <div className="infoSelectedPatient">
                    <span className="textSelectedPatient"><strong>Paciente:</strong> <span>{selectedPatient.name} {selectedPatient.secondName} {selectedPatient.lastname} {selectedPatient.secondLastname}</span></span>
                    <span className="textSelectedPatient"><strong>Documento:</strong> <span>{selectedPatient.typeDNI} {saniziteNumbers(selectedPatient.dni)}</span></span>
                    <span className="textSelectedPatient"><strong>Obra social:</strong> <span>{selectedPatient.obrasocial} - {selectedPatient.nro}</span></span>
                </div>
            }
            <Form.Group widths='equal'>
                <Form.Input 
                    fluid label='Nombre' 
                    name="name"
                    required
                    value={formData.name}
                    placeholder='Nombre'
                    onChange={e => setFormData({...formData, [e.target.name]: e.target.value}) }
                />
                <Form.Input 
                    fluid label='Segundo nombre' 
                    name="secondName"
                    value={formData.secondName}
                    placeholder='Si no posee, dejarlo en blanco.'
                    onChange={e => setFormData({...formData, [e.target.name]: e.target.value}) }
                />
            </Form.Group>
            <Form.Group widths='equal'>
                <Form.Input 
                    fluid label='Apellido'
                    name="lastname"
                    required
                    value={formData.lastname}
                    placeholder='Apellido'
                    onChange={e => setFormData({...formData, [e.target.name]: e.target.value}) }
                />
                <Form.Input 
                    fluid label='Segundo apellido'
                    name="secondLastname"
                    value={formData.secondLastname}
                    placeholder='Si no posee, dejarlo en blanco.'
                    onChange={e => setFormData({...formData, [e.target.name]: e.target.value}) }
                />
            </Form.Group>
            <Form.Group widths='equal'>
                <Form.Field 
                    control={Select}
                    options={sexOptions}
                    search
                    required
                    label="Sexo"
                    placeholder="Sexo"
                    name="sex"
                    value={formData.sex}
                    error={formError.sex}
                    onChange={(e, {value}) => setFormData({...formData, sex: value}) }
                />
                <Form.Input 
                    fluid label='Fecha de nacimiento'
                    name="dateBirth"
                    type="date"
                    max={moment().subtract(1, "days").format("YYYY-MM-DD")}
                    value={formData.dateBirth}
                    error={formError.dateBirth}
                    placeholder='Fecha de nacimiento'
                    onChange={e => setFormData({...formData, [e.target.name]: e.target.value}) }
                />
            </Form.Group>
            <Form.Group widths='equal'>
                <Form.Field 
                    control={Select}
                    options={dniOptions}
                    search
                    required
                    label="Tipo de documento"
                    placeholder="Tipo de documento"
                    name="typeDNI"
                    value={formData.typeDNI}
                    error={formError.typeDNI}
                    onChange={(e, {value}) => setFormData({...formData, typeDNI: value}) }
                />
                <Form.Input 
                    fluid label='Número de documento'
                    name="dni"
                    type="number"
                    required
                    value={formData.dni}
                    error={formError.dni}
                    placeholder='Documento de identidad, sin puntos ni coma.'
                    onChange={e => setFormData({...formData, [e.target.name]: e.target.value}) }
                />
            </Form.Group>
            <Form.Group widths='equal'>
                <Form.Input 
                    fluid name="obrasocial" 
                    label='Obra social (si posee)' 
                    value={formData.obrasocial}
                    error={formError.obrasocial}
                    placeholder='Si no posee obra social, déjelo en blanco'
                    onChange={e => setFormData({...formData, [e.target.name]: e.target.value}) }
                />
                <Form.Input 
                    fluid 
                    name="nro" 
                    label='Número de obra social (si posee)' 
                    error={formError.nro}
                    value={formData.nro}
                    placeholder='Si no posee obra social, déjelo en blanco'
                    onChange={e => setFormData({...formData, [e.target.name]: e.target.value}) }
                />
            </Form.Group>
            <Form.Group widths='equal'>
                <Form.Input 
                    fluid name="qrPhotoPami" 
                    label='Link de la credencial de PAMI (Sacado del QR, si corresponde)' 
                    value={formData.qrPhotoPami}
                    error={formError.qrPhotoPami}
                    placeholder='Si no posee ninnguna dirección, no subir nada.'
                    onChange={e => setFormData({...formData, [e.target.name]: e.target.value}) }
                />
                <Form.Input 
                    fluid 
                    name="codePamiQR" 
                    label='Número de 3 dígitos de PAMI (si corresponde)' 
                    error={formError.codePamiQR}
                    value={formData.codePamiQR}
                    placeholder='Si no posee PAMI, déjelo en blanco.'
                    onChange={e => setFormData({...formData, [e.target.name]: e.target.value}) }
                />
            </Form.Group>
            <Form.Group widths='equal'>
                <Form.Input 
                    fluid name="email"
                    label='Email de contacto (para enviarle las recetas)'
                    placeholder='Email de contacto'
                    error={formError.email}
                    value={formData.email}
                    type="email"
                    onChange={e => setFormData({...formData, [e.target.name]: e.target.value}) }
                />
                <Form.Field>
                <div className="labelFormPhone">Teléfono celular de contacto</div>
                <PhoneInput
                    placeholder='Teléfono celular de contacto'
                    inputClass="inputStylePhone"
                    country='ar'
                    preferredCountries={['ar', 'uy', 'cl', 'br', 'py', 'bo', 've', 'co', 'us', 'es', 'ec', 'pe', 'mx']}
                    name="cellphone"
                    localization={es}
                    inputProps={{
                        name: 'cellphone',
                        required: true
                    }}
                    value={formData.cellphone}
                    error={formError.cellphone}
                    onChange={(value) => setFormData({ ...formData, "cellphone": value })}
                />
                </Form.Field>
            </Form.Group>
            {doneMsg && 
                <div className="messageDiv">
                    <Message className={isOK === "messageUserData__fail" ? "messageUserData messageUserData__fail" : "messageUserData messageUserData__success"}>
                        {msg}
                    </Message>
                </div>
            }
            <Form.Button loading={loading} disabled={loading} onClick={() => saveData()} className="btnUpdatePatient btnChangePersonalDate">Modificar paciente</Form.Button>
        </Form>
        </>
    )
}
