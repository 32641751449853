const limitForFreeUsers = [100, 250, 25];

const Prices = ["299", "2.699"];

const dniOptions = [
    { key: "0", value: '0', text: 'DNI' },
    { key: "1", value: '1', text: 'LC' },
    { key: "2", value: '2', text: 'LE' },
    { key: "3", value: '3', text: 'Pasaporte' }
];

const dniOptionsRegister = {
    "AR": [
        { key: "0", value: 'DNI', text: 'DNI' },
        { key: "1", value: 'LC', text: 'LC' },
        { key: "2", value: 'LE', text: 'LE' },
        { key: "3", value: 'Pasaporte', text: 'Pasaporte' }
    ]
};

const dataDoctorValuesOptions = {
    "AR": {
        "matriculaNacional": "Matrícula nacional (MN)",
        "matriculaProvincial": "Matrícula provincial (MP)"
    }
};

const dataDoctorValuesOptionsAbbr = {
    "AR": {
        "matriculaNacional": "M.N.",
        "matriculaProvincial": "M.P."
    }
};

const stateOptions = {
    "AR": [
        { key: 0, value: 0, text: 'C.A.B.A.', mandatory: "matriculaNacional" },
        { key: 1, value: 1, text: 'Buenos Aires', mandatory: "matriculaProvincial" },
        { key: 2, value: 2, text: 'Catamarca', mandatory: "matriculaProvincial" },
        { key: 3, value: 3, text: 'Chaco', mandatory: "matriculaProvincial" },
        { key: 4, value: 4, text: 'Chubut', mandatory: "matriculaProvincial" },
        { key: 5, value: 5, text: 'Córdoba', mandatory: "matriculaProvincial" },
        { key: 6, value: 6, text: 'Corrientes', mandatory: "matriculaProvincial" },
        { key: 7, value: 7, text: 'Entre Ríos', mandatory: "matriculaProvincial" },
        { key: 8, value: 8, text: 'Formosa', mandatory: "matriculaProvincial" },
        { key: 9, value: 9, text: 'Jujuy', mandatory: "matriculaProvincial" },
        { key: 10, value: 10, text: 'La Pampa', mandatory: "matriculaProvincial" },
        { key: 11, value: 11, text: 'La Rioja', mandatory: "matriculaProvincial" },
        { key: 12, value: 12, text: 'Mendoza', mandatory: "matriculaProvincial" },
        { key: 13, value: 13, text: 'Misiones', mandatory: "matriculaProvincial" },
        { key: 14, value: 14, text: 'Neuquén', mandatory: "matriculaProvincial" },
        { key: 15, value: 15, text: 'Río Negro', mandatory: "matriculaProvincial" },
        { key: 16, value: 16, text: 'Salta', mandatory: "matriculaProvincial" },
        { key: 17, value: 17, text: 'San Juan', mandatory: "matriculaProvincial" },
        { key: 18, value: 18, text: 'San Luis', mandatory: "matriculaProvincial" },
        { key: 19, value: 19, text: 'Santa Cruz', mandatory: "matriculaProvincial" },
        { key: 20, value: 20, text: 'Santa Fé', mandatory: "matriculaProvincial" },
        { key: 21, value: 21, text: 'Santiago del Estero', mandatory: "matriculaProvincial" },
        { key: 22, value: 22, text: 'Tierra del Fuego', mandatory: "matriculaProvincial" },
        { key: 23, value: 23, text: 'Tucumán', mandatory: "matriculaProvincial" }
    ]
};

const countriesOptions = [
    { key: "AR", value: "AR", text: 'Argentina' }
];

const sexOptions = [
    { key: "M", value: 'M', text: 'Masculino' },
    { key: "F", value: 'F', text: 'Femenino' }
];

export { Prices, limitForFreeUsers, dniOptions, dniOptionsRegister, dataDoctorValuesOptions, dataDoctorValuesOptionsAbbr, stateOptions, sexOptions, countriesOptions };