import React, { useState, useEffect } from 'react';

import { Form, Header, Message } from "semantic-ui-react";

import { isMobile } from "react-device-detect";

import axios from "axios";
import Global from "../../../../utils/Global";
import { getLocalStorage } from "../../../../utils/localStorage";

import "./ModifyRemedio.scss";

export default function ModifyRemedio(props) {

    const { infoUser } = props;

    const [doneMsg, setDoneMsg] = useState(false);
    const [isOK, setIsOK] = useState("messageUserData__fail");
    const [msg, setMsg] = useState("");

    const [loading, setLoading] = useState(false);

    const [optionsBusiness, setOptionsBusiness] = useState([]);
    const [currentValues, setCurrentValues] = useState([]);

    const [resultsSearch, setResultsSearch] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [searching, setSearching] = useState(false);
    const [gotValues, setGotValues] = useState(false);
    const [selectedRemedio, setSelectedRemedio] = useState(null);

    const [formError, setFormError] = useState({});
    const [formData, setFormData] = useState({
        "_id": "",
        "name": "",
        "price": "",
        "discounts": [],
        "updated": "",
        "business": [],
        "obs": ""
    });

    const searchRemedio = value => {
        setSearchValue(value);
        setSearching(true);
        setGotValues(false);
        setTimeout(function() {
            axios.post(Global.url + 'search-remedio', { search: value }, { headers: { "authorization" : `Bearer ${getLocalStorage()}`} })
                .then(res => {
                    setResultsSearch(res.data.results);
                }).catch(err => {
                    setResultsSearch([]);
                }).finally(() => {
                    setSearching(false);
                    setGotValues(true);
            });
        }, 300);
    }

    const selectRemedio = item => {
        setSearchValue("");
        setSelectedRemedio(item);
        setGotValues(false);
        setResultsSearch([]);
        setFormData({
            "_id": item._id,
            "name": item.name,
            "price": item.price,
            "discounts": item.discounts,
            "updated": item.updated,
            "business": item.business,
            "obs": item.obs
        });
        // hacer reestructura de dropdown
    }

    const setSelectedDropdownBusiness = (e, { value }) => {
        setCurrentValues(value);
        let businessSelected = formData.business;
        businessSelected.push(value);
        setFormData({ ...formData, "business": businessSelected });
    }

    const renderLabelBusiness = (label) => ({
        color: 'blue',
        content: `${label.text}`,
        icon: 'check',
    });
    
    const addItemDropdownBusiness = (e, { value }) => {
        let auxOptions = optionsBusiness;
        auxOptions.push({
            "key": value,
            "text": value,
            "value": value
        });
        setOptionsBusiness(auxOptions);
    }

    const saveData = () => {
        if(!formData.name) {
            setFormError({ name: "Este campo es obligatorio. "});
        } else {
            setLoading(true);
            axios.post(Global.url + 'modify-remedio', { formData: formData }, { headers: { "authorization" : `Bearer ${getLocalStorage()}`} })
                .then(res => {
                    setMsg(res.data.message);
                    if(res.data.status) {
                        setIsOK("messageUserData__success");
                        setFormData({
                            "_id": "",
                            "name": "",
                            "price": "",
                            "discounts": [],
                            "updated": "",
                            "business": [],
                            "obs": ""
                        });
                    } else {
                        setIsOK("messageUserData__fail");
                    }
                }).catch(err => {
                    setIsOK("messageUserData__fail");
                    console.log(err);
                }).finally(() => {
                    setDoneMsg(true);
                    setLoading(false);
                });
        }
    }

    return (
        <>
        <Header as='h3' className="headerUserPanel">
            Modificar obra social
        </Header>
        <Form>
            <Form.Input
                onChange={e => searchRemedio(e.target.value)}
                fluid label='Remedio' 
                name="remedio"
                value={searchValue}
                error={formError.remedio}
                icon={searching ? "circle notch" : "search"}
                placeholder='Buscar remedio'
            />
            {gotValues &&
                <div className="listValuesFound listValuesFound__Patients">
                    {resultsSearch.length === 0 && 
                        <div className="aValueFound">No se encontró ningún remedio que coincida. </div>
                    }
                    {resultsSearch.map((item) => (
                        <div 
                            onClick={() => selectRemedio(item)} 
                            className="aValueFound"
                            key={item._id}
                        >
                            {item.name} - {item.price}
                        </div>
                    ))}
                </div>
            }
            {selectedRemedio !== null &&
                <div className="infoSelectedPatient">
                    <span className="textSelectedPatient"><strong>Remedio:</strong> <span>{selectedRemedio.name}</span></span>
                    <span className="textSelectedPatient"><strong>Precio:</strong> {selectedRemedio.price}</span>
                </div>
            }
            <Form.Group widths='equal'>
                <Form.Input 
                    fluid label='Nombre de la obra social' 
                    name="name"
                    required
                    value={formData.name}
                    placeholder='Nombre de la obra social'
                    onChange={e => setFormData({...formData, [e.target.name]: e.target.value}) }
                />
            </Form.Group>
            <Form.Group widths='equal'>
                <Form.Dropdown
                    options={optionsBusiness}
                    fluid label='Empresas del remedio'
                    name="business"
                    search
                    multiple
                    selection
                    allowAdditions
                    value={currentValues}
                    placeholder='Empresas del remedio'
                    onAddItem={addItemDropdownBusiness}
                    renderLabel={renderLabelBusiness}
                    onChange={setSelectedDropdownBusiness}
                />
            </Form.Group>
            {doneMsg && 
                <div className="messageDiv">
                    <Message className={isOK === "messageUserData__fail" ? "messageUserData messageUserData__fail" : "messageUserData messageUserData__success"}>
                        {msg}
                    </Message>
                </div>
            }
            <Form.Button loading={loading} disabled={loading} onClick={() => saveData()} className="btnSubmit btnChangePersonalDate">Modificar remedio existente</Form.Button>
            {/* falta uno que diga borrar remedio */}
        </Form>
        </>
    )
}

