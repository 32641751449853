import React, { useState, useEffect } from 'react';
import { Grid, Menu } from "semantic-ui-react";
import { isMobile } from "react-device-detect";
import { useParams } from "react-router-dom";
import axios from "axios";
import Global from "../../../utils/Global";
import { getLocalStorage } from "../../../utils/localStorage";
import "./Planillas.scss";

//components
import CreatePlanilla from "./CreatePlanilla";
import ModifyPlanilla from "./ModifyPlanilla";
import ListPlanillas from "./ListPlanillas";
import Modules from "./Modules";

export default function Discounts(props) {

    const { infoUser } = props;

    let { subpath } = useParams();

    const [menuPlanillas, setMenuPlanillas] = useState(subpath !== undefined ? subpath : "create");

    return (
        <>
            <Grid className="gridUser gridUser__Admin" columns="equal">
                <Grid.Column className={isMobile ? "menuVertical" : "menuUserGrid"} width={isMobile ? 16 : 4}>
                    <Menu fluid vertical tabular={isMobile ? false : true} style={{ minHeight: isMobile ? "auto" : "67vh", height: "100%" }}>
                        <Menu.Item 
                            name="Sección de módulos"
                            content="Sección de módulos"
                            active={menuPlanillas === "modules"}
                            onClick={() => setMenuPlanillas("modules")}
                        />
                        <Menu.Item 
                            name="Crear planilla"
                            content="Crear planilla"
                            active={menuPlanillas === "create"}
                            onClick={() => setMenuPlanillas("create")}
                        />
                        <Menu.Item 
                            name="Modificar planilla"
                            content="Modificar planilla"
                            active={menuPlanillas === "modify"}
                            onClick={() => setMenuPlanillas("modify")}
                        />
                        <Menu.Item 
                            name="Listado de planillas"
                            content="Listado de planillas"
                            active={menuPlanillas === "list"}
                            onClick={() => setMenuPlanillas("list")}
                        />
                    </Menu>
                </Grid.Column>
                <Grid.Column className="contentUserGrid">
                    {(() => {
                        if (menuPlanillas === "new") {
                            return <CreatePlanilla infoUser={infoUser} />
                        } else if (menuPlanillas === "update") {
                            return <ModifyPlanilla infoUser={infoUser} />
                        } else if(menuPlanillas === "list") {
                            return <ListPlanillas infoUser={infoUser} />
                        } else if(menuPlanillas === "modules") {
                            return <Modules infoUser={infoUser} />
                        } else {
                            return <CreatePlanilla infoUser={infoUser} />
                        }
                    })()}
                </Grid.Column>
            </Grid>
        </>
    )
}
