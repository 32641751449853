import React, { useState, useEffect } from 'react';
import "./StastiticSuscription.scss";
import { Segment, Statistic, Label } from "semantic-ui-react";
import moment from "moment";
import { limitForFreeUsers } from "../../../utils/ValuesOptions";
import { Link } from "react-router-dom";

export default function StastiticSuscription(props) {

    const { infoUser, setActiveItem } = props;

    const [activeSuscription, setActiveSuscription] = useState(undefined);

    useEffect(() => {
        let dateToday = moment().format("DD/MM/YYYY");
        let date = moment(infoUser?.endDateSuscription, "DD/MM/YYYY");
        setActiveSuscription(date.isAfter(moment(dateToday, "DD/MM/YYYY")));
    }, [infoUser]);

    if(infoUser?.typeSuscription === "SUPERPREMIUM") {
        return (
            <Segment className="infoSubscription infoSubscription__active">
                <Statistic size='mini'>
                    <Statistic.Value>Suscripción vitalicia</Statistic.Value>
                    <Statistic.Label>Tipo de suscripción: vitalicia</Statistic.Label>
                    <Statistic.Label>Hasta fin de los tiempos</Statistic.Label>
                </Statistic>
            </Segment>
        )
    } else if(infoUser?.typeSuscription === "BASIC") {
        return (
            <Segment className="infoSubscription infoSubscription__free">
                <Statistic size='mini'>
                    <Statistic.Value>Suscripción gratuita
                        <Label onClick={() => setActiveItem("update-suscription")} size="tiny" color="teal" className="labelUpdate">
                            Actualizar plan
                        </Label>
                    </Statistic.Value>
                    <Statistic.Label>Max. <span className={checkLimit(infoUser.limitUser["max_patients"], 0)}>{infoUser.limitUser["max_patients"]+"/"+limitForFreeUsers[0]}</span> pacientes. </Statistic.Label>
                    <Statistic.Label>Max. <span className={checkLimit(infoUser.limitUser["max_recets"], 1)}>{infoUser.limitUser["max_recets"]+"/"+limitForFreeUsers[1]}</span> recetas este mes. </Statistic.Label>
                    {/* <Statistic.Label>Max. <span className={checkLimit(infoUser.limitUser["max_planillas"], 2)}>{infoUser.limitUser["max_planillas"]+"/"+limitForFreeUsers[2]}</span> planillas este mes. </Statistic.Label> */}
                </Statistic>
            </Segment>
        )
    } else {
        return (
            <Segment className={activeSuscription ? "infoSubscription infoSubscription__active" : "infoSubscription"}>
                <Statistic size='mini'>
                    <Statistic.Value>Suscripción {activeSuscription ? "activada" : "expirada"}</Statistic.Value>
                    <Statistic.Label>Tipo de suscripción: {infoUser?.timeSuscription === "month" ? "mensual" : "anual"}</Statistic.Label>
                    <Statistic.Label>Hasta {infoUser?.endDateSuscription}</Statistic.Label>
                </Statistic>
            </Segment>
        )
    }
}

function checkLimit(key, number) {
    let cssName = "valueLimit";
    if(key >= limitForFreeUsers[number]) {
        cssName = "valueLimit valueLimit__overcome";
    }
    return cssName;
}
