import React, { useState } from 'react';
import "./Home.scss";

import Start from "./Start";
import Howworks from "./Howworks";
import Whoarewe from "./Whoarewe";
import Stats from "./Stats";
import ExtraPlanilla from "./ExtraPlanilla";
import Pricing from "./Pricing";

export default function Home() {

    return (
        <>
            <Start />
            <Whoarewe />
            <Howworks />
            {/* <ExtraPlanilla /> */}
            <Pricing />
            {/* <Stats /> */}
        </>
    )
}
