import React, { useState, useEffect } from 'react';
import "./Done.scss";
import { Icon, Step, Message } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { isMobile } from "react-device-detect";
import axios from 'axios';
import Global from "../../utils/Global";

function Register(props) {

    const { isLogged } = props;

    const params = queryString.parse(props.location.search);
    const [msg, setMsg] = useState("¡Se creó el usuario exitosamente! Te envíamos un email para activar tu usuario. Si no lo encontrás, revisá en tu casilla de Spam. ");
    const [titleMsg, setTitleMsg] = useState("¡Éxito!");
    const [typeMsg, setTypeMsg] = useState("green");

    useEffect(() => {
        if(isLogged) {
            props.history.push("/dashboard");
        }
    }, [isLogged]);

    // useEffect(() => {
    //     if(Object.keys(params).length !== 0) {
    //         if(params.status !== "200") {
    //             setMsg("Hubo un error al registrarse. Por favor, póngase en contacto inmeditamente con contacto@rpdigitales.com.ar");
    //             setTypeMsg("red");
    //             setTitleMsg("Error");
    //         }
    //     } else {
    //         props.history.push("/");
    //     }
    // }, []);

    if(!isLogged) {
        return (
            <>
            <div className="thanksRegister">
                ¡Gracias por elegirnos! Por favor, siga los pasos necesarios para registrarse y completar la suscripción.
            </div>
            <div className="stepsMenu">
                <Step.Group widths={4}>
                    <Step completed={true} active={false}>
                    <Icon name='user doctor'  />
                    <Step.Content>
                        <Step.Title>Registro</Step.Title>
                        <Step.Description>Completar los datos de cuenta</Step.Description>
                    </Step.Content>
                    </Step>

                    <Step completed={true} active={false}>
                    <Icon name='calendar alternate outline' />
                    <Step.Content>
                        <Step.Title>Suscripción</Step.Title>
                        <Step.Description>Seleccionar tipo de suscripción</Step.Description>
                    </Step.Content>
                    </Step>

                    <Step completed={true} active={false}>
                    <Icon name='payment' />
                    <Step.Content>
                        <Step.Title>Pago</Step.Title>
                        <Step.Description>Seleccionar método de pago</Step.Description>
                    </Step.Content>
                    </Step>

                    <Step completed={true} active={true}>
                    <Icon name='check circle' />
                    <Step.Content>
                        <Step.Title>¡Listo!</Step.Title>
                        <Step.Description>Envío de activación de cuenta.</Step.Description>
                    </Step.Content>
                    </Step>
                </Step.Group>
            </div>
            <div id="registerDiv" className={isMobile ? "registerDivMobile" : "registerDiv"}>
                <div className="doneRegister">
                    <Message color={typeMsg}>
                        <Message.Header>{titleMsg}</Message.Header>
                        <Message.Content>{msg}</Message.Content>
                    </Message>
                </div>
            </div>
            </>
        )
    } else {
        return null;
    }
}


export default withRouter(Register);
