import React from 'react';
import { Grid, Segment, Header, Image, List } from "semantic-ui-react";
import ScrollAnimation from 'react-animate-on-scroll';
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import Visa from "../../../assets/images/payments/VISA.svg";
import Mastercard from "../../../assets/images/payments/MASTERCARD.svg";
import AMEX from "../../../assets/images/payments/AMEX.svg";
import Cabal from "../../../assets/images/payments/cabal.svg";
import Naranja from "../../../assets/images/payments/naranja.svg";
import { limitForFreeUsers, Prices } from "../../../utils/ValuesOptions";
import "./Pricing.scss";

export default function Pricing() {
    return (
        <div className="pricingHome">
            <div className={isMobile ? "pricingDiv pricingDiv__Mobile" : "pricingDiv"}>
                <Header as='h1' className="titlePricingDiv">Precios</Header>
                <div className={isMobile ? "subtitlePricingDiv subtitlePricingDiv__Mobile" : "subtitlePricingDiv"}>Contamos con dos tipos de suscripciones para utilizar nuestro servicio.</div>
                <div className={isMobile ? "segmentsPricing segmentsPricing__Mobile" : "segmentsPricing"}>
                    <ScrollAnimation animateIn="fadeInRight" animateOnce={true}>
                        <div className={isMobile ? "divSegment divSegment__Mobile divSegment__free divSegment__free__Mobile" : "divSegment divSegment__free"}>
                            <Segment as={Link} to="/register" className={isMobile ? "pricingSegment pricingSegment__0 pricingSegment__0__Mobile" : "pricingSegment pricingSegment__0"}>
                                <Header as='h2' className="titleHeaderSegment titleHeaderSegment__free">Suscripción gratuita</Header>
                                <List bulleted divided className="listSegment">
                                    <List.Item className="listElement">Max. {limitForFreeUsers[0]} pacientes. </List.Item>
                                    <List.Item className="listElement">Max. {limitForFreeUsers[1]} recetas por mes. </List.Item>
                                    {/* <List.Item className="listElement">Max. {limitForFreeUsers[2]} planillas por mes. </List.Item> */}
                                    <List.Item className="listElement">Con publicidad. </List.Item>
                                    {/* <List.Item className="listElement">Sin posibilidad de envío por WhatsApp. </List.Item> */}
                                </List>
                                <Header.Subheader className="priceSegment">Gratis</Header.Subheader>
                                <Header.Subheader className="priceSegmentRegister">Registrarse</Header.Subheader>
                                <div className={isMobile ? "extraDivPricing extraDivPricing__Mobile" : "extraDivPricing"}>Se puede actualizar el plan de suscripción si luego cambiás de opinión.</div>
                            </Segment>
                        </div>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="fadeInDown" animateOnce={true}>
                        <div className={isMobile ? "divSegment divSegment__Mobile divSegment__month divSegment__month__Mobile" : "divSegment divSegment__month"}>
                            <Segment as={Link} to="/register" className={isMobile ? "pricingSegment pricingSegment__1 pricingSegment__1__Mobile" : "pricingSegment pricingSegment__1"}>
                                <Header as='h2' className="titleHeaderSegment titleHeaderSegment__month">Suscripción mensual</Header>
                                <List bulleted divided className="listSegment">
                                    <List.Item className="listElement">Pacientes y recetas ilimitadas. </List.Item>
                                    {/* <List.Item className="listElement">Acceso a nuestras planillas. </List.Item> */}
                                    <List.Item className="listElement">Primeros 7 días gratuito. </List.Item>
                                    <List.Item className="listElement">Soporte las 24/7. </List.Item>
                                    {/* <List.Item className="listElement">Con posibilidad de enviar por WhatsApp. </List.Item> */}
                                </List>
                                <Header.Subheader className={isMobile ? "priceSegment priceSegment__Mobile" : "priceSegment"}>ARS {Prices[0]} / mes</Header.Subheader>
                                <Header.Subheader className="priceSegmentRegister">Contratar ahora</Header.Subheader>
                                <div className={isMobile ? "extraDivPricing extraDivPricing__Mobile" : "extraDivPricing"}>Se puede desactivar la renovación automática en Config. de pagos en Mi Cuenta.</div>
                            </Segment>
                        </div>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="fadeInLeft" animateOnce={true}>
                        <div className={isMobile ? "divSegment divSegment__Mobile divSegment__year divSegment__year__Mobile" : "divSegment divSegment__year"}>
                            <Segment as={Link} to="/register" className="pricingSegment pricingSegment__2">
                                <Header as='h2' className="titleHeaderSegment titleHeaderSegment__year">Suscripción anual</Header>
                                <div className="free3months">¡3 meses gratis!</div>
                                <List bulleted divided className="listSegment">
                                    <List.Item className="listElement">Pacientes y recetas ilimitadas. </List.Item>
                                    {/* <List.Item className="listElement">Acceso a nuestras planillas. </List.Item> */}
                                    <List.Item className="listElement">Primeros 7 días gratuito. </List.Item>
                                    <List.Item className="listElement">Soporte las 24/7. </List.Item>
                                    {/* <List.Item className="listElement">Con posibilidad de enviar por WhatsApp. </List.Item> */}
                                </List>
                                <Header.Subheader className={isMobile ? "priceSegment priceSegment__Mobile" : "priceSegment"}>ARS {Prices[1]} / año</Header.Subheader>
                                <Header.Subheader className="priceSegmentRegister">Contratar ahora</Header.Subheader>
                                <div className={isMobile ? "extraDivPricing extraDivPricing__Mobile" : "extraDivPricing"}>Se puede desactivar la renovación automática en Config. de pagos en Mi Cuenta.</div>
                            </Segment>
                        </div>
                    </ScrollAnimation>
                </div>
                <div className="paymentTypes">
                    <div className={isMobile ? "titlePayments titlePayments__Mobile" : "titlePayments"}>Aceptamos los siguientes métodos de pago</div>
                    <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                        <Grid columns={isMobile ? 2 : "equal"} className="typePaymentsAcceptGrid">
                            <Grid.Column className={isMobile ? "gridColumnPaymentHomeMobile" : "gridColumnPaymentHome"}>
                                <Image src={Visa} />
                                <div className="titleCardPayment">
                                    Visa débito y crédito
                                </div>
                            </Grid.Column>
                            <Grid.Column className={isMobile ? "gridColumnPaymentHomeMobile" : "gridColumnPaymentHome"}>
                                <Image src={Mastercard} />
                                <div className="titleCardPayment">
                                    Mastercard débito y crédito
                                </div>
                            </Grid.Column>
                            <Grid.Column className={isMobile ? "gridColumnPaymentHomeMobile" : "gridColumnPaymentHome"}>
                                <Image src={AMEX} />
                                <div className="titleCardPayment">
                                    American Express
                                </div>
                            </Grid.Column>
                            <Grid.Column className={isMobile ? "gridColumnPaymentHomeMobile" : "gridColumnPaymentHome"}>
                                <Image src={Cabal} />
                                <div className="titleCardPayment">
                                    Cabal débito y crédito
                                </div>
                            </Grid.Column>
                            <Grid.Column className={isMobile ? "gridColumnPaymentHomeMobile" : "gridColumnPaymentHome"}>
                                <Image src={Naranja} />
                                <div className="titleCardPayment">
                                    Naranja
                                </div>
                            </Grid.Column>
                        </Grid>
                    </ScrollAnimation>
                </div>
            </div>
        </div>
    )
}
