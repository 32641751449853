import React, { useState, useEffect } from 'react';
import "./Login.scss";
import Global from "../../utils/Global";
import axios from "axios";
import { Message, Card, Form, Input, Icon, Button } from 'semantic-ui-react';
import { setLocalStorage } from "../../utils/localStorage";
import { withRouter } from "react-router-dom";
import { validateEmail } from '../../utils/Validations';
import LoadingPage from "../../components/LoadingPage";
import { isMobile } from "react-device-detect";

function Login(props) {

    const { setIsLogged, isLogged, isLoaded } = props;

    useEffect(() => {
        if(isLogged) {
            props.history.push("/dashboard");
        }
    }, [isLogged]);

    useEffect(() => {
        if(!isLogged && isLoaded) {
            document.getElementById("form-input-control-user").focus();
        }
    }, [isLogged, isLoaded]);

    const [visiblePass, setVisiblePass] = useState(false);
    const [valueType, setValueType] = useState("");
    const [openResetEmail, setOpenResetEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [loadingEmail, setLoadingEmail] = useState(false);
    const [formError, setFormError] = useState({});
    const [status, setStatus] = useState(false);
    const [statusRes, setStatusRes] = useState("failMessage");
    const [contentMessage, setContentMessage] = useState("");
    const [statusPass, setStatusPass] = useState(false);
    const [formData, setFormData] = useState({
        "user": "",
        "password": ""
    });

    const [email, setEmail] = useState("");

    const loginUser = () => {
        if(!formData.user) {
            setFormError({ "user": "El campo usuario es obligatorio. "});
        } else if(!formData.password) {
            setFormError({ "password": "El campo Contraseña es obligatorio. "});
        } else {
            setFormError({});
            setLoading(true);
            axios.post(Global.url + "sign-in", { formData })
                .then(res => {
                    setContentMessage(res.data.message);
                    if(res.data.status) {
                        setStatusRes("doneMessage");
                        setLocalStorage(res.data.token);
                        setTimeout(function() {
                            setIsLogged(true);
                            props.history.push("/dashboard");
                        }, 1500);
                    }
                }).catch(err => {
                    setContentMessage(err.data);
                    setStatusRes("failMessage");
                    setLocalStorage(null);
                }).finally(() => {
                    setStatus(true);
                    setLoading(false);
                    // HACER X INTENTOS Y PONER RECAPTCHA ENTONCES
                });
        }
    }

    const needHelp = (value) => {
        if(value === "password") {
            setValueType("send-recover-password");
        } else {
            setValueType("resend-activation-user");
        }
        setOpenResetEmail(true);
    }

    const sendResetEmail = () => {
        setStatusPass(false);
        if(email !== "" && validateEmail(email)) {
            setLoadingEmail(true);
            axios.post(Global.url + valueType, { email: email })
                .then(res => {
                    setContentMessage(res.data.message);
                    if(res.data.status) {
                        setStatusRes("doneMessage");
                    } else {
                        setStatusRes("failMessage");
                    }
                }).catch(err => {
                    setContentMessage("Hubo un error en el servidor. Por favor, intente más tarde. ");
                    setStatusRes("failMessage");
                }).finally(() => {
                    setStatus(false);
                    setStatusPass(true);
                    setLoadingEmail(false);
                });
        }
    }
    
    if(!isLogged && isLoaded) {
        return (
            <div id="loginCard" style={{ marginTop: isMobile ? 20 : 100 }}>
                <Card>
                    <Form>
                        <Form.Field
                            id='form-input-control-user'
                            control={Input}
                            label='Usuario o email'
                            placeholder='Usuario o email'
                            required
                            name="user"
                            error={formError.user}
                            icon={<Icon name="user" />}
                            onChange={e => setFormData({ ...formData, [e.target.name]: e.target.value })}
                        />
                        <Form.Field
                            id='form-input-control-password'
                            control={Input}
                            label='Contraseña'
                            placeholder='Contraseña'
                            required
                            type={visiblePass ? "text" : "password"}
                            name="password"
                            error={formError.password}
                            icon={<Icon name={visiblePass ? 'eye slash' : 'eye'} link onClick={() => setVisiblePass(prevValue => !prevValue)} />}
                            onChange={e => setFormData({ ...formData, [e.target.name]: e.target.value })}
                        />
                        <Button loading={loading} disabled={loading} className="btnLoginNow" onClick={() => loginUser()} color='red'>
                            Iniciar sesión
                        </Button>
                        {status &&
                            <Message className={statusRes}>
                                {contentMessage}
                            </Message>
                        }
                    </Form>
                    <div className="resetPassDiv" onClick={() => needHelp("password")}>Me olvidé la contraseña.</div>
                    <div className="resetPassDiv" onClick={() => needHelp("confirm")}>No me llegó el email de activación de cuenta.</div>
                    {openResetEmail &&
                        <Form className="formResetEmail">
                            <Form.Field
                                id='form-input-control-reset-email'
                                control={Input}
                                label='Email'
                                placeholder='Email'
                                required
                                type="email"
                                name="email"
                                icon={<Icon name="at" />}
                                onChange={e => setEmail(e.target.value)}
                            />
                            <Button loading={loadingEmail} disabled={loadingEmail} className="btnLoginNow" onClick={() => sendResetEmail()} color={valueType === "send-recover-password" ? 'green' : 'blue'}>
                                {valueType === "send-recover-password" ? "Recuperar contraseña" : "Enviar email de activación de cuenta"}
                            </Button>
                            {statusPass &&
                                <Message className={statusRes}>
                                    {contentMessage}
                                </Message>
                            }
                        </Form>
                    }
                </Card>
            </div>
        )
    } else {
        return <LoadingPage />;
    }
}


export default withRouter(Login);