import React, { useState, useEffect } from 'react';

import { Header, Form, Message } from "semantic-ui-react";

import { isMobile } from "react-device-detect";

import axios from "axios";
import Global from "../../../../utils/Global";
import { getLocalStorage } from "../../../../utils/localStorage";

import "./ModifyPlanilla.scss";

export default function ModifyPlanilla(props) {

    const { infoUser } = props;

    const [doneMsg, setDoneMsg] = useState(false);
    const [isOK, setIsOK] = useState("messageUserData__fail");
    const [msg, setMsg] = useState("");

    const [loading, setLoading] = useState(false);

    const [resultsSearch, setResultsSearch] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [searching, setSearching] = useState(false);
    const [gotValues, setGotValues] = useState(false);
    const [selectedDiscount, setSelectedDiscount] = useState(null);

    const [formError, setFormError] = useState({});
    const [formData, setFormData] = useState({
        "plan": "",
        "discount": 0,
        "obs": ""
    });

    const searchRemedioDiscount = value => {
        setSearchValue(value);
        setSearching(true);
        setGotValues(false);
        setTimeout(function() {
            axios.post(Global.url + 'search-discount', { search: value }, { headers: { "authorization" : `Bearer ${getLocalStorage()}`} })
                .then(res => {
                    setResultsSearch(res.data.results);
                }).catch(err => {
                    setResultsSearch([]);
                }).finally(() => {
                    setSearching(false);
                    setGotValues(true);
            });
        }, 300);
    }

    const selectDiscount = item => {
        setSearchValue("");
        setSelectedDiscount(item);
        setFormData({
            "plan": "",
            "discount": item.discount,
            "obs": item.obs
        })
        setGotValues(false);
        setResultsSearch([]);
        setDoneMsg(false);
        setIsOK("messageUserData__fail");
    }

    const saveData = () => {
        if(!selectedDiscount) {
            setFormError({ discount: "Debes seleccionar un descuento. "})
        } else {
            setLoading(true);
            //TENEMOS QUE ENVIAR EL ID DE REMEDIO Y OBRA SOCIAL
            axios.post(Global.url + 'modify-discount', formData, { headers: { "authorization" : `Bearer ${getLocalStorage()}`} })
                .then(res => {
                    setMsg(res.data.message);
                    if(res.data.status) {
                        setIsOK("messageUserData__success");
                        setFormData({
                            "plan": "",
                            "discount": "",
                            "obs": ""
                        });
                    } else {
                        setIsOK("messageUserData__fail");
                    }
                }).catch(err => {
                    setIsOK("messageUserData__fail");
                    console.log(err);
                }).finally(() => {
                    setDoneMsg(true);
                    setLoading(false);
                });
        }
    }

    return (
        <>
        <Header as='h3' className="headerUserPanel">
            Modificar descuento existente
        </Header>
        <Form>
            <Form.Input
                onChange={e => searchRemedioDiscount(e.target.value)}
                fluid label='Descuento' 
                name="discount"
                value={searchValue}
                error={formError.discount}
                icon={searching ? "circle notch" : "search"}
                placeholder='Buscar descuento'
            />
            {gotValues &&
                <div className="listValuesFound listValuesFound__Patients">
                    {resultsSearch.length === 0 && 
                        <div className="aValueFound">No se encontró ningún descuento que coincida. </div>
                    }
                    {resultsSearch.map((item) => (
                        <div 
                            onClick={() => selectDiscount(item)} 
                            className="aValueFound"
                            key={item._id}
                        >
                            {item.name} - <strong>Precio</strong>: {item.price}
                        </div>
                    ))}
                </div>
            }
            {selectedDiscount !== null &&
                <div className="infoSelectedPatient">
                    <span className="textSelectedPatient"><strong>Remedio:</strong> <span>//nombre de remedio</span></span>
                    <span className="textSelectedPatient"><strong>Precio:</strong> <span>//precio original</span></span>
                    <span className="textSelectedPatient"><strong>Observaciones:</strong> <span>//obs</span></span>
                </div>
            }
            <Form.Group widths='equal'>
                <Form.Input 
                    fluid label='Descuento en %' 
                    name="discount"
                    required
                    type="number"
                    value={formData.discount}
                    error={formError.discount}
                    placeholder='Descuento en %'
                    onChange={e => setFormData({...formData, [e.target.name]: e.target.value}) }
                />
            </Form.Group>
            <Form.Group widths='equal'>
                <Form.TextArea
                    label='Observaciones del descuento'
                    name="obs"
                    value={formData.obs}
                    placeholder='Observaciones del descuento'
                    onChange={e => setFormData({...formData, [e.target.name]: e.target.value}) }
                />
            </Form.Group>
            {doneMsg && 
                <div className="messageDiv">
                    <Message className={isOK === "messageUserData__fail" ? "messageUserData messageUserData__fail" : "messageUserData messageUserData__success"}>
                        {msg}
                    </Message>
                </div>
            }
            <Form.Button loading={loading} disabled={loading} onClick={() => saveData()} className="btnSubmit btnChangePersonalDate">Mofificar descuento existente</Form.Button>
        </Form>
        </>
    )
}
