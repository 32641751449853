import React, { useRef, useState, useEffect } from 'react';

import { Form, Select, Image, Header, Message } from "semantic-ui-react";

import SignatureCanvas from "react-signature-canvas";

import axios from "axios";
import Global from "../../../../utils/Global";
import { getLocalStorage } from '../../../../utils/localStorage';
import { validateEmail , validateNumber } from '../../../../utils/Validations';
import { dataDoctorValuesOptions, countriesOptions, stateOptions, sexOptions } from '../../../../utils/ValuesOptions';

import "./Personal.scss";
import ModalComponent from '../../../ModalComponent/ModalComponent';

export default function Personal(props) {

    const { infoUser, setInfoUser } = props; 

    const [signature, setSignature] = useState(undefined);
    const [settedSig, setSettedSig] = useState(false);

    const [loading, setLoading] = useState(false);

    const [isOK, setIsOK] = useState("messageUserData__fail");
    const [msg, setMsg] = useState("");
    const [doneMsg, setDoneMsg] = useState(false);

    const [openModal, setOpenModal] = useState(false);
    const [openModalQuestion, setOpenModalQuestion] = useState(false);
    

    const [formError, setFormError] = useState({});
    const [formData, setFormData] = useState({
        "name": infoUser ? infoUser.name : "",
        "lastname": infoUser ? infoUser.lastname : "",
        "sex": infoUser ? infoUser.sex : "",
        "state": infoUser ? infoUser.state : "",
        "address": infoUser ? infoUser.address : "",
        "zipcode": infoUser ? infoUser.zipcode : "",
        "phone": infoUser ? infoUser.phone : "",
        "emailJob": infoUser ? infoUser.emailJob : "",
        "cellphone": infoUser ? infoUser.cellphone : "",
        "country": infoUser ? infoUser.country : "",
        "speciality": infoUser ? infoUser.speciality : "",
        "dataDoctorValues": infoUser ? infoUser.dataDoctorValues : "",
        "signature": ""
    });

    useEffect(() => {
        axios.post(Global.url + 'get-signature', { mobile: false }, { headers: { "authorization" : `Bearer ${getLocalStorage()}`}, responseType: 'blob' })
            .then(res => {
                var imageUrl = URL.createObjectURL(res.data);
                setSignature(imageUrl);
            }).catch(err => {
                console.log(err);
            })
    }, []);

    const signatureRef = useRef({});

    const openModalSignature = () => {
        setOpenModal(true);
    }

    const restartSignature = () => {
        signatureRef.current.clear();
        saveSignature(undefined);
        setSettedSig(false);
        setOpenModal(true);
    }

    const saveSignature = () => {
        if(settedSig) {
            setSignature(signatureRef.current.getSignaturePad().toDataURL("image/jpg")); //save in formData
            setFormData(formData => ({ ...formData, signature: signatureRef.current.getSignaturePad().toDataURL("image/jpg") }));
            setOpenModal(false);
        } else {
            alert("Falta firmar");
        }
    }

    const saveData = () => {
        if(!formData.name) {
            setFormError({ name: "Este campo es obligatorio. "});
        } else if(!formData.lastname) {
            setFormError({ lastname: "Este campo es obligatorio. "});
        } else if(!formData.sex) {
            setFormError({ sex: "Este campo es obligatorio. "});
        } else if(formData.emailJob && !validateEmail(formData.emailJob)) {
            setFormError({ emailJob: "El email no es correcto. Si no desea introducir nada, déjelo en blanco. "});
        } else if(formData.phone && !validateNumber(formData.phone)) {
            setFormError({ phone: "El teléfono fijo no es válido. Si no desea introducir nada, déjelo en blanco. "});
        } else if(formData.cellphone && !validateNumber(formData.cellphone)) {
            setFormError({ cellphone: "El teléfono celular no es válido. Si no desea introducir nada, déjelo en blanco. "});
        } else if(formData.state === "" || formData.state === null) {
            setFormError({ state: "Este campo es obligatorio. "});
        } else if(formData.dataDoctorValues[stateOptions[formData?.country][formData?.state]?.mandatory] === "" || !validateNumber(formData?.dataDoctorValues[stateOptions[formData.country][formData?.state]?.mandatory])) {
            setFormError({ [stateOptions[formData?.country][formData?.state]?.mandatory]: "La matrícula solo debe contener números, sin puntos ni guiones. "});
        } else {
            let allOk = true;
            Object.keys(formData.dataDoctorValues).map(function(keyName) {
                if(formData.dataDoctorValues[keyName] !== "" && !validateNumber(formData.dataDoctorValues[keyName])) {
                    allOk = false;
                    setFormError({ [keyName]: "La matrícula solo debe contener números, sin puntos ni guiones. "});
                }
            });
            if(allOk) {
                setLoading(true);
                setFormError({});
                axios.put(Global.url + 'change-data-personal', formData, { headers: { "authorization" : `Bearer ${getLocalStorage()}`} })
                    .then(res => {
                        setMsg(res.data.message);
                        if(res.data.status) {
                            setInfoUser(res.data.result);
                            setIsOK("messageUserData__success");
                        } else {
                            setIsOK("messageUserData__fail");
                        }
                    }).catch(err => {
                        setIsOK("messageUserData__fail");
                        console.log(err);
                    }).finally(() => {
                        setDoneMsg(true);
                        setLoading(false);
                    });
            }
        }
    }

    return (
        <>
        <Header as='h5' className="headerUserPanel">
            Para crear y duplicar recetas, son obligatorios rellenar los campos que contienen asterico rojo y hacer la firma digital. 
            {/* Estos datos estarán en el código QR en las recetas. Si desea que algunos datos no estén en el código QR, por favor diríjase a Privacidad. 
            <span className="clickQuestionWhy" onClick={() => setOpenModalQuestion(true)}> ¿Por qué son obligatorios? </span>*/}
        </Header>
        <Form>
            <Form.Group widths='equal'>
                <Form.Input 
                    fluid label='Nombre' 
                    name="name"
                    type="text"
                    required
                    value={formData.name}
                    placeholder='Nombre'
                    onChange={e => setFormData({...formData, [e.target.name]: e.target.value}) }
                />
                <Form.Input 
                    fluid label='Apellido'
                    name="lastname"
                    type="text"
                    required
                    value={formData.lastname}
                    placeholder='Apellido'
                    onChange={e => setFormData({...formData, [e.target.name]: e.target.value}) }
                />
                <Form.Field 
                    control={Select}
                    options={sexOptions}
                    search
                    required
                    label="Sexo"
                    placeholder="Sexo"
                    name="sex"
                    value={formData.sex}
                    error={formError.sex}
                    onChange={(e, {value}) => setFormData({...formData, sex: value}) }
                />
            </Form.Group>
            <Form.Input 
                control={Select}
                options={countriesOptions}
                search
                required
                fluid name="country"
                label={"País donde se encuentra "+(formData.sex === "M" ? "matriculado" : "matriculada")}
                placeholder={"País donde se encuentra "+(formData.sex === "M" ? "matriculado" : "matriculada")}
                value={formData.country}
                onChange={(e, {value}) => setFormData({...formData, country: value}) }
            />
            <Form.Group widths='equal'>
                <Form.Field 
                    control={Select}
                    options={stateOptions[formData.country]}
                    search
                    required
                    label="Provincia donde trabaja"
                    name="state"
                    value={formData.state}
                    error={formError.state}
                    placeholder="Provincia donde trabaja"
                    onChange={(e, {value}) => setFormData({...formData, state: value}) }
                />
                <Form.Input 
                    fluid
                    type="text"
                    name="address" 
                    label='Domicilio (Consultorio, clínica u hospital)' 
                    placeholder='Domicilio' 
                    value={formData.address}
                    onChange={e => setFormData({...formData, [e.target.name]: e.target.value}) }
                />
                <Form.Input 
                    fluid 
                    type="text"
                    name="zipcode" 
                    label='Código postal' 
                    value={formData.zipcode}
                    placeholder='Código postal'
                    onChange={e => setFormData({...formData, [e.target.name]: e.target.value}) }
                />
            </Form.Group>
            <Form.Group widths='equal'>
                <Form.Input 
                    fluid name="emailJob" 
                    label='Correo electrónico' 
                    type="email" 
                    value={formData.emailJob}
                    error={formError.emailJob}
                    placeholder='Correo electrónico'
                    onChange={e => setFormData({...formData, [e.target.name]: e.target.value}) }
                />
                <Form.Input 
                    fluid 
                    type="text"
                    name="phone" 
                    label='Teléfono fijo' 
                    error={formError.phone}
                    value={formData.phone}
                    placeholder='Teléfono fijo'
                    onChange={e => setFormData({...formData, [e.target.name]: e.target.value}) }
                />
                <Form.Input 
                    fluid 
                    type="text"
                    name="cellphone" 
                    label='Teléfono celular' 
                    error={formError.cellphone}
                    value={formData.cellphone}
                    placeholder='Teléfono celular'
                    onChange={e => setFormData({...formData, [e.target.name]: e.target.value}) }
                />
            </Form.Group>
            <Form.Input 
                fluid name="speciality"
                label='Especialidad'
                type="text"
                placeholder='Especialidad'
                value={formData.speciality}
                onChange={e => setFormData({...formData, [e.target.name]: e.target.value}) }
            />
            <Form.Group widths='equal'>
                {Object.keys(infoUser?.dataDoctorValues).map((element) => (
                    <Form.Input
                        key={element}
                        type="text"
                        fluid name={element}
                        label={dataDoctorValuesOptions[formData.country][element]}
                        placeholder={dataDoctorValuesOptions[formData.country][element]}
                        error={formError[element]}
                        defaultValue={formData.dataDoctorValues[element]}
                        required={stateOptions[formData.country][formData?.state]?.mandatory === element ? true : false}
                        onChange={e => setFormData({...formData, dataDoctorValues: { ...formData["dataDoctorValues"], [e.target.name]: e.target.value} }) }
                    />
                ))}
            </Form.Group>
            <Form.Button inline label="Firma digital" className="signatureButton" onClick={() => openModalSignature()}>Hacer firma digital</Form.Button>
            <div>
                <div><strong>Previsualización de la firma digital</strong></div>
                <div className="signaturePhoto">
                    {signature && 
                        <Image src={signature} />
                    }
                </div>
            </div>
            {doneMsg && 
                <div className="messageDiv">
                    <Message className={isOK === "messageUserData__fail" ? "messageUserData messageUserData__fail" : "messageUserData messageUserData__success"}>
                        {msg}
                    </Message>
                </div>
            }
            <Form.Button loading={loading} disabled={loading} onClick={() => saveData()} className="btnSubmit btnChangePersonalDate">Guardar datos</Form.Button>
        </Form>
        <ModalComponent titleModal="Haga su firma digital aquí" contentYes="Validar firma" size="small" openModal={openModal} setOpenModal={setOpenModal} functionToDo={saveSignature} functionToDoNotOk={restartSignature}>
            <SignatureCanvas canvasProps={{ className: 'sigCanvas' }} ref={signatureRef} onBegin={() => {setSettedSig(true)}} />
        </ModalComponent>
        <ModalComponent includeActions={false} titleModal="¿Por qué debo rellenar los campos para crear recetas?" contentYes="OK" size="tiny" openModal={openModalQuestion} setOpenModal={setOpenModalQuestion}>
            BLA BLA BLA...
        </ModalComponent>
        </>
    )
}
