import React, { useState } from 'react';
import "./Pacientes.scss";
import { useParams } from "react-router-dom";
import { Grid, Menu } from "semantic-ui-react";
import New from "./New";
import Update from "./Update";
import List from "./List";
import Import from "./Import";
import { isMobile } from "react-device-detect";

export default function Pacientes(props) {

    const { infoUser, setInfoUser } = props;

    let { subpath } = useParams();

    const [menuPacientes, setMenuPacientes] = useState(subpath !== undefined ? subpath : "new");

    return (
        <Grid className="gridUser" columns='equal'>
            <Grid.Column className={isMobile ? "menuVertical" : "menuUserGrid"} width={isMobile ? 16 : 4}>
                <Menu fluid vertical tabular={isMobile ? false : true} style={{ minHeight: isMobile ? "auto" : "67vh", height: "100%" }}>
                    <Menu.Item 
                        name="Nuevo paciente"
                        content="Nuevo paciente"
                        active={menuPacientes === "new"}
                        onClick={() => setMenuPacientes("new")}
                    />
                    <Menu.Item 
                        name="Modificar paciente"
                        content="Modificar paciente"
                        active={menuPacientes === "update"}
                        onClick={() => setMenuPacientes("update")}
                    />
                    <Menu.Item 
                        name="Listado de pacientes"
                        content="Listado de pacientes"
                        active={menuPacientes === "list"}
                        onClick={() => setMenuPacientes("list")}
                    />
                    <Menu.Item 
                        name="Importar pacientes"
                        content="Importar pacientes"
                        active={menuPacientes === "import"}
                        onClick={() => setMenuPacientes("import")}
                    />
                </Menu>
            </Grid.Column>
            <Grid.Column className="contentUserGrid">
                {(() => {
                    if (menuPacientes === "new") {
                        return <New setInfoUser={setInfoUser} />
                    } else if (menuPacientes === "update") {
                        return <Update />
                    } else if(menuPacientes === "list") {
                        return <List infoUser={infoUser} />
                    } else if(menuPacientes === "import") {
                        return <Import infoUser={infoUser} setInfoUser={setInfoUser} />
                    } else {
                        return <New setInfoUser={setInfoUser} />
                    }
                })()}
            </Grid.Column>
        </Grid>
    )
}
