import React, { useState, useEffect } from 'react';

import { Grid, Menu } from "semantic-ui-react";

import { isMobile } from "react-device-detect";

import axios from "axios";
import Global from "../../../utils/Global";
import { getLocalStorage } from "../../../utils/localStorage";

import "./Discounts.scss";

//components
import AddDiscount from "./AddDiscount";
import ModifyDiscount from "./ModifyDiscount";
import ListDiscounts from "./ListDiscounts";

export default function Discounts(props) {

    const { infoUser } = props;

    const [menuDiscounts, setMenuDiscounts] = useState("adddiscount");

    return (
        <>
            <Grid className="gridUser gridUser__Admin">
                <Grid.Column className={isMobile ? "menuVertical" : "menuUserGrid"} width={isMobile ? 16 : 4}>
                    <Menu fluid vertical tabular={isMobile ? false : true} style={{ minHeight: isMobile ? "auto" : "67vh", height: "100%" }}>
                        <Menu.Item 
                            name="Agregar descuento"
                            content="Agregar descuento"
                            active={menuDiscounts === "adddiscount"}
                            onClick={() => setMenuDiscounts("adddiscount")}
                        />
                        <Menu.Item 
                            name="Modificar descuento"
                            content="Modificar descuento"
                            active={menuDiscounts === "modifydiscount"}
                            onClick={() => setMenuDiscounts("modifydiscount")}
                        />
                        <Menu.Item 
                            name="Listado de descuentos"
                            content="Listado de descuentos"
                            active={menuDiscounts === "listdiscounts"}
                            onClick={() => setMenuDiscounts("listdiscounts")}
                        />
                    </Menu>
                </Grid.Column>
                <Grid.Column className="contentUserGrid" width={isMobile ? 16 : 12}>
                    {menuDiscounts === "adddiscount" &&
                        <AddDiscount infoUser={infoUser} />
                    }
                    {menuDiscounts === "modifydiscount" &&
                        <ModifyDiscount infoUser={infoUser} />
                    }
                    {menuDiscounts === "listdiscounts" &&
                        <ListDiscounts infoUser={infoUser} />
                    }
                </Grid.Column>
            </Grid>
        </>
    )
}
