import React, { useState, useEffect } from 'react';
import { Header, Form, Message, Input, Button, Select, Divider } from "semantic-ui-react";
import produce from "immer";
import { isMobile } from "react-device-detect";
import axios from "axios";
import Global from "../../../../utils/Global";
import { getLocalStorage } from "../../../../utils/localStorage";
import ReactJson from 'react-json-view';
import "./CreatePlanilla.scss";

export default function CreatePlanilla(props) {

    const { infoUser } = props;

    const [doneMsg, setDoneMsg] = useState(false);
    const [isOK, setIsOK] = useState("messageUserData__fail");
    const [msg, setMsg] = useState("");
    const [loading, setLoading] = useState(false);
    const [PDFFile, setPDFFile] = useState(undefined);
    const [formError, setFormError] = useState({});
    const [formData, setFormData] = useState({
        "model": "",
        "module": "",
        "emptyData": ""
    });
    const [groupCreated, setGroupCreated] = useState(false);
    const [totalGroups, setTotalGroups] = useState(1);
    const [totalFields, setTotalFields] = useState(1);
    const [options, setOptions] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);

    //aux hooks
    const [titleAux, setTitleAux] = useState("");
    const [keyAux, setKeyAux] = useState("");
    const [typeAux, setTypeAux] = useState("");
    const [requiredAux, setRequiredAux] = useState("");
    const [functionAux, setFunctionAux] = useState("");

    const [savedValues, setSavedValues] = useState([]);
    const [actualEmptyValues, setActualEmptyValues] = useState({});

    const typeOptionsField = [
        { key: "text", value: 'text', text: 'Texto' },
        { key: "select", value: 'select', text: 'Select' },
        { key: "radio", value: 'radio', text: 'Radio' },
        { key: "checkbox", value: 'checkbox', text: 'Checkbox' }
    ];

    const requiredOptionsField = [
        { key: "true", value: true, text: 'True' },
        { key: "false", value: false, text: 'False' }
    ];

    const createGroup = () => {
        if(!groupCreated) {
            let auxActualEmptyValues = actualEmptyValues;
            auxActualEmptyValues["group"+totalGroups] = {
                "title": "",
                "field1": {
                    "key": "",
                    "values": [],
                    "required": false,
                    "function": ""
                }
            }
            setActualEmptyValues(auxActualEmptyValues);
            setGroupCreated(true);
        }
    }

    const closeGroup = () => {
        if(typeAux !== "" && requiredAux !== "" && keyAux !== "") {
            saveAndAddNewField();
        }
        let auxActualEmptyValues = actualEmptyValues;
        auxActualEmptyValues["group"+totalGroups]["title"] = titleAux; 
        setActualEmptyValues(auxActualEmptyValues);
        setTotalFields(0);
        setTotalGroups(totalGroups+1);
        setGroupCreated(false);
    }

    const saveAndAddNewField = () => {
        let auxActualEmptyValues = actualEmptyValues;
        auxActualEmptyValues["group"+totalGroups]["field"+totalFields] = {
            "key": keyAux,
            "values": selectedOptions,
            "required": requiredAux,
            "function": functionAux
        }
        setActualEmptyValues(auxActualEmptyValues);
        setOptions([]);
        setSelectedOptions([]);
        setKeyAux("");
        setTypeAux("");
        setRequiredAux("");
        setFunctionAux("");
        setTotalFields(totalFields+1);
    }

    const setFilePDF = e => {
        let xt = e.target.files[0].name.split('.').pop();
        if(xt !== "pdf") {
            alert("El archivo debe tener extensión .pdf");
            setFormError({ pathFile: true });
        }
        if(e.target.files[0].size < 10000000) {
            setPDFFile(e.target.files[0]);
        } else {
            alert("El archivo adjunto no debe superar los 10 Mb");
            setFormError({ pathFile: true });
        }
    }

    const handleAddition = (e, {value}) => {
        let auxOptions = options;
        auxOptions.push({ key: value, text: value, value: value });
        setOptions(auxOptions);
    }

    const saveData = () => {
        // if(!selectedRemedio) {
        //     setFormError({ remedio: "Este campo es obligatorio. "});
        // } else if(!selectedObraSocial) {
        //     setFormError({ obrasocial: "Este campo es obligatorio. "});
        // } else {
        //     setLoading(true);
        //     //TENEMOS QUE ENVIAR EL ID DE REMEDIO Y OBRA SOCIAL
        //     axios.post(Global.url + 'create-new-planilla', { formData: formData, remedio: selectedRemedio, obrasocial: selectedObraSocial }, { headers: { "authorization" : `Bearer ${getLocalStorage()}`} })
        //         .then(res => {
        //             setMsg(res.data.message);
        //             if(res.data.status) {
        //                 setIsOK("messageUserData__success");
        //                 setFormData({
        //                     "plan": "",
        //                     "discount": "",
        //                     "obs": ""
        //                 });
        //             } else {
        //                 setIsOK("messageUserData__fail");
        //             }
        //         }).catch(err => {
        //             setIsOK("messageUserData__fail");
        //             console.log(err);
        //         }).finally(() => {
        //             setDoneMsg(true);
        //             setLoading(false);
        //         });
        // }
    }

    return (
        <>
        <Header as='h3' className="headerUserPanel">
            Agregar nueva planilla
        </Header>
        <Form>
            <Form.Group widths='equal'>
                <Form.Input
                    onChange={e => setFormData({...formData, [e.target.name]: e.target.value})}
                    fluid label='Nombre del modelo de la planilla' 
                    name="module"
                    value={formData.module}
                    error={formError.module}
                    required
                    placeholder='Nombre del módulo para planillas'
                />
                <Form.Input
                    onChange={e => setFormData({...formData, [e.target.name]: e.target.value})}
                    fluid label='Módulo al que pertenece la planilla' 
                    name="model"
                    value={formData.model}
                    error={formError.model}
                    required
                    placeholder='Módulo al que pertenece la planilla'
                />
            </Form.Group>
            <Form.Field
                id='form-textarea-control-file'
                control={Input}
                label="Subir planilla en formato editable .PDF"
                name="pathFile"
                type="file"
                accept=".pdf"
                required
                error={formError.pathFile}
                onChange={e => setFilePDF(e)}
            />
            <Header as='h4' className="headerUserPanel">
                Crear formulario
            </Header>
            {!groupCreated &&
                <Button onClick={() => createGroup()}>Crear grupo de campos</Button>
            }
            {groupCreated &&
                <>
                    <Form.Input
                        fluid label={'Título del grupo número '+totalGroups} 
                        name="title"
                        value={titleAux}
                        error={formError.title}
                        required
                        placeholder={'Título del grupo número '+totalGroups}
                        onChange={e => setTitleAux(e.target.value)}
                    />
                    <Divider />
                    <Header as='h5' className="headerUserPanel">
                        {"Campo número "+totalFields} 
                    </Header>
                    <Form.Group widths='equal'>
                        <Form.Field 
                            control={Select}
                            options={typeOptionsField}
                            search
                            required
                            label={'Tipo del campo (número '+totalFields+')'} 
                            placeholder={'Tipo del campo (número '+totalFields+')'} 
                            name="type"
                            value={typeAux}
                            error={formError.type}
                            onChange={(e, { value }) => setTypeAux(value)}
                        />
                        <Form.Input
                            type="text"
                            fluid label={'Nombre del campo (key) (número '+totalFields+')'} 
                            name="key"
                            error={formError.key}
                            required
                            value={keyAux}
                            placeholder={'Nombre del campo (key) (número '+totalFields+')'} 
                            onChange={e => setKeyAux(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Dropdown
                        search
                        selection
                        fluid
                        multiple
                        allowAdditions
                        fluid label={'Valores posibles (número '+totalFields+')'} 
                        name="values"
                        options={options}
                        error={formError.values}
                        value={selectedOptions}
                        onAddItem={handleAddition}
                        onChange={(e, { value }) => setSelectedOptions(value)}
                        placeholder='Valores permitidos para responder'
                    />
                    <Form.Group widths='equal'>
                        <Form.Field 
                            control={Select}
                            options={requiredOptionsField}
                            search
                            required
                            label={'Requerido (número '+totalFields+')'} 
                            placeholder='True or false'
                            name="required"
                            value={requiredAux}
                            error={formError.required}
                            onChange={(e, { value }) => setRequiredAux(value)}
                        />
                        <Form.Input
                            type="text"
                            fluid label={'Función a llamar (número '+totalFields+')'}  
                            name="function"
                            value={functionAux}
                            error={formError.function}
                            placeholder='Función a llamar (si no posee, dejarlo en blanco)'
                            onChange={e => setFunctionAux(e.target.value)}
                        />
                    </Form.Group>
                    <Button onClick={() => saveAndAddNewField()}>Agregar nuevo campo</Button>
                    <Button onClick={() => closeGroup()}>Cerrar grupo de campos</Button>
                </>
            }
            <div className="bgCodeJson">
                <Header as='h4' className="headerUserPanel">
                    Resultado en formato JSON
                </Header>
                <ReactJson src={actualEmptyValues} />
            </div>
            {doneMsg && 
                <div className="messageDiv">
                    <Message className={isOK === "messageUserData__fail" ? "messageUserData messageUserData__fail" : "messageUserData messageUserData__success"}>
                        {msg}
                    </Message>
                </div>
            }
            <Form.Button loading={loading} disabled={loading} onClick={() => saveData()} className="btnSubmit btnChangePersonalDate">Guardar modelo de planilla</Form.Button>
        </Form>
        </>
    )
}
