function saniziteNumbers(value) {
    if(value !== undefined) {
        var parts = value.toString();
        parts = parts.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        return parts;
    } else {
        return value;
    }
}

function GenerateQRCode(infoUser, dataDoctorValuesOptions, dataNames) {
    let sexDr = "Dr. ";
    if(infoUser.sex === "F") {
        sexDr = "Dra. ";
    }
    let infoInQR = sexDr+infoUser.name+" "+infoUser.lastname+"\n";
    for(let i in infoUser.qrcode) {
        if(infoUser.qrcode[i] === "dataDoctorValues") {
            for(let k in infoUser.qrcode[i]) {
                infoInQR += dataDoctorValuesOptions[infoUser.country][k]+": "+infoUser["dataDoctorValues"][k]
            }
        }
        if(infoUser.qrcode[i] !== "name" && infoUser.qrcode[i] !== "lastname") {
            infoInQR += dataNames[infoUser.qrcode[i]]+": "+infoUser[infoUser.qrcode[i]]+"\n";
        }
    }
    return infoInQR;
}

export { saniziteNumbers, GenerateQRCode };
