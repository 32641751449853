import React, { useState, useEffect } from 'react';

import { Header, Grid, Icon, Popup, Button, Message } from "semantic-ui-react";

import axios from "axios";
import Global from "../../../../utils/Global";
import { getLocalStorage } from '../../../../utils/localStorage';

import { isMobile } from "react-device-detect";

import QRCode from "qrcode.react";

export default function Privacity(props) {

    const { infoUser, selectedUser, setSelectedUser } = props;

    const [selectedElement, setSelectedElement] = useState(undefined);
    const [direction, setDirection] = useState(false);
    const [elementsToShow, setElementsToShow] = useState([]);
    const [elementsNotToShow, setElementsNotToShow] = useState([]);

    const [loading, setLoading] = useState(false);

    const [doneMsg, setDoneMsg] = useState(false);
    const [isOK, setIsOK] = useState("messageUserData__fail");
    const [msg, setMsg] = useState("");

    const [valueQRCode, setValueQRCode] = useState("");

    const leftArrow = isMobile ? "arrow up" : "arrow left";
    const rightArrow = isMobile ? "arrow down" : "arrow right";

    const notShow = ["__v", "_id", "qrcode", "sex", "signature", "email", "user", "password", "created", "active", "role", "state", "dni", "country", "modified", "totalPatients", "donePatients", "newRecetas", "duplicatedRecetas", "subscriber", "renovation", "typeSuscription", "timeSuscription", "endDateSuscription"];

    const dataNames = {
        "name": "Nombre",
        "lastname": "Apellido",
        "state": "Provincia",
        "address": "Dirección",
        "phone": "Teléfono",
        "cellphone": "Celular",
        "emailJob": "Email",
        "matriculaNacional": "Matrícula Nacional (MN)",
        "matriculaProvincial": "Matrícula Provincial (MP)",
        "speciality": "Especialidad",
        "zipcode": "Código Postal"
    };

    useEffect(() => {
        let auxArrayShow = [];
        let auxArrayNotShow = [];
        for(let i in selectedUser.qrcode) {
            auxArrayShow.push(selectedUser.qrcode[i]);
        }
        for(let k in selectedUser) {
            if(!notShow.includes(k)) {
                if (auxArrayShow.indexOf(k) === -1) {
                    auxArrayNotShow.push(k);
                }
            }
        }
        setElementsToShow(auxArrayShow);
        setElementsNotToShow(auxArrayNotShow);
    }, []);

    const moveSelectedElement = () => {
        if(selectedElement) {
            let auxArrayNotShow = [];
            let auxArrayShow = [];
            let auxArray_1 = elementsToShow;
            let auxArray_2 = elementsNotToShow;
            if(direction) {
                auxArrayNotShow = elementsNotToShow.filter(item => item !== selectedElement);
                auxArray_1.push(selectedElement);
                setElementsToShow(auxArray_1);
                setElementsNotToShow(auxArrayNotShow);
            } else {
                auxArrayShow = elementsToShow.filter(item => item !== selectedElement);
                auxArray_2.push(selectedElement);
                setElementsToShow(auxArrayShow);
                setElementsNotToShow(auxArray_2);
            }
        }
        setSelectedElement(undefined);
    }

    const selectElement = (element, direction) => {
        setSelectedElement(element);
        setDirection(direction);
    }

    const saveQRCode = () => {
        if(!elementsToShow.includes("name") || !elementsToShow.includes("lastname")) {
            setDoneMsg(true);
            setMsg("El nombre y apellido deben estar en el código QR. ");
        } else if(!elementsToShow.includes("matriculaNacional") && selectedUser.state === 0) {
            setDoneMsg(true);
            setMsg("La Matrícula Nacional (MN) para los trabajadores en C.A.B.A. es obligatoria. ");
        } else if(!elementsToShow.includes("matriculaProvincial") && selectedUser.state !== 0) {
            setDoneMsg(true);
            setMsg("La Matrícula Provincial (MP) para los trabajadores fuera de C.A.B.A. es obligatoria. ");
        } else {
            setLoading(true);
            axios.post(Global.url + 'change-qr-doctor', { elementsToShow: elementsToShow, selectedUser: selectedUser }, { headers: { "authorization" : `Bearer ${getLocalStorage()}`} })
                .then(res => {
                    setIsOK(res.data.result);
                    setMsg("¡Cambios guardados!");
                    setSelectedUser({...selectedUser, qrcode: elementsToShow });
                    GenerateQRCode();
                }).catch(err => {
                    setIsOK("messageUserData__fail");
                    setMsg("Hubo un error interno en el servidor. Por favor, intente más tarde. ");
                }).finally(() => {
                    setDoneMsg(true);
                    setLoading(false);
                })
        }
    }

    const GenerateQRCode = () => {
        let sexDr = "Dr. ";
        if(selectedUser.sex === "F") {
            sexDr = "Dra. ";
        }
        let infoInQR = sexDr+selectedUser.name+" "+selectedUser.lastname+"\n";
        for(let i in selectedUser.qrcode) {
            if(selectedUser.qrcode[i] !== "name" && selectedUser.qrcode[i] !== "lastname") {
                infoInQR += dataNames[selectedUser.qrcode[i]]+": "+selectedUser[selectedUser.qrcode[i]]+"\n";
            }
        }
        setValueQRCode(infoInQR);
    }
    
    return (
        <div>
            <Header as="h3">Código QR</Header>
            <Header as="h4">Seleccione los datos que querés mostrar en el código QR de tus recetas. El nombre, apellido y la matrícula son obligatorios. </Header>
            <Header as="h5">Para cambiar de dirección, seleccione la opción que deseas que se muestre y luego hacer click a la flecha correspondiente. </Header>
            <Grid className="gridQRCode">
                <Grid.Column width={isMobile ? 16 : 6}>
                    <div className="titleQRCode">Mostrar en el código QR</div>
                    <div className="listElementsQrCode" id="listElementsShow">
                        {elementsToShow.map((element) => (
                            <ElementInDivToShow key={element} element={element} direction={false} infoUser={selectedUser} dataNames={dataNames} selectedElement={selectedElement} selectElement={selectElement} />
                        ))}
                    </div>
                </Grid.Column>
                <Grid.Column width={isMobile ? 16 : 4} className="arrowColumn">
                    <div className="iconArrow">
                        <Popup
                            trigger={<Icon name={direction ? leftArrow : rightArrow} onClick={() => moveSelectedElement()} />}
                            content='Mover elemento'
                            inverted
                            position='bottom center'
                        />
                    </div>
                </Grid.Column>
                <Grid.Column width={isMobile ? 16 : 6}>
                    <div className="titleQRCode">No mostrar en el código QR</div>
                    <div className="listElementsQrCode" id="listElementsNotShow">
                        {elementsNotToShow.map((element) => (
                            <div key={element} className="selectListElement" className={selectedElement === element ? "selectListElement selectListElement__active" : "selectListElement"} onClick={() => selectElement(element, true)}>{dataNames[element]}</div>
                        ))}
                    </div>
                </Grid.Column>
            </Grid>
            <div className="divButtonQRCode">
                <Button loading={loading} disabled={loading} name="saveQRCode" className="saveQRCode" onClick={() => saveQRCode()}>Guardar cambios</Button>
            </div>
            {doneMsg && 
                <>
                    <div className="messageDiv">
                        <Message className={isOK === "messageUserData__fail" ? "messageUserData messageUserData__fail" : "messageUserData messageUserData__success"}>
                            {msg}
                        </Message>
                    </div>
                    <div className="messageDiv">
                        Podés comprobarlo usando la cámara de tu celular    
                    </div>
                    <div className="messageDiv">
                    <QRCode
                        value={valueQRCode}
                        size={128}
                        bgColor={"#ffffff"}
                        fgColor={"#000000"}
                        level={"H"}
                        includeMargin={false}
                        renderAs={"svg"}
                        // imageSettings={{
                        //     src: "https://static.zpao.com/favicon.png",
                        //     x: null,
                        //     y: null,
                        //     height: 24,
                        //     width: 24,
                        //     excavate: true,
                        // }}
                    />
                    </div>
                </>
            }
        </div>
    )
}


function ElementInDivToShow(props) {
    const { element, direction, infoUser, dataNames, selectedElement, selectElement } = props;
    if(element === "name" || element === "lastname") {
        return (
            <div className="selectListElement" className="selectListElement">{dataNames[element]}</div>
        )
    } else if(infoUser.state === 0 && element === "matriculaNacional") {
        return (
            <div className="selectListElement" className="selectListElement">{dataNames[element]}</div>
        )
    } else if(infoUser.state !== 0 && element === "matriculaProvincial") {
        return (
            <div className="selectListElement" className="selectListElement">{dataNames[element]}</div>
        )
    } else {
        return (
            <div className="selectListElement" className={selectedElement === element ? "selectListElement selectListElement__active" : "selectListElement"} onClick={() => selectElement(element, direction)}>{dataNames[element]}</div>
        )
    }
}
