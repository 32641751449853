import React, { useState, useEffect } from 'react';
import { Form, Header, Message, Button, Input, Radio, Icon } from "semantic-ui-react";
import "./CreateReceta.scss";
import moment from "moment";
import axios from "axios";
import Global from "../../../../utils/Global";
import PhoneInput from 'react-phone-input-2';
import es from 'react-phone-input-2/lang/es.json';
import { getLocalStorage } from '../../../../utils/localStorage';
import { isMobile } from "react-device-detect";
import { validateEmail } from "../../../../utils/Validations";
import { saniziteNumbers, GenerateQRCode } from "../../../../utils/saniziteValues";
import { dataDoctorValuesOptions } from "../../../../utils/ValuesOptions";
import ModalComponent from "../../../ModalComponent/ModalComponent";
import ModalPrevisualizacion from '../ModalPrevisualizacion/ModalPrevisualizacion';

export default function CreateReceta(props) {

    const { infoUser, setInfoUser } = props;

    const [allOk, setAllOk] = useState(true);
    const [loadingSaveReceta, setLoadingSaveReceta] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingPDF, setLoadingPDF] = useState(false);
    const [loadingAction, setLoadingAction] = useState(false);
    const [loadingSend, setLoadingSend] = useState(false);
    const [loadingShareMobile, setLoadingShareMobile] = useState(false);

    const [howShare, setHowShare] = useState(false);
    const [infoPatientToShare, setInfoPatientToShare] = useState(false);
    
    useEffect(() => { //REVISAR ESO (matriculas mal hechas)
        if(infoUser?.name === null || infoUser?.lastname === null || infoUser?.name === "" || infoUser?.lastname === "") {
            setAllOk(false);
        }
        if((infoUser?.state === 0 && infoUser?.matriculaNacional === null) || (infoUser?.state !== 0 && infoUser?.matriculaProvincial === null)) {
            setAllOk(false);
        }
        if((infoUser?.state === 0 && infoUser?.matriculaNacional === "") || (infoUser?.state !== 0 && infoUser?.matriculaProvincial === "")) {
            setAllOk(false);
        }
        if(infoUser?.signature === null || infoUser?.signature === false || infoUser?.signature === "") {
            setAllOk(false);
        }
    }, [infoUser]);


    const [totalRecets, setTotalRecets] = useState(undefined);
    
    const [sentRecet, setSentRecet] = useState(false);
    const [msgSent, setMsgSent] = useState("");
    const [isOKSent, setIsOKSent] = useState("messageUserData__fail");

    const [resultRes, setResultRes] = useState(false);
    const [doneMsg, setDoneMsg] = useState(false);
    const [isOK, setIsOK] = useState("messageUserData__fail");
    const [msg, setMsg] = useState("");

    const [openModal, setOpenModal] = useState(false);
    const [openModalPharmacy, setOpenModalPharmacy] = useState(false);
    const [contentModalPharmacy, setContentModalPharmacy] = useState(undefined);
    const [emailToSend, setEmailToSend] = useState("");
    const [formErrorPharmacy, setFormErrorPharmacy] = useState(false);
    const [ifErrorSend, setIfErrorSend] = useState(false);
    const [msgSentPharmacy, setMsgSentPharmacy] = useState(false);
    const [isOKSentPharmacy, setIsOKSentPharmacy] = useState(false);

    const [resultsSearchCie10, setResultsSearchCie10] = useState([]);
    const [searchValueCie10, setSearchValueCie10] = useState("");
    const [searchingCie10, setSearchingCie10] = useState(false);
    const [gotValuesCie10, setGotValuesCie10] = useState(false);
    const [selectedCie10, setSelectedCie10] = useState(null);

    // search remedios
    const [resultsSearchRemedio1, setResultsSearchRemedio1] = useState([]);
    const [searchValueRemedio1, setSearchValueRemedio1] = useState("");
    const [searchingRemedio1, setSearchingRemedio1] = useState(false);
    const [gotValuesRemedio1, setGotValuesRemedio1] = useState(false);
    const [selectedRemedio1, setSelectedRemedio1] = useState(null);
    const [resultsSearchRemedio2, setResultsSearchRemedio2] = useState([]);
    const [searchValueRemedio2, setSearchValueRemedio2] = useState("");
    const [searchingRemedio2, setSearchingRemedio2] = useState(false);
    const [gotValuesRemedio2, setGotValuesRemedio2] = useState(false);
    const [selectedRemedio2, setSelectedRemedio2] = useState(null);

    const [resultsSearch, setResultsSearch] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [searching, setSearching] = useState(false);
    const [gotValues, setGotValues] = useState(false);
    const [selectedPatient, setSelectedPatient] = useState(null);

    const [recetaCreated, setRecetaCreated] = useState([]);
    const [emailPatient, setEmailPatient] = useState("");
    const [cellPatient, setCellPatient] = useState("");

    const [formError, setFormError] = useState({});
    const [formData, setFormData] = useState({
        "remedio1": "",
        "remedio2": "",
        "duration": 30,
        "duplicated": false
    });

    const [valueQRCode, setValueQRCode] = useState("");
    const [signature, setSignature] = useState(undefined);

    const dataNames = {
        "name": "Nombre",
        "lastname": "Apellido",
        "state": "Provincia",
        "address": "Dirección",
        "phone": "Teléfono",
        "cellphone": "Celular",
        "emailJob": "Email",
        "dataDoctorValues": dataDoctorValuesOptions[infoUser.country],
        "speciality": "Especialidad",
        "zipcode": "Código Postal"
    };

    const searchPatient = value => {
        setSearchValue(value);
        setSearching(true);
        setGotValues(false);
        if(value.length >= 2) {
            setTimeout(function() {
                axios.post(Global.url + 'search-patient', { search: value }, { headers: { "authorization" : `Bearer ${getLocalStorage()}`} })
                    .then(res => {
                        setResultsSearch(res.data.results);
                    }).catch(err => {
                        setResultsSearch([]);
                    }).finally(() => {
                        setSearching(false);
                        setGotValues(true);
                });
            }, 300);
        } else {
            setSearching(true);
            setGotValues(false);
        }
    }

    const searchCie10 = value => {
        setSearchValueCie10(value);
        if((value.length > 1 && !isNaN(value.charAt(1))) || (value.length > 3)) {
            setSearchingCie10(true);
            setGotValuesCie10(false);
            setTimeout(function() {
                axios.post(Global.url + 'load-cie10', { search: value }, { headers: { "authorization" : `Bearer ${getLocalStorage()}`} })
                    .then(res => {
                        setResultsSearchCie10(res.data.results);
                    }).catch(err => {
                        setResultsSearchCie10([]);
                    }).finally(() => {
                        setSearchingCie10(false);
                        setGotValuesCie10(true);
                });
            }, 300);
        } else {
            setSearchingCie10(false);
            setGotValuesCie10(false);
        }
    }

    const searchRemedio = (value, number) => {
        number === 1 ? setSearchValueRemedio1(value) : setSearchValueRemedio2(value)
        if(value.length > 3) {
            number === 1 ? setSearchingRemedio1(true) : setSearchingRemedio2(true)
            number === 1 ? setGotValuesRemedio1(true) : setGotValuesRemedio2(true)
            setTimeout(function() {
                axios.post(Global.url + 'load-vademecum', { search: value }, { headers: { "authorization" : `Bearer ${getLocalStorage()}`} })
                    .then(res => {
                        number === 1 ? setResultsSearchRemedio1(res.data.results) : setResultsSearchRemedio2(res.data.results)
                    }).catch(err => {
                        number === 1 ? setResultsSearchRemedio1([]) : setResultsSearchRemedio2([])
                    }).finally(() => {
                        number === 1 ? setSearchingRemedio1(false) : setSearchingRemedio2(false)
                        number === 1 ? setGotValuesRemedio1(true) : setGotValuesRemedio2(true)
                });
            }, 300);
        } else {
            number === 1 ? setSearchingRemedio1(false) : setSearchingRemedio2(false)
            number === 1 ? setGotValuesRemedio1(false) : setGotValuesRemedio2(false)
        }
    }

    const selectCie10 = item => {
        setSearchValueCie10("");
        setSelectedCie10(item);
        setGotValuesCie10(false);
        setResultsSearchCie10([]);
    }

    const selectPatient = item => {
        setSearchValue("");
        setSelectedPatient(item);
        setGotValues(false);
        setResultsSearch([]);
        setDoneMsg(false);
        setSentRecet(false);
        setIsOK("messageUserData__fail");
        setResultRes(false);
    }

    const selectRemedio = (item, number) => {
        if(number === 1) {
            setSearchValueRemedio1("");
            setSelectedRemedio1(item);
            setGotValuesRemedio1(false);
            setResultsSearchRemedio1([]);
        } else {
            setSearchValueRemedio2("");
            setSelectedRemedio2(item);
            setGotValuesRemedio2(false);
            setResultsSearchRemedio2([]);
        }
        setDoneMsg(false);
        setSentRecet(false);
        setIsOK("messageUserData__fail");
        setResultRes(false);
    }

    const previsualizeReceta = () => {
        if(!selectedPatient) {
            setFormError({ patient: "El campo paciente es obligatorio. "});
        } else if (!selectedCie10) {
            setFormError({ diagnostic: "Es obligatorio declarar el diagnóstico. "});
        } else if(!formData.remedio1) {
            setFormError({ remedio1: "El remedio 1 es obligatorio. "});
        } else {
            setLoading(true);
            axios.post(Global.url + 'get-signature', { mobile: false }, { headers: { "authorization" : `Bearer ${getLocalStorage()}`}, responseType: 'blob' })
            .then(res => {
                var imageUrl = URL.createObjectURL(res.data);
                setSignature(imageUrl);
                setValueQRCode(GenerateQRCode(infoUser, dataDoctorValuesOptions, dataNames));
                setOpenModal(true);
            }).catch(err => {
                setMsgSent("Debes tener la firma. Para hacer una firma digital, por favor diríjase a Cuenta -> Datos Personales");
                setIsOKSent("messageUserData__fail");
                setSentRecet(true);
                console.log(err);
            }).finally(() => {
                setLoading(false);
            });
        }
    }

    const saveReceta = () => {
        setLoadingSaveReceta(true);
        axios.post(Global.url + 'save-receta', { formData: formData, patient: selectedPatient, cie10: selectedCie10, infoUser: infoUser }, { headers: { "authorization" : `Bearer ${getLocalStorage()}`} })
            .then(res => {
                if(res.data.status) {
                    setInfoUser(prevValue => ({...prevValue, "limitUser": { ...prevValue["limitUser"], "max_recets": infoUser["limitUser"]["max_recets"] + res.data.total } }));
                    setTotalRecets(res.data.total);
                    setRecetaCreated(res.data.receta);
                    setEmailPatient(res.data.receta[0].patient.email);
                    setCellPatient(res.data.receta[0].patient.cellphone);
                    setIsOK("messageUserData__success");
                    setMsg(res.data.message);
                    setFormData({
                        "remedio1": "",
                        "remedio2": "",
                        "duration": 30,
                        "duplicated": false
                    });
                    setGotValues(false);
                    setGotValuesCie10(false);
                    setSelectedCie10(null);
                    setSelectedPatient(null);
                    setOpenModal(false);
                } else {
                    setIsOK("messageUserData__fail");
                    setMsg(res.data.message);
                    setOpenModal(false);
                }
                setDoneMsg(true);
            }).catch(err => {
                setIsOK("messageUserData__fail");
                setMsg("Hubo un error interno en el servidor. Por favor, intente más tarde. ");
                console.log(err);
            }).finally(() => {
                setResultRes(true);
                setLoadingSaveReceta(false);
            });
    }

    const GeneratePDF = (toDo, share = false, howShare = false) => {
        if(toDo) {
            setLoadingAction(true);
        } else {
            setLoadingPDF(true);
        }
        axios.post(Global.url + 'generate-pdf', { recetaCreated: recetaCreated, cellphone: cellPatient, email: emailPatient, toDo: toDo, howShare: howShare, enableQr: infoUser.enableQrCode }, { headers: { "authorization" : `Bearer ${getLocalStorage()}`}, responseType: toDo === true ? 'json' : 'blob' })
            .then(res => {
                if(!toDo) {
                    if(share) {
                        try {
                            let shareDataFile = new File([res.data], `Receta__${recetaCreated[0].patient._id}__${moment().format("DD-MM-YYYY")}.pdf`, {lastModified: 1534584790000}, {type: res.data.type});
                            let shareData = { files: [shareDataFile] };
                            if(navigator.canShare && navigator.canShare(shareData)) {
                                shareData.title = "RECETA";
                                navigator.share(shareData).then(() => {
                                    console.log("OK")
                                }).catch(error => {
                                    console.log(error);
                                });
                            } else {
                                console.log("Your system doesn't support sharing files.");
                            }
                        } catch(error) {
                            console.log(error);
                        }
                    } else {
                        const url = window.URL.createObjectURL(res.data);
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', `Receta__${recetaCreated[0].patient._id}__${moment().format("DD-MM-YYYY")}.pdf`);
                        document.body.appendChild(link);
                        link.click();
                        DeletePDF(null);
                    }
                } else {
                    if(res.data.status) {
                        setIsOKSent("messageUserData__success");
                        DeletePDF(res.data.namesRecets);
                    } else {
                        setIsOKSent("messageUserData__fail");
                    }
                    setMsgSent(res.data.message);
                }
            }).catch(err => {
                setIsOKSent("messageUserData__fail");
                setMsgSent("Hubo un error interno en el servidor, por favor intente más tarde. ");
            }).finally(() => {
                setSentRecet(true);
                if(toDo) {
                    setLoadingAction(false);
                } else {
                    setLoadingPDF(false);
                }
            });
    }

    const activateSharePatient = how => {
        setInfoPatientToShare(true);
        setHowShare(how);
    }

    const OpenModalFarmacia = () => {
        setContentModalPharmacy(<ContentModalPharmacy isOKSentPharmacy={isOKSentPharmacy} formErrorPharmacy={formErrorPharmacy} setEmailToSend={setEmailToSend} msgSentPharmacy={msgSentPharmacy} isOKSentPharmacy={isOKSentPharmacy} ifErrorSend={ifErrorSend} />)
        setOpenModalPharmacy(true);
    }

    // const SendRecetToPharmacy = () => { // NO LO USAMOS POR AHORA
    //     if(!validateEmail(emailToSend)) {
    //         setFormErrorPharmacy(true);
    //         setIsOKSentPharmacy("messageUserData__fail");
    //         setMsgSentPharmacy("El email introducido no es correcto. ");
    //         setIfErrorSend(true);
    //     } else {
    //         setLoadingSend(true);
    //         axios.post(Global.url + 'send-pdf-pharmacy', { recetaCreated, emailToSend: emailToSend, enableQr: infoUser.enableQrCode }, { headers: { "authorization" : `Bearer ${getLocalStorage()}`} })
    //         .then(res => {
    //             if(res.data.status) {
    //                 setIsOKSent("messageUserData__success");
    //                 setMsgSent(res.data.message);
    //                 setIfErrorSend(false);
    //                 setOpenModalPharmacy(false);
    //             } else {
    //                 setIsOKSentPharmacy("messageUserData__fail");
    //                 setMsgSentPharmacy(res.data.message);
    //             }
    //         }).catch(err => {
    //             setIsOKSentPharmacy("messageUserData__fail");
    //             setMsgSentPharmacy("Hubo un error interno en el servidor, por favor intente más tarde. ");
    //         }).finally(() => {
    //             setLoadingSend(false);
    //             DeletePDF();
    //         });
    //     }  
    // }

    const DeletePDF = value => { 
        axios.post(Global.url + 'delete-pdf', { value: value }, { headers: { "authorization" : `Bearer ${getLocalStorage()}`} })
            .then(() => {
                // nothing to do
            }).catch(err => {
                setIsOKSent("messageUserData__fail");
                console.log(err);
        });
    }

    useEffect(() => {
        setContentModalPharmacy(<ContentModalPharmacy isOKSentPharmacy={isOKSentPharmacy} formErrorPharmacy={formErrorPharmacy} setEmailToSend={setEmailToSend} msgSentPharmacy={msgSentPharmacy} isOKSentPharmacy={isOKSentPharmacy} ifErrorSend={ifErrorSend} />)
    }, [ifErrorSend, formErrorPharmacy, msgSentPharmacy]);

    return (
        <>
        <Header as='h3' className="headerUserPanel">
            Crear nueva receta
        </Header>
        {!allOk &&
            <Message error className="textLeftMessage">
                <Message.Header>Bloqueo de recetas</Message.Header>
                <Message.Content className="contentMessage">
                    No podés crear recetas porque faltan datos necesarios de la cuenta para la creación de recetas. Por favor dirigir a <strong>Datos Personales</strong> en la solapa <strong>Cuenta</strong> para completar los datos faltantes.
                </Message.Content>
            </Message>
        }
        <Form>
            <Form.Input
                onChange={e => searchPatient(e.target.value)}
                fluid label='Paciente' 
                name="patient"
                disabled={allOk ? false : true}
                value={searchValue}
                error={formError.patient}
                icon={searching ? "circle notch" : "search"}
                placeholder='Buscar paciente (nombre, apellido o DNI)'
            />
            {gotValues &&
                <div className="listValuesFound listValuesFound__Patients">
                    {resultsSearch.length === 0 && 
                        <div className="aValueFound">No se encontró ningún paciente que coincida. </div>
                    }
                    {resultsSearch.map((item) => (
                        <div 
                            onClick={() => selectPatient(item)} 
                            className="aValueFound"
                            key={item._id}
                        >
                            {item.name} {item.secondName} {item.lastname} {item.secondLastname} - <strong>{item.typeDNI}</strong>: {saniziteNumbers(item.dni)} - <strong>Obra social</strong>: {item.obrasocial}
                        </div>
                    ))}
                </div>
            }
            {selectedPatient !== null &&
                <div className="infoSelectedPatient">
                    <span className="textSelectedPatient"><strong>Paciente:</strong> <span>{selectedPatient.name} {selectedPatient.secondName} {selectedPatient.lastname} {selectedPatient.secondLastname}</span></span>
                    <span className="textSelectedPatient"><strong>Documento:</strong> <span>{selectedPatient.typeDNI} {saniziteNumbers(selectedPatient.dni)}</span></span>
                    <span className="textSelectedPatient"><strong>Obra social:</strong> <span>{selectedPatient.obrasocial} - {selectedPatient.nro}</span></span>
                </div>
            }
                <Form.Input
                    onChange={e => searchCie10(e.target.value)}
                    fluid label='Diagnóstico CIE10' 
                    name="diagnostic"
                    disabled={allOk ? false : true}
                    value={searchValueCie10}
                    error={formError.diagnostic}
                    icon={searchingCie10 ? "circle notch" : "search"}
                    placeholder='Buscar código CIE10 o diagnóstico'
                />
                {gotValuesCie10 &&
                    <div className="listValuesFound listValuesFound__Cie10">
                        {resultsSearchCie10.length === 0 && 
                            <div className="aValueFound">No se encontró ningún diagnóstico CIE10 que coincida. </div>
                        }
                        {resultsSearchCie10.map((item) => (
                            <div 
                                onClick={() => selectCie10(item)} 
                                className="aValueFound"
                                key={item._id}
                            >
                                {item.cie10} - {item.diagnostic}
                            </div>
                        ))}
                    </div>
                }
                {selectedCie10 !== null &&
                    <div className="infoSelectedCie10">
                        <span className="textSelectedCie10"><strong>Diagnóstico CIE10:</strong> <span>{selectedCie10.cie10} - {selectedCie10.diagnostic}</span></span>
                    </div>
                } 
                <Form.Group widths='equal'>
                    <Form.TextArea 
                        label='Medicamento 1' 
                        name="remedio1"
                        disabled={allOk ? false : true}
                        error={formError.remedio1}
                        value={formData.remedio1}
                        placeholder='Detalles del medicamento 1'
                        onChange={e => setFormData({...formData, [e.target.name]: e.target.value}) }
                    />
                </Form.Group>
                {/* <Form.Input
                    onChange={e => searchRemedio(e.target.value, 1)}
                    fluid label='Medicamento 1' 
                    name="remedio1"
                    disabled={allOk ? false : true}
                    value={searchValueRemedio1}
                    error={formError.remedio1}
                    icon={searchingRemedio1 ? "circle notch" : "search"}
                    placeholder='Buscar medicamento 1 (nombre genérico o nombre comercial)'
                />
                {gotValuesRemedio1 &&
                    <div className="listValuesFound listValuesFound__Patients">
                        {resultsSearchRemedio1.length === 0 && 
                            <div className="aValueFound">No se encontró ningún medicamento que coincida. </div>
                        }
                        {resultsSearchRemedio1.map((item) => (
                            <div 
                                onClick={() => selectRemedio(item, 1)} 
                                className="aValueFound"
                                key={item._id}
                            >
                                {item.name} {item.secondName} {item.lastname} {item.secondLastname} - <strong>DNI</strong>: {item.dni} - <strong>Obra social</strong>: {item.obrasocial}
                            </div>
                        ))}
                    </div>
                }
                {selectedRemedio1 !== null &&
                    <div className="infoSelectedPatient">
                        <span className="textSelectedPatient"><strong>Paciente:</strong> <span>{selectedPatient.name} {selectedPatient.secondName} {selectedPatient.lastname} {selectedPatient.secondLastname}</span></span>
                        <span className="textSelectedPatient"><strong>Documento:</strong> <span>{selectedPatient.typeDNI} {selectedPatient.dni}</span></span>
                        <span className="textSelectedPatient"><strong>Obra social:</strong> <span>{selectedPatient.obrasocial} - {selectedPatient.nro}</span></span>
                    </div>
                } */}
            <Form.Group widths='equal'>
                <Form.TextArea 
                    label='Medicamento 2 (Opcional)' 
                    name="remedio2"
                    disabled={allOk ? false : true}
                    value={formData.remedio2}
                    placeholder='Detalles del medicamento 2'
                    onChange={e => setFormData({...formData, [e.target.name]: e.target.value}) }
                />
            </Form.Group>
                {/* <Form.Input
                    onChange={e => searchRemedio(e.target.value, 2)}
                    fluid label='Medicamento 2' 
                    name="remedio2"
                    disabled={allOk ? false : true}
                    value={searchValueRemedio2}
                    error={formError.remedio2}
                    icon={searchingRemedio2 ? "circle notch" : "search"}
                    placeholder='Buscar medicamento 2 (nombre genérico o nombre comercial)'
                />
                {gotValuesRemedio2 &&
                    <div className="listValuesFound listValuesFound__Patients">
                        {resultsSearchRemedio2.length === 0 && 
                            <div className="aValueFound">No se encontró ningún medicamento que coincida. </div>
                        }
                        {resultsSearchRemedio2.map((item) => (
                            <div 
                                onClick={() => selectRemedio(item, 1)} 
                                className="aValueFound"
                                key={item._id}
                            >
                                {item.name} {item.secondName} {item.lastname} {item.secondLastname} - <strong>DNI</strong>: {item.dni} - <strong>Obra social</strong>: {item.obrasocial}
                            </div>
                        ))}
                    </div>
                }
                {selectedRemedio2 !== null &&
                    <div className="infoSelectedPatient">
                        <span className="textSelectedPatient"><strong>Paciente:</strong> <span>{selectedPatient.name} {selectedPatient.secondName} {selectedPatient.lastname} {selectedPatient.secondLastname}</span></span>
                        <span className="textSelectedPatient"><strong>Documento:</strong> <span>{selectedPatient.typeDNI} {selectedPatient.dni}</span></span>
                        <span className="textSelectedPatient"><strong>Obra social:</strong> <span>{selectedPatient.obrasocial} - {selectedPatient.nro}</span></span>
                    </div>
                } */}
            <Form.Field>
                <Header as='h5'>Tiempo de validez de la receta</Header>
            </Form.Field>
            <div className="durationDiv">
                <Form.Field>
                    <Radio
                        className="durationRadio"
                        label='1 mes'
                        name='duration'
                        disabled={allOk ? false : true}
                        value={30}
                        checked={formData.duration === 30}
                        onChange={(e, {value}) => setFormData({...formData, duration: value})}
                    />
                </Form.Field>
                <Form.Field>
                    <Radio
                        className="durationRadio"
                        label='2 meses'
                        name='duration'
                        disabled={allOk ? false : true}
                        value={60}
                        checked={formData.duration === 60}
                        onChange={(e, {value}) => setFormData({...formData, duration: value})}
                    />
                </Form.Field>
                <Form.Field>
                    <Radio
                        className="durationRadio"
                        label='3 meses'
                        name='duration'
                        disabled={allOk ? false : true}
                        value={90}
                        checked={formData.duration === 90}
                        onChange={(e, {value}) => setFormData({...formData, duration: value})}
                    />
                </Form.Field>
            </div>
            {resultRes && 
                <div className="messageDiv">
                    <Message className={isOK === "messageUserData__fail" ? "messageUserData messageUserData__fail" : "messageUserData messageUserData__success"}>
                        {msg}
                    </Message>
                </div>
            }
            {isOK === "messageUserData__fail" &&
                allOk &&
                    <Form.Button loading={loading} disabled={loading} onClick={() => previsualizeReceta()} className="btnSubmit btnChangePersonalDate">Previsualizar receta</Form.Button>
            }
            {doneMsg && isOK === "messageUserData__success" &&
                <>
                    <div className="buttonsPDFGenerate">
                        <Button name="downloadPDF" loading={loadingPDF} disabled={loadingPDF} className="downloadPDF" onClick={() => GeneratePDF(false, false)}>Descargar {totalRecets === 1 ? "receta" : "1º receta"}</Button>
                        <Button name="sharePDF" className="sharePDF" onClick={() => activateSharePatient(false)}>Enviar {totalRecets === 1 ? "receta" : "recetas"} por email</Button>
                        {/*<Button name="shareWhatsApp" className="shareWhatsApp" onClick={() => activateSharePatient(true)}>Enviar {totalRecets === 1 ? "receta" : "recetas"} por WhatsApp <Icon name="whatsapp" /></Button>
                        <Button name="sendPDF" loading={loadingSend} disabled={loadingSend} className="sendPDF" onClick={() => OpenModalFarmacia()}>Enviar receta a la farmacia</Button>
                        {isMobile && 
                            <Button name="sharePDFMobile" loading={loadingShareMobile} disabled={loadingShareMobile} className="sharePDFMobile" onClick={() => GeneratePDF(false, true)}>Compartir receta</Button>
                        } */}
                        {totalRecets !== 1 &&
                            <Message info>
                                <Message.Content>Si necesitás descargar o enviar las otras recetas generadas, debes ir a Compartir Receta y seleccionar la receta correspondiente. </Message.Content>
                            </Message>
                        }
                    </div>
                    {infoPatientToShare &&
                        <div className="dataToShare">
                            {howShare &&
                                <Form.Field>
                                    <div className="labelFormPhone">Número de celular a enviar por WhatsApp</div>
                                    <PhoneInput
                                        placeholder='Número de celular a enviar por WhatsApp'
                                        inputClass="inputStylePhone howShareInput"
                                        country='ar'
                                        preferredCountries={['ar', 'uy', 'cl', 'br', 'py', 'bo', 've', 'co', 'us', 'es', 'ec', 'pe', 'mx']}
                                        name="cellphone"
                                        localization={es}
                                        inputProps={{
                                            name: 'cellphone',
                                            required: true
                                        }}
                                        value={cellPatient}
                                        error={formError.cellphone}
                                        onChange={(value) => setCellPatient(value) }
                                    />
                                </Form.Field>
                            }
                            {!howShare &&
                                <Form.Input 
                                    className="howShareInput"
                                    fluid name="email"
                                    label='Email a enviar'
                                    placeholder='Email a enviar'
                                    error={formError.email}
                                    value={emailPatient}
                                    type="email"
                                    onChange={e => setEmailPatient(e.target.value) }
                                />
                            }
                            <Button name="sendRecet" loading={loadingAction} disabled={loadingAction} className="sendRecet" onClick={() => GeneratePDF(true, false, howShare)}>Enviar</Button>
                            {sentRecet && 
                                <div className="messageDiv">
                                    <Message className={isOKSent === "messageUserData__fail" ? "messageUserData messageUserData__fail" : "messageUserData messageUserData__success"}>
                                        {msgSent}
                                    </Message>
                                </div>
                            }
                        </div>
                    }
                </>
            }
        </Form>
        {/* {openModalPharmacy &&
            <ModalComponent loading={loadingSend} openModal={openModalPharmacy} setOpenModal={setOpenModalPharmacy} titleModal="Ingrese el mail de la farmacia" size="tiny" contentYes="Enviar receta a la farmacia" functionToDo={SendRecetToPharmacy}>
                {contentModalPharmacy}
            </ModalComponent>
        } */}
            <ModalPrevisualizacion 
                loading={loadingSaveReceta}
                valueQRCode={valueQRCode} 
                signature={signature} 
                selectedPatient={selectedPatient}
                openModal={openModal} 
                setOpenModal={setOpenModal} 
                saveReceta={saveReceta} 
                infoUser={infoUser}
                formData={formData}
                selectedCie10={selectedCie10}    
            />
        </>
    )
}


function ContentModalPharmacy(props) {
    const { setEmailToSend, formErrorPharmacy, ifErrorSend, isOKSentPharmacy, msgSentPharmacy } = props;
    const [input, setInput] = useState("");
    useEffect(() => {
        setEmailToSend(input);
    }, [input]);
    return (
        <div>
            <Header as="h4">Ingrese el mail de la farmacia a la que querés enviar la receta: </Header>
            <Input error={formErrorPharmacy} icon='at' placeholder="Email de la farmacia" className="styleInputEmailPharmacy" type="email" name="emailToSend" onChange={e => setInput(e.target.value)} value={input} />
            {ifErrorSend &&
                <div className="centerMsgModal">
                    <Message className={isOKSentPharmacy === "messageUserData__fail" ? "messageUserData messageUserData__fail" : "messageUserData messageUserData__success"}>
                        {msgSentPharmacy}
                    </Message>
                </div>
            }
        </div>
    );
}