import React, { useState, useEffect } from 'react';
import "./Privacity.scss";
import { Header, Grid, Icon, Popup, Button, Message, Checkbox } from "semantic-ui-react";
import axios from "axios";
import Global from "../../../../utils/Global";
import { GenerateQRCode } from '../../../../utils/saniziteValues';
import { getLocalStorage } from '../../../../utils/localStorage';
import { dataDoctorValuesOptions, stateOptions } from '../../../../utils/ValuesOptions';
import { isMobile } from "react-device-detect";
import QRCode from "qrcode.react";

export default function Privacity(props) {

    const { setInfoUser, infoUser } = props;

    const [loading, setLoading] = useState(false);

    const [doneMsg, setDoneMsg] = useState(false);
    const [isOK, setIsOK] = useState("messageUserData__fail");
    const [msg, setMsg] = useState("");

    const listToShow = ["name", "lastname", "address", "cellphone", "dni", "speciality", "zipcode", "emailJob", "phone", "state", "dataDoctorValues"];

    const [enableQR, setEnableQR] = useState(infoUser?.enableQrCode);
    const [listChecks, setListChecks] = useState({});

    const [valueQRCode, setValueQRCode] = useState("");

    const dataNames = {
        "name": "Nombre",
        "lastname": "Apellido",
        "state": "Provincia",
        "dni": "Documento de identidad",
        "address": "Dirección",
        "phone": "Teléfono",
        "cellphone": "Celular",
        "emailJob": "Email",
        "dataDoctorValues": dataDoctorValuesOptions[infoUser.country],
        "speciality": "Especialidad",
        "zipcode": "Código Postal"
    };

    useEffect(() => {
        let objectCheck = {};
        for(let el in infoUser) {
            if(listToShow.includes(el) && el !== "dataDoctorValues") {
                if(infoUser.qrcode.includes(el)) {
                    objectCheck[el] = true;
                } else {
                    objectCheck[el] = false;
                }
            }
            if(el === "dataDoctorValues") {
                let auxObj = {};
                for(let subel in infoUser[el]) {
                    for(let obj in infoUser.qrcode) {
                        if(!!infoUser.qrcode[obj] && infoUser.qrcode[obj].constructor === Object) {
                            if(infoUser.qrcode[obj][el].includes(subel)) {
                                auxObj[subel] = true;
                            } else {
                                auxObj[subel] = false;
                            }
                        }
                    }
                }
                objectCheck[el] = auxObj;
            }
        }
        setListChecks(objectCheck);
    }, []);

    const saveQRCode = () => { // acá también tenemos que limpiar!
        if(!listChecks.name || !listChecks.lastname) {
            setDoneMsg(true);
            setMsg("El nombre y apellido deben estar en el código QR. ");
        } else if(!listChecks["dataDoctorValues"][stateOptions[infoUser.country][infoUser.state].mandatory]) {
            setDoneMsg(true);
            setMsg("La matrícula es obligatoria. ");
        } else {
            setLoading(true);
            axios.post(Global.url + 'change-qr', { listQrCode: listChecks, enableQrCode: enableQR }, { headers: { "authorization" : `Bearer ${getLocalStorage()}`} })
                .then(res => {
                    setIsOK(res.data.result);
                    setMsg("¡Cambios guardados!");
                    setInfoUser(res.data.infoUser);
                    setValueQRCode(GenerateQRCode(infoUser, dataDoctorValuesOptions, dataNames));
                }).catch(err => {
                    setIsOK("messageUserData__fail");
                    setMsg("Hubo un error interno en el servidor. Por favor, intente más tarde. ");
                }).finally(() => {
                    setDoneMsg(true);
                    setLoading(false);
                })
        }
    }

    return (
        <div>
            <Header as="h3">Código QR <Button className="btnEnableQR" color={enableQR ? "red" : "green"} onClick={() => setEnableQR(prevValue => !prevValue)}>{enableQR ? "Desactivar" : "Activar"}</Button></Header>
            <Header as="h4">Seleccione los datos que querés mostrar en el código QR de tus recetas. El nombre, apellido y la matrícula son obligatorios. </Header>
            {enableQR &&
                <Grid className="gridQRCode">
                    {listToShow.map((e, i) => (
                        <CheckboxDiv type="personal" key={e} element={e} dataNames={dataNames} listChecks={listChecks} setListChecks={setListChecks} listToShow={listToShow} />
                    ))}
                    {Object.keys(infoUser.dataDoctorValues).map((e, i) => (
                        <CheckboxDiv stateOptions={stateOptions} infoU={infoUser} type="prof" key={e} element={e} dataNames={dataNames} listChecks={listChecks} setListChecks={setListChecks} listToShow={listToShow}  />
                    ))}
                </Grid>
            }
            <div className="divButtonQRCode">
                <Button loading={loading} disabled={loading} name="saveQRCode" className="saveQRCode" onClick={() => saveQRCode()}>Guardar cambios</Button>
            </div>
            {doneMsg && 
                <>
                    <div className="messageDiv">
                        <Message className={isOK === "messageUserData__fail" ? "messageUserData messageUserData__fail" : "messageUserData messageUserData__success"}>
                            {msg}
                        </Message>
                    </div>
                    <div className="messageDiv">
                        Podés comprobarlo usando la cámara de tu celular    
                    </div>
                    <div className="messageDiv">
                    <QRCode
                        value={valueQRCode}
                        size={128}
                        bgColor={"#ffffff"}
                        fgColor={"#000000"}
                        level={"H"}
                        includeMargin={false}
                        renderAs={"svg"}
                        // imageSettings={{
                        //     src: "https://static.zpao.com/favicon.png",
                        //     x: null,
                        //     y: null,
                        //     height: 24,
                        //     width: 24,
                        //     excavate: true,
                        // }}
                    />
                    </div>
                </>
            }
        </div>
    )
}


function CheckboxDiv(props) {
    const { type, element, dataNames, listChecks, setListChecks, listToShow, infoU = null, stateOptions = null } = props;
    if(type === "personal") {
        if(listToShow.includes(element) && element !== "dataDoctorValues") {
            if(element === "name" || element === "lastname") {
                return <Checkbox className="checkboxQrCode" label={dataNames[element]} checked={true} disabled={true} />
            } else {
                return <Checkbox className="checkboxQrCode" label={dataNames[element]} checked={listChecks[element]} onChange={() => setListChecks({...listChecks, [element]: !listChecks[element] })} />
            }
        } else {
            return null;
        }
    } else {
        if(listChecks?.dataDoctorValues?.[element] !== undefined) {
            let mandatory = stateOptions[infoU.country][infoU.state].mandatory === element ? true : false;
            return <Checkbox className="checkboxQrCode" label={dataNames.dataDoctorValues[element]} checked={listChecks?.dataDoctorValues?.[element]} disabled={mandatory} onChange={() => setListChecks({...listChecks, dataDoctorValues: { ...listChecks["dataDoctorValues"], [element]: !listChecks["dataDoctorValues"][element] } })} />
        } else {
            return null;
        }
    }
 
}
