import React, { useState, useEffect } from 'react';

import "./ConfirmData.scss";

import { Container, Message } from "semantic-ui-react";

import { isMobile } from "react-device-detect";

import axios from "axios";
import Global from "../../utils/Global";

import { useHistory } from "react-router-dom";

export default function ConfirmData(props) {

    const { match } = props;

    const history = useHistory();

    const [doneMsg, setDoneMsg] = useState(false);
    const [headerMsg, setHeaderMsg] = useState("");
    const [contentMsg, setContentMsg] = useState("");
    const [successMsg, setSuccessMsg] = useState("warning");


    useEffect(() => {
        if(match?.params.type !== "confirm-change-email" && match?.params.type !== "confirm-activation") {
            history.push("/");
        } else {
            axios.post(Global.url + match.params.type, { code: match.params.token })
                .then(res => {
                    setSuccessMsg(res.data.success);
                    setContentMsg(res.data.message);
                    setHeaderMsg(res.data.header);
                    if(match?.params.type === "confirm-activation" && res.data.success === "success") {
                        setTimeout(function() {
                            history.push("/login");
                        }, 4000);
                    }
                }).catch(err => {
                    setSuccessMsg("error");
                    setContentMsg("Hubo un error interno del servidor. Por favor, intente más tarde. ");
                    setHeaderMsg("¡Ups! Algo falló");
                }).finally(() => {
                    setDoneMsg(true);
                });
        }
    }, []);

    return (
        <Container className={isMobile ? "confirmContainerMobile" : "confirmContainer"}>
            {doneMsg &&
                <Message color={successMsg} className={isMobile ? "msgStyle msgStyle__Mobile" : "msgStyle"}>
                    <Message.Header>{headerMsg}</Message.Header>
                    <Message.Content>{contentMsg}</Message.Content>
                </Message>
            }
        </Container>
    )
}
