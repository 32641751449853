import React from 'react';
import { Modal, Button } from "semantic-ui-react";

import "./ModalComponent.scss";

export default function ModalComponent(props) {

    const { openModal, setOpenModal, titleModal, children, includeActions = true, size = "tiny", functionToDo = null, functionToDoNotOk = null, contentYes = "OK", loading = false } = props;

    const onClose = () => {
        setOpenModal(false);
    }

    const confirmButton = () => {
        if(functionToDo !== null) {
            functionToDo();
        } else {
            setOpenModal(false);
        }
    }

    const restartValues = () => {
        if(functionToDoNotOk !== null) {
            functionToDoNotOk();
        }
    }

    return (
        <div>
            <Modal size={size} open={openModal} onClose={onClose}>
                <Modal.Header>{titleModal}</Modal.Header>
                <Modal.Content>
                    {children}
                </Modal.Content>
                <Modal.Actions>
                    {includeActions ? (
                        <>
                            <Button 
                                className="buttonNegativeModal"
                                negative
                                onClick={onClose}
                            >
                                Cancelar
                            </Button>
                            {functionToDoNotOk !== null && 
                                <Button 
                                    className="buttonRestartSignature"
                                    negative
                                    onClick={() => restartValues()}
                                >
                                    Reiniciar firma
                                </Button>
                            }
                            <Button
                                loading={loading}
                                disabled={loading}
                                positive
                                icon='checkmark'
                                labelPosition='right'
                                content={contentYes}
                                onClick={confirmButton}
                            />
                        </>
                    ) : (
                        <Button
                            positive
                            onClick={confirmButton}
                        >
                            {contentYes}
                        </Button>
                    )}
                </Modal.Actions>
            </Modal>
        </div>
    );
}
