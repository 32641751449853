import React from 'react';
import "./ModalPrevisualizacion.scss";
import moment from "moment";
import { Grid, Image, Message } from "semantic-ui-react";
import QRCode from "qrcode.react";
import ModalComponent from '../../../ModalComponent/ModalComponent';

import { isMobile, isMobileOnly, isTablet } from "react-device-detect";
import { dataDoctorValuesOptionsAbbr } from "../../../../utils/ValuesOptions";
import { saniziteNumbers } from "../../../../utils/saniziteValues";

export default function ModalPrevisualizacion(props) {

    const { valueQRCode, signature, selectedPatient, openModal, setOpenModal, saveReceta, infoUser, formData, selectedCie10, loading } = props;
    
    const repeat = (formData.duration)/30;

    const detectDevice = () => {
        if(isMobileOnly) {
            return "demoNotValidMobile";
        } else if(isTablet){
            return "demoNotValidTablet";
        } else {
            return "demoNotValid";    
        }
    }

    return (
        <ModalComponent loading={loading} titleModal="Previsualización de Receta" contentYes={repeat === 1 ? "Validar receta" : "Validar recetas"} size="large" openModal={openModal} setOpenModal={setOpenModal} functionToDo={saveReceta}>
            <div className="contentModalReceta">
                {repeat !== 1 &&
                    <Message warning>
                        <Message.Header>Atención</Message.Header>
                        <Message.Content>Seleccionaste {formData.duration} días. Se hará {repeat} recetas, cada uno con sus fechas de inicio y vencimiento correspondientes. </Message.Content>
                    </Message>
                }
                <div className={isMobile ? "recetsDivPrev recetsDivPrev__Mobile" : "recetsDivPrev"}>
                {Array.from(Array(repeat), (e, i) => (
                    <PrevReceta key={i+1} duration={i+1} valueQRCode={valueQRCode} selectedPatient={selectedPatient} formData={formData} selectedCie10={selectedCie10} infoUser={infoUser} signature={signature} detectDevice={detectDevice} saniziteNumbers={saniziteNumbers} />
                ))}
                </div>
            </div>
        </ModalComponent>
    )
}


function PrevReceta(props) {

    const { duration, valueQRCode, selectedPatient, formData, selectedCie10, infoUser, signature, detectDevice, saniziteNumbers } = props;

    const getDataDoctorValues = () => {
        let divDataDoctorValues = "";
        for (let elem in infoUser.dataDoctorValues) {
            if(infoUser?.dataDoctorValues[elem].length) {
                divDataDoctorValues += `<div className="aclarationSignature">${dataDoctorValuesOptionsAbbr[infoUser?.country][elem]} ${saniziteNumbers(infoUser?.dataDoctorValues[elem])}</div>`
            }
        }
        return { __html: divDataDoctorValues }
    }

    return (
        <div className={isMobile ? "prevReceta prevReceta__Mobile" : "prevReceta"}>
            <div className="headerReceta">
                <div className="dateReceta"><strong>Fecha</strong>: {moment().add(((duration-1)*30), "days").format("DD/MM/YYYY")}</div>
                <Grid className="gridReceta">
                    <Grid.Column width={8}>
                        <div><strong>Paciente</strong>: {selectedPatient?.name} {selectedPatient?.lastname}</div>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <div><strong>Documento</strong>: {selectedPatient?.typeDNI} {saniziteNumbers(selectedPatient?.dni)}</div>
                    </Grid.Column>
                </Grid>
                <Grid className="gridReceta anotherGrid">
                    <Grid.Column width={8}>
                        <div><strong>Obra social</strong>: {selectedPatient?.obrasocial}</div>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <div><strong>Nro</strong>: {selectedPatient?.nro}</div>
                    </Grid.Column>
                </Grid>
                <div className="RpContent">
                    <div>Rp/</div>
                    <div className="remedio1">{formData.remedio1}</div>
                    <div className="remedio2">{formData.remedio2}</div>
                    <div className="diagnostics">
                        <div><strong>Diagnóstico</strong>: {selectedCie10?.cie10}</div>
                    </div>
                </div>
            </div>
            <div className={detectDevice()}>DEMOSTRACIÓN</div>
            <div className="footerReceta">
                <Grid className="gridFooter">
                    <Grid.Column width={6} floated="left">
                        {infoUser.enableQrCode &&
                            <QRCode
                                value={valueQRCode}
                                size={64}
                                //bgColor={"#ffc2d7"}
                                bgColor={"#fff"}
                                fgColor={"#000000"}
                                level={"H"}
                                includeMargin={false}
                                renderAs={"svg"}
                            />
                        }
                    </Grid.Column>
                    <Grid.Column width={8} floated="right">
                        <div className="contentSignature">
                            <Image src={signature} />
                        </div>
                        <div className="aclarationSignature">{infoUser?.sex === "M" ? "Dr. " : "Dra. "}{infoUser?.name} {infoUser?.lastname}</div>
                        <div className="aclarationSignature" dangerouslySetInnerHTML={getDataDoctorValues(infoUser)}></div>
                        <div className="textPaddingTop">Firma del Médico</div>
                    </Grid.Column>
                </Grid>
                <div className="endFooterReceta">
                    Respetar la prescripción médica garantiza la efectividad del tratamiento.
                </div>
            </div>
            <div className="footerId">Válido hasta: {moment().add((duration*30), "days").format("DD/MM/YYYY")} - ID: Para validar la receta, por favor haga click en validar receta. </div>
        </div>
    )
}
