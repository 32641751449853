import React from 'react';
import logo from './logo.svg';
import './assets/scss/App.scss';

import Router from "./components/Router";


function App() {

  return (
    <div className="App">
      <Router />
    </div>
  );
}

export default App;
