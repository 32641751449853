import React, { useState } from 'react';
import "./Recetas.scss";
import { useParams } from "react-router-dom";
import { Grid, Menu } from "semantic-ui-react";
import CreateReceta from "./CreateReceta";
import DownloadReceta from "./DownloadReceta";
import DuplicateReceta from "./DuplicateReceta";
import CancelReceta from "./CancelReceta";
import ListRecetas from "./ListRecetas";
import { isMobile } from "react-device-detect";

export default function Recetas(props) {

    const { infoUser, setInfoUser } = props;

    let { subpath } = useParams();

    const [menuRecetas, setMenuRecetas] = useState(subpath !== undefined ? subpath : "create");

    return (
        <Grid className="gridUser" columns='equal'>
            <Grid.Column className={isMobile ? "menuVertical" : "menuUserGrid"} width={isMobile ? 16 : 4}>
                <Menu fluid vertical tabular={isMobile ? false : true} style={{ minHeight: isMobile ? "auto" : "67vh", height: "100%" }}>
                    <Menu.Item 
                        name="Nueva Receta"
                        content="Nueva receta"
                        active={menuRecetas === "create"}
                        onClick={() => setMenuRecetas("create")}
                    />
                    <Menu.Item 
                        name="Compartir receta"
                        content="Compartir receta"
                        active={menuRecetas === "share"}
                        onClick={() => setMenuRecetas("share")}
                    />
                    <Menu.Item 
                        name="Duplicar receta"
                        content="Duplicar receta"
                        active={menuRecetas === "duplicate"}
                        onClick={() => setMenuRecetas("duplicate")}
                    />
                    <Menu.Item 
                        name="Anular receta"
                        content="Anular receta"
                        active={menuRecetas === "cancel"}
                        onClick={() => setMenuRecetas("cancel")}
                    />
                    <Menu.Item 
                        name="Listado de recetas"
                        content="Listado de recetas"
                        active={menuRecetas === "list"}
                        onClick={() => setMenuRecetas("list")}
                    />
                </Menu>
            </Grid.Column>
            <Grid.Column className="contentUserGrid">
                {(() => {
                    if (menuRecetas === "create") {
                        return <CreateReceta infoUser={infoUser} setInfoUser={setInfoUser} />
                    } else if (menuRecetas === "share") {
                        return <DownloadReceta infoUser={infoUser} />
                    } else if(menuRecetas === "duplicate") {
                        return <DuplicateReceta infoUser={infoUser} setInfoUser={setInfoUser} />
                    } else if(menuRecetas === "cancel") {
                        return <CancelReceta />
                    } else if(menuRecetas === "list") {
                        return <ListRecetas />
                    } else {
                        return <CreateReceta infoUser={infoUser} setInfoUser={setInfoUser} />
                    }
                })()}
            </Grid.Column>
        </Grid>
    )
}
