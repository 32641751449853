import React, { useEffect } from 'react';

import "./Tos.scss";

import { Container, Header, List } from "semantic-ui-react";

export default function Tos() {

    useEffect(() => {
        window.scroll({top: 0, left: 0, behavior: 'smooth' });
    }, []);

    return (
        <Container className="bgDashboard bgTos">
            <Header as="h3" className="tosTitle">Términos y condicione del uso</Header>
            <div className="contentTos">
                {/* <List ordered className="listTos">
                    <List.Item>RECETA MÉDICA (la App) es un software y servicio interactivo de propiedad de IDEOLOGY S.R.L. (el Titular), destinado a profesionales de la medicina (genéricamente denominados en el presente como los Médicos, término que abarca asimismo a sus equipos de trabajo y/o a los Centros Médicos) y a los pacientes (los Pacientes), cuyo objeto es que los médicos realicen (las Recetas médicas) a través de la App y también que los pacientes puedan recepcionarlas de esta forma, con el objetivo final de mejorar la inmediatez en  el expendio de recetas médicas, los cuales serán utilizados por los Médicos y Pacientes (denominados en conjunto como los Usuarios) incorporan en la App (las Recetas médicas).</List.Item>
                    <List.Item>El Titular es una sociedad registrada e inscripta en la República Argentina, con domicilio en Av. Del Libertador 6836, Piso 3, (C.P. 1429) Ciudad Autónoma de Buenos Aires, Argentina, que actúa de conformidad con los términos de la ley Argentina.</List.Item>
                    <List.Item>La utilización de la App implica la aceptación íntegra, sin limitación alguna, de los Términos y Condiciones Generales que surgen del presente (los Términos) por parte de los Usuarios y/o de cualquier otra persona, física o jurídica, que directa o indirectamente haga uso de la misma (los Terceros).</List.Item>
                    <List.Item>La App constituye un servicio para lograr agilizar el proceso de recetar por parte de los Usuarios, siendo éstos quienes ingresan, bajo su propia responsabilidad, los datos volcados en la misma. En consecuencia, el Titular no asume responsabilidad alguna por: (i) los productos que se receten; (ii) la identidad de los emisores de la Información; (iii) el uso dado a la Información. Todo ello, habida cuenta que la App funciona como un mero soporte digital de la Información, la que es ingresada y utilizada por los Usuarios, quienes serán los únicos y exclusivos responsables.</List.Item>
                    <List.Item>El acceso y/o uso de la App obliga al Usuario -bajo su exclusiva y única responsabilidad- a que la incorporación de la Información y/o de los datos personales (los Datos) que le sean solicitados, sean verdaderos, completos y precisos. Todo Usuario se compromete asimismo a notificar cualquier cambio habido en la Información y/o en los Datos, a la mayor brevedad posible.</List.Item>
                    <List.Item>La incorporación de Información y/o Datos por los Usuarios será en forma libre y voluntaria. Los Usuarios que elijan usar la Información disponible serán enteramente responsables por su uso.</List.Item>
                    <List.Item>El Titular no será responsable en caso de que el Usuario no pudiese acceder a la Información, toda vez que la App no sustituye a otros sistemas de almacenamiento ni acceso a la Información. Cada Usuario en su caso deberá llevar registros complementarios fuera de la App. </List.Item>
                    <List.Item>Cuando la Información sea ingresada por los Médicos, éstos deberán obtener el consentimiento expreso del Paciente, en los siguientes términos: Por medio del presente presto expreso consentimiento a que el Dr. ***** recete los productos que considera son necesarios para mi afección , en la App RECETA MÉDICA, cuyos Términos y Condiciones conozco y acepto, reconociendo que la Titular (conforme se describe en la misma) no será responsable por la veracidad ni uso de la información que se incorpore. La incorporación de Información constituye una declaración jurada del Médico en el sentido que ha obtenido la debida autorización. </List.Item>
                    <List.Item>El Titular podrá disponer diversos sistemas de incorporación de la Información a la App y de acceso a la misma por parte de los Usuarios, los que podrá modificar sin restricción alguna. Cuando existieran medidas de seguridad para el resguardo de la información, correrá por cuenta de los Usuarios mantener, bajo su exclusiva responsabilidad,  el secreto e integridad de la Información, Datos, claves y/o medidas de seguridad.</List.Item>
                    <List.Item>La APP brinda una herramienta de Software para que los médicos puedan realizar recetas en forma digital, pudiendo observar precios, marcas comerciales, presentaciones, farmacias más cercanas, etc.</List.Item>
                    <List.Item>La APP brinda una herramienta de Software para que los médicos puedan realizar recetas en forma digital, pudiendo observar precios, marcas comerciales, presentaciones, farmacias más cercanas, etc.</List.Item>
                    <List.Item>En la misma se verá el encabezado y pie de página con los datos del médico ingresante y este podrá modificarlos las veces que sea necesario. Asimismo, podrá elegir firmar digital o caligráficamente.</List.Item>
                    <List.Item>El Titular se reserva el derecho de incorporar o no a los Médicos en el directorio de la APP, a su solo arbitrio, así como de establecer patrocinios pagos para ocupar lugares destacados en el directorio, sin que ello implique recomendación alguna por parte del Titular, ni genere derecho a reclamo alguno de los Usuarios y/o terceros.</List.Item>
                    <List.Item>El Titular no será responsable por ninguna publicidad, comercialización y/u otras actividades que se realicen en el marco de la App  y no intermediará en la misma, siendo por tanto ajeno a cualquier efecto, consecuencia o responsabilidad derivado de ella. Tampoco será responsable de las actuaciones llevadas a cabo directamente por los fabricantes, distribuidores o proveedores de productos o servicios ofertados.</List.Item>
                    <List.Item>El Titular no será responsable por ninguna falla en los productos recetados, como tampoco lo será en el caso de efectos colaterales de los productos y/o efectos adversos de los mismos.</List.Item>
                    <List.Item>Los Usuarios autorizan al Titular y/o a quien él disponga, a sistematizar, analizar y/o utilizar la Información y los Datos con fines científicos, académicos y/o de políticas comerciales, siempre respetando el anonimato, y en un todo de conformidad con la legislación vigente. Asimismo, el Titular, por medio de la App, podrá recoger y procesar información anónima sobre las consultas y/o visitas, sus comentarios que realicen los Usuarios vinculadas con la App, pudiendo emplear la misma para la mejora de los servicios brindados, obtener y compilar estadísticas, o la realización de promociones comerciales por Terceros, A estos fines, podrán instalarse cookies, que son un conjunto de datos de tamaño reducido que se envían al navegador y se almacenan en el disco rígido. El Usuario podrá programar a su navegador para que le notifique cuando recibe un cookie, de manera de decidir, en cada caso, si lo acepta o no. El Titular no será responsable por dichos cookies ni por un eventual mal funcionamiento de la página y/o del ordenador, ni por los eventuales daños o perjuicios que se pudieran derivar de interferencias, omisiones, interrupciones, virus informáticos, averías telefónicas o desconexiones en el funcionamiento operativo de este sistema electrónico.</List.Item>
                    <List.Item>Todos los Usuarios se comprometen a utilizar la App en un todo de acuerdo con la legislación vigente. El Titular se reserva el derecho de impedir el acceso y/o cancelar el mismo, a su sólo arbitrio, a cualquier persona que realice un uso de la App que no se ajuste a las disposiciones legales y/o a estos Términos.</List.Item>
                    <List.Item>El Titular en ningún caso será responsable por cualquier daño -directo o indirecto- que pueda ocasionar la exactitud, veracidad o exhaustividad de la Información, o por uso que de ellos pueda hacerse por parte de los Usuarios y/o Terceros, ni por el uso ilegítimo que pudiesen hacer de los nombres de marcas y/u otros derechos de propiedad intelectual. Tampoco será responsable por los eventuales perjuicios que se deriven de las relaciones que establezcan los Usuarios con Terceros, dejando aclarado que la promoción y/o comercialización que éstos realicen vía la App no implica que el Titular asuma la precisión ni calidad de estos o de sus contenidos. La presencia de enlaces (links) a sitios web de terceros tiene una finalidad meramente informativa, y en ningún caso supone sugerencia, invitación o recomendación sobre los mismos. </List.Item>
                    <List.Item>El Titular no será responsable por el desempeño de los Médicos, tampoco será responsable en el caso que la conducta del médico incurra en el delito de mala praxís.</List.Item>
                    <List.Item>Todo Usuario se compromete a mantener indemne al Titular y a cualquier persona vinculada, de cualquier reclamo derivado del uso de la App y/o de la Información y/o Datos.</List.Item>
                    <List.Item>Los derechos de propiedad intelectual referidos a la App, su uso y demás servicios ofrecidos por el Titular, incluidos los diseños gráficos y códigos, son de propiedad de éste, quedando prohibida la reproducción por cualquier medio de los mismos. Toda imagen y/o fotografía que sea incorporada a la App por los Usuarios podrá ser libremente utilizada por  el Titular.</List.Item>
                    <List.Item>El Titular se reserva el derecho de modificar estos Términos en cualquier momento y sin previo aviso. Se considera notificación de la modificación al Usuario la inserción del nuevo texto en la App. El uso de la misma implicará su aceptación por parte del usuario.</List.Item>
                    <List.Item>El Usuario tendrá en todo momento el derecho de solicitar la eliminación de la Información que a él se refiera, lo que será realizado en un plazo máximo de 30 días. Asimismo, podrá solicitar la rectificación de la Información y/o Datos, todo ello de conformidad con lo dispuesto por la legislación de Protección de Datos Personales, ley Nº 25.326.</List.Item>
                    <List.Item>Los Términos y la App se regirán por la legislación argentina, quedando establecida la competencia de los tribunales ordinarios de la Capital Federal, con renuncia expresa a cualquier otro fuero o jurisdicción que pudiera llegar a corresponder.</List.Item>
                </List> */}
            </div>
        </Container>
    )
}
