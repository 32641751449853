import React, { useState, useEffect } from 'react';

import moment from "moment";
import axios from "axios";
import Global from "../../../../utils/Global";
import { getLocalStorage } from '../../../../utils/localStorage';

import { validateEmail } from '../../../../utils/Validations';

import { Header, Icon, Form, Divider, Message, Select } from "semantic-ui-react";

export default function ConfigUser(props) {

    const { infoUser, selectedUser } = props;

    const [doneMsgSuscription, setDoneMsgSuscription] = useState(false);
    const [doneMsgActive, setDoneMsgActive] = useState(false);
    const [doneMsgPass, setDoneMsgPass] = useState(false);
    const [doneMsg, setDoneMsg] = useState(false);
    const [isOK, setIsOK] = useState("messageUserData__fail");
    const [msg, setMsg] = useState("");

    const [loadingEmail, setLoadingEmail] = useState(false);
    const [loadingPass, setLoadingPass] = useState(false);
    const [loadingActive, setLoadingActive] = useState(false);
    const [loadingSuscription, setLoadingSuscription] = useState(false);

    const [visibleNewPass, setVisibleNewPass] = useState(false);

    const [formError, setFormError] = useState({});
    const [formDataEmail, setFormDataEmail] = useState({
        "email": "",
        "emailRepeat": ""
    });
    const [formDataPassword, setFormDataPassword] = useState({
        "passwordNew": "",
        "passwordRepeat": ""
    });

    const suscriptionOptions = [
        { key: 'BASIC', value: 'BASIC', text: 'Ninguna suscripción' },
        { key: 'PREMIUM', value: 'PREMIUM', text: 'Suscripción Premium' },
        { key: 'SUPERPREMIUM', value: 'SUPERPREMIUM', text: 'Suscripción Superpremium' }
    ];

    const [selectSuscription, setSelectSuscription] = useState({
        "typeSuscription": selectedUser.typeSuscription,
        "timeSuscription": selectedUser.timeSuscription,
        "endDateSuscription": moment(selectedUser.endDateSuscription, "DD/MM/YYYY").format("YYYY-MM-DD")
    });

    const changeDetails = sufix => {
        let formData = formDataEmail;
        let isOk = true;
        if(sufix === "password") {
            formData = formDataPassword;
            if(!formDataPassword.passwordNew.length) {
                setFormError({ passwordNew: "La nueva contraseña no puede estar vacía. "});
                isOk = false;
            } else if(formDataPassword.passwordNew.length < 8) {
                setFormError({ passwordNew: "La nueva contraseña debe contener al menos 8 carácteres. "});
                isOk = false;    
            } else if(formDataPassword.passwordNew !== formDataPassword.passwordRepeat) {
                setFormError({ passwordRepeat: "Las nuevas contraseñas no coinciden. "});
                isOk = false;
            }
        } else if(sufix === "email") {
            if(formDataEmail.email === selectedUser.email) {
                setFormError({ email: "El email es igual al que tiene el médico actualmente. "});
                isOk = false;
            } else if(!validateEmail(formDataEmail.email) || !validateEmail(formDataEmail.emailRepeat)) {
                setFormError({ email: "El nuevo email no es correcto. "});
                isOk = false;
            }
        } else if(sufix === "suscription") {
            formData = selectSuscription;
            if(!moment(selectSuscription.endDateSuscription).isValid() && selectSuscription.typeSuscription === "PREMIUM") {
                setFormError({ endDateSuscription: "La fecha fin de suscripción no es correcta. "});
                isOk = false;
            }
        }
        if(isOk) {
            if(sufix === "password") {
                setLoadingPass(true);
            } else if(sufix === "email") {
                setLoadingEmail(true);
            } else if(sufix === "suscription") {
                setLoadingSuscription(true);
            } else {
                setLoadingActive(true);
            }
            axios.post(Global.url + 'change-'+sufix+"-doctor", { formData: formData, selectedUser: selectedUser }, { headers: { "authorization" : `Bearer ${getLocalStorage()}`} })
            .then(res => {
                if(res.data.status) {
                    setIsOK("messageUserData__success");
                }
                setMsg(res.data.message);
            }).catch(err => {
                setMsg("Hubo un error interno en el servidor. Por favor, intente más tarde. ");
                setIsOK("messageUserData__fail");
            }).finally(() => {
                if(sufix === "password") {
                    setDoneMsgPass(true);
                    setLoadingPass(false);
                } else if(sufix === "email") {
                    setDoneMsg(true);
                    setLoadingEmail(false);
                } else if(sufix === "suscription") {
                    setDoneMsgSuscription(true);
                    setLoadingSuscription(false);
                } else {
                    setDoneMsgActive(true);
                    setLoadingActive(false);
                }
            });
        }
    }
    
    return (
        <>
            <Header as="h3">
                Cambiar email
            </Header>
            <div className="infoUserDiv">
                <strong>Email actual</strong>: {selectedUser.email}
            </div>
            <Form>
                <Form.Group widths='equal'>
                    <Form.Input
                        inline
                        fluid
                        label="Nuevo email"
                        name="email"
                        type="email"
                        value={formDataEmail.email}
                        error={formError.email}
                        className="formEmailConfigUser"
                        placeholder="Nuevo email"
                        onChange={e => setFormDataEmail({ ...formDataEmail, [e.target.name]: e.target.value })}
                    />
                    <Form.Input
                        inline
                        fluid
                        label="Repita el nuevo email"
                        name="emailRepeat"
                        type="email"
                        value={formDataEmail.emailRepeat}
                        error={formError.email}
                        className="formEmailConfigUser"
                        placeholder="Repita el nuevo email"
                        onChange={e => setFormDataEmail({ ...formDataEmail, [e.target.name]: e.target.value })}
                    />
                </Form.Group>
                <Form.Button loading={loadingEmail} disabled={loadingEmail} onClick={() => changeDetails("email")} className="btnSubmitForm">Cambiar email</Form.Button>
                {doneMsg && 
                    <div className="messageDiv">
                        <Message className={isOK === "messageUserData__fail" ? "messageUserData messageUserData__fail" : "messageUserData messageUserData__success"}>
                            {msg}
                        </Message>
                    </div>
                }
            </Form>
            <Divider />
            <Header as="h3">
                Cambiar contraseña
            </Header>
            <Form>
                <Form.Group widths='equal'>
                    <Form.Input
                        inline
                        fluid
                        label="Nueva contraseña"
                        name="passwordNew"
                        type={visibleNewPass ? "text" : "password"}
                        value={formDataPassword.passwordNew}
                        error={formError.passwordNew}
                        icon={<Icon name={visibleNewPass ? 'eye slash' : 'eye'} link onClick={() => setVisibleNewPass(prevValue => !prevValue)} />}
                        className="formEmailConfigUser"
                        placeholder="Escriba la nueva contraseña"
                        onChange={e => setFormDataPassword({ ...formDataPassword, [e.target.name]: e.target.value })}
                    />
                    <Form.Input
                        inline
                        fluid
                        label="Repita la nueva contraseña"
                        name="passwordRepeat"
                        type={visibleNewPass ? "text" : "password"}
                        value={formDataPassword.passwordRepeat}
                        error={formError.passwordRepeat}
                        icon={<Icon name={visibleNewPass ? 'eye slash' : 'eye'} link onClick={() => setVisibleNewPass(prevValue => !prevValue)} />}
                        className="formEmailConfigUser"
                        placeholder="Repita la nueva contraseña"
                        onChange={e => setFormDataPassword({ ...formDataPassword, [e.target.name]: e.target.value })}
                    />
                </Form.Group>
                <Form.Button loading={loadingPass} disabled={loadingPass} onClick={() => changeDetails("password")} className="btnSubmitForm">Cambiar contraseña</Form.Button>
                {doneMsgPass && 
                    <div className="messageDiv">
                        <Message className={isOK === "messageUserData__fail" ? "messageUserData messageUserData__fail" : "messageUserData messageUserData__success"}>
                            {msg}
                        </Message>
                    </div>
                }
            </Form>
            <Divider />
            <Header as="h3">
                Activar cuenta
            </Header>
            <Form>
                <Form.Button loading={loadingActive} disabled={loadingActive} onClick={() => changeDetails("active")} className="btnSubmitForm">Activar cuenta</Form.Button>
                {doneMsgActive && 
                    <div className="messageDiv">
                        <Message className={isOK === "messageUserData__fail" ? "messageUserData messageUserData__fail" : "messageUserData messageUserData__success"}>
                            {msg}
                        </Message>
                    </div>
                }
            </Form>
            <Divider />
            <Header as="h3">
                Convertir estado de suscripción
            </Header>
            <Form>
                <Form.Field 
                    control={Select}
                    options={suscriptionOptions}
                    required
                    label="Tipo de suscripción"
                    placeholder="Tipo de suscripción"
                    name="typeSuscription"
                    value={selectSuscription.typeSuscription}
                    error={formError.typeSuscription}
                    onChange={(e, {value}) => setSelectSuscription({...selectSuscription, typeSuscription: value}) }
                />
                <Form.Group widths='equal'>
                    <Form.Input
                        inline
                        fluid
                        label="Fecha fin de suscripción"
                        name="endDateSuscription"
                        type="date"
                        value={selectSuscription.endDateSuscription}
                        error={formError.endDateSuscription}
                        min={moment().format("YYYY-MM-DD")}
                        className="formEndDateSuscription"
                        placeholder="Fecha fin de suscripción"
                        onChange={e => setSelectSuscription({ ...selectSuscription, [e.target.name]: e.target.value})}
                    />
                </Form.Group>
                <Form.Button loading={loadingSuscription} disabled={loadingSuscription} onClick={() => changeDetails("suscription")} className="btnSubmitForm">Modificar suscripción</Form.Button>
                {doneMsgSuscription && 
                    <div className="messageDiv">
                        <Message className={isOK === "messageUserData__fail" ? "messageUserData messageUserData__fail" : "messageUserData messageUserData__success"}>
                            {msg}
                        </Message>
                    </div>
                }
            </Form>
        </>
    )
}
