import React, { useState, useEffect } from 'react';

import { Grid, Menu, Input } from "semantic-ui-react";

import { isMobile } from "react-device-detect";

import axios from "axios";
import Global from "../../../utils/Global";
import { getLocalStorage } from "../../../utils/localStorage";

import "./Remedios.scss";

//components
import AddRemedio from "./AddRemedio";
import ModifyRemedio from "./ModifyRemedio";
import ListRemedios from "./ListRemedios";

export default function Remedios(props) {

    const { infoUser } = props;

    const [menuRemedio, setMenuRemedio] = useState("addremedio");

    return (
        <>
            <Grid className="gridUser gridUser__Admin">
                <Grid.Column className={isMobile ? "menuVertical" : "menuUserGrid"} width={isMobile ? 16 : 4}>
                    <Menu fluid vertical tabular={isMobile ? false : true} style={{ minHeight: isMobile ? "auto" : "67vh", height: "100%" }}>
                        <Menu.Item 
                            name="Agregar remedio"
                            content="Agregar remedio"
                            active={menuRemedio === "addremedio"}
                            onClick={() => setMenuRemedio("addremedio")}
                        />
                        <Menu.Item 
                            name="Modificar remedio"
                            content="Modificar remedio"
                            active={menuRemedio === "modifyremedio"}
                            onClick={() => setMenuRemedio("modifyremedio")}
                        />
                        <Menu.Item 
                            name="Listado de remedios"
                            content="Listado de remedios"
                            active={menuRemedio === "listremedios"}
                            onClick={() => setMenuRemedio("listremedios")}
                        />
                    </Menu>
                </Grid.Column>
                <Grid.Column className="contentUserGrid" width={isMobile ? 16 : 12}>
                    {menuRemedio === "addremedio" &&
                        <AddRemedio infoUser={infoUser} />
                    }
                    {menuRemedio === "modifyremedio" &&
                        <ModifyRemedio infoUser={infoUser} />
                    }
                    {menuRemedio === "listremedios" &&
                        <ListRemedios infoUser={infoUser} />
                    }
                </Grid.Column>
            </Grid>
        </>
    )
}
