export const getLocalStorage = () => {
    const tokenStorage = localStorage.getItem(nameToken());
    if(tokenStorage === null || tokenStorage === undefined) return undefined;
    return tokenStorage;
}

export const setLocalStorage = token => {
    if(token !== undefined && token !== null) {
        localStorage.setItem(nameToken(), token);
    } else {
        localStorage.removeItem(nameToken());
    }
}

function nameToken() {
    switch(window.location.hostname) {
        case "rpdigitales.com.ar": return 'token_RPD'
        case "recetasmedicas.tomasbusquets.com": return 'token_RPD_demo'
        default: return 'token_RPD_localhost'
    }
}
