import React, { useState, useEffect } from 'react';
import { Form, Header, Message, Button, Table, Menu, Icon, Input, Select } from "semantic-ui-react";
import "./DuplicateReceta.scss";
import moment from "moment";
import axios from "axios";
import Global from "../../../../utils/Global";
import { getLocalStorage } from '../../../../utils/localStorage';
import { validateEmail } from "../../../../utils/Validations";
import { saniziteNumbers, GenerateQRCode } from "../../../../utils/saniziteValues";
import { dataDoctorValuesOptions } from "../../../../utils/ValuesOptions";
import PhoneInput from 'react-phone-input-2';
import es from 'react-phone-input-2/lang/es.json';
import ModalComponent from "../../../ModalComponent/ModalComponent";
import ModalPrevisualizacion from '../ModalPrevisualizacion/ModalPrevisualizacion';

export default function DuplicateReceta(props) {

    const { infoUser, setInfoUser } = props;

    const [allRecets, setAllRecets] = useState([]);
    const [loadingSaveReceta, setLoadingSaveReceta] = useState(false);
    const [loadingPDF, setLoadingPDF] = useState(false);
    const [loadingAction, setLoadingAction] = useState(false);
    const [loadingSend, setLoadingSend] = useState(false);
    const [loadingShareMobile, setLoadingShareMobile] = useState(false);

    const [howShare, setHowShare] = useState(false);
    const [infoPatientToShare, setInfoPatientToShare] = useState(false);

    const [sentRecet, setSentRecet] = useState(false);
    const [msgSent, setMsgSent] = useState("");
    const [isOKSent, setIsOKSent] = useState("messageUserData__fail");

    const [resultRes, setResultRes] = useState(false);
    const [doneMsg, setDoneMsg] = useState(false);
    const [isOK, setIsOK] = useState("messageUserData__fail");
    const [msg, setMsg] = useState("");

    const [openModal, setOpenModal] = useState(false);
    const [openModalPharmacy, setOpenModalPharmacy] = useState(false);
    const [contentModalPharmacy, setContentModalPharmacy] = useState(undefined);
    const [emailToSend, setEmailToSend] = useState("");
    const [formErrorPharmacy, setFormErrorPharmacy] = useState(false);
    const [ifErrorSend, setIfErrorSend] = useState(false);
    const [msgSentPharmacy, setMsgSentPharmacy] = useState(false);
    const [isOKSentPharmacy, setIsOKSentPharmacy] = useState(false);

    const [resultsSearch, setResultsSearch] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [searching, setSearching] = useState(false);
    const [gotValues, setGotValues] = useState(false);
    const [selectedPatient, setSelectedPatient] = useState(null);
    const [selectedCie10, setSelectedCie10] = useState(null);

    const [totalRecets, setTotalRecets] = useState(undefined);
    const [recetaCreated, setRecetaCreated] = useState([]);
    const [emailPatient, setEmailPatient] = useState("");
    const [cellPatient, setCellPatient] = useState("");

    const [formError, setFormError] = useState({});

    const [valueQRCode, setValueQRCode] = useState("");
    const [signature, setSignature] = useState(undefined);

    const [offset, setOffset] = useState(0);
    const [countPages, setCountPages] = useState(0);
    const [actualPage, setActualPage] = useState(1);
    const [startLimit, setStartLimit] = useState(1);

    const [formData, setFormData] = useState({
        "remedio1": "",
        "remedio2": "",
        "duration": 30,
        "duplicated": true
    });

    const dataNames = {
        "name": "Nombre",
        "lastname": "Apellido",
        "state": "Provincia",
        "address": "Dirección",
        "phone": "Teléfono",
        "cellphone": "Celular",
        "emailJob": "Email",
        "dataDoctorValues": dataDoctorValuesOptions[infoUser.country],
        "speciality": "Especialidad",
        "zipcode": "Código Postal"
    };

    useEffect(() => {
        if(selectedPatient !== null) {
            axios.post(Global.url + 'count-recets-patient', { selectedPatient: selectedPatient, excluded: "CANCELED" }, { headers: { "authorization" : `Bearer ${getLocalStorage()}`} })
                .then(res => {
                    setCountPages(res.data.result);
                }).catch(err => {
                    setCountPages(0);
                });
        }
    }, [selectedPatient]);

    useEffect(() => {
        if(selectedPatient !== null) {
            axios.post(Global.url + 'load-recets-patient', { offset: offset, selectedPatient: selectedPatient, excluded: "CANCELED" }, { headers: { "authorization" : `Bearer ${getLocalStorage()}`} })
                .then(res => {
                    setAllRecets(res.data.result);
                }).catch(err => {
                    setAllRecets([]);
                });
        } else {
            setAllRecets([]);
        }
    }, [selectedPatient, countPages, actualPage]);


    const searchPatient = value => {
        setSearchValue(value);
        setSearching(true);
        setGotValues(false);
        if(value.length >= 2) {
            setTimeout(function() {
                axios.post(Global.url + 'search-patient', { search: value }, { headers: { "authorization" : `Bearer ${getLocalStorage()}`} })
                    .then(res => {
                        setResultsSearch(res.data.results);
                    }).catch(err => {
                        setResultsSearch([]);
                    }).finally(() => {
                        setSearching(false);
                        setGotValues(true);
                });
            }, 300);
        } else {
            setSearching(true);
            setGotValues(false);
        }
    }

    const selectPatient = item => {
        setSearchValue("");
        setSelectedPatient(item);
        setGotValues(false);
        setResultsSearch([]);
        setDoneMsg(false);
        setSentRecet(false);
        setTotalRecets(undefined);
        setIsOK("messageUserData__fail");
    }

    const saveReceta = () => {
        setLoadingSaveReceta(true);
        axios.post(Global.url + 'save-receta', { formData: formData, patient: selectedPatient, cie10: selectedCie10, infoUser: infoUser }, { headers: { "authorization" : `Bearer ${getLocalStorage()}`} })
            .then(res => {
                if(res.data.status) {
                    setTotalRecets(res.data.total);
                    setInfoUser(prevValue => ({...prevValue, "limitUser": { ...prevValue["limitUser"], "max_recets": infoUser["limitUser"]["max_recets"] + res.data.total } }));
                    setRecetaCreated(res.data.receta);
                    setEmailPatient(res.data.receta[0].patient.email);
                    setCellPatient(res.data.receta[0].patient.cellphone);
                    setIsOK("messageUserData__success");
                    setMsg(res.data.message);
                    setFormData({
                        "remedio1": "",
                        "remedio2": "",
                        "duration": 30,
                        "duplicated": true
                    });
                    setGotValues(false);
                    setSelectedCie10(null);
                    setSelectedPatient(null);
                    setOpenModal(false);
                } else {
                    setIsOK("messageUserData__fail");
                    setMsg(res.data.message);
                    setOpenModal(false);
                }
                setDoneMsg(true);
            }).catch(err => {
                setIsOK("messageUserData__fail");
                setMsg("Hubo un error interno en el servidor. Por favor, intente más tarde. ");
                console.log(err);
            }).finally(() => {
                setResultRes(true);
                setLoadingSaveReceta(false);
            });
    }

    const DuplicateReceta = (recet) => {
        if(!selectedPatient) {
            setFormError({ patient: "El campo paciente es obligatorio. "});
        } else {
            setSelectedCie10({ cie10: recet.diagnostic });
            setFormData({
                "remedio1": recet.remedio1,
                "remedio2": recet.remedio2,
                "duration": recet.duration,
                "duplicated": true
            });
            axios.post(Global.url + 'get-signature', { mobile: false }, { headers: { "authorization" : `Bearer ${getLocalStorage()}`}, responseType: 'blob' })
            .then(res => {
                var imageUrl = URL.createObjectURL(res.data);
                setSignature(imageUrl);
                setValueQRCode(GenerateQRCode(infoUser, dataDoctorValuesOptions, dataNames));
                setOpenModal(true);
            }).catch(err => {
                console.log(err);
            });
        }
    }

    // const GenerateQRCode = () => {
    //     let sexDr = "Dr. ";
    //     if(infoUser.sex === "F") {
    //         sexDr = "Dra. ";
    //     }
    //     let infoInQR = sexDr+infoUser.name+" "+infoUser.lastname+"\n";
    //     for(let i in infoUser.qrcode) {
    //         if(infoUser.qrcode[i] === "dataDoctorValues") {
    //             for(let k in infoUser.qrcode[i]) {
    //                 infoInQR += dataDoctorValuesOptions[infoUser.country][k]+": "+infoUser["dataDoctorValues"][k]
    //             }
    //         }
    //         if(infoUser.qrcode[i] !== "name" && infoUser.qrcode[i] !== "lastname") {
    //             infoInQR += dataNames[infoUser.qrcode[i]]+": "+infoUser[infoUser.qrcode[i]]+"\n";
    //         }
    //     }
    //     setValueQRCode(infoInQR);
    // }

    const GeneratePDF = (toDo, share = false, howShare = false) => {
        if(toDo) {
            setLoadingAction(true);
        } else {
            setLoadingPDF(true);
        }
        axios.post(Global.url + 'generate-pdf', { recetaCreated: recetaCreated, cellphone: cellPatient, email: emailPatient, toDo: toDo, howShare: howShare, enableQr: infoUser.enableQrCode }, { headers: { "authorization" : `Bearer ${getLocalStorage()}`}, responseType: toDo === true ? 'json' : 'blob' })
            .then(res => {
                if(!toDo) {
                    if(share) {
                        try {
                            let shareDataFile = new File([res.data], `Receta__${recetaCreated[0].patient._id}__${moment().format("DD-MM-YYYY")}.pdf`, {lastModified: 1534584790000}, {type: res.data.type});
                            let shareData = { files: [shareDataFile] };
                            if(navigator.canShare && navigator.canShare(shareData)) {
                                shareData.title = "RECETA";
                                navigator.share(shareData).then(() => {
                                    console.log("OK")
                                }).catch(error => {
                                    console.log(error);
                                });
                            } else {
                                console.log("Your system doesn't support sharing files.");
                            }
                        } catch(error) {
                            console.log(error);
                        }
                    } else {
                        const url = window.URL.createObjectURL(res.data);
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', `Receta__${recetaCreated[0].patient._id}__${moment().format("DD-MM-YYYY")}.pdf`);
                        document.body.appendChild(link);
                        link.click();
                        DeletePDF(null);
                    }
                } else {
                    setSentRecet(true);
                    if(res.data.status) {
                        setIsOKSent("messageUserData__success");
                        DeletePDF(res.data.namesRecets);
                    } else {
                        setIsOKSent("messageUserData__fail");
                    }
                    setMsgSent(res.data.message);
                }
            }).catch(err => {
                setIsOKSent("messageUserData__fail");
                setMsgSent("Hubo un error interno en el servidor, por favor intente más tarde. ");
            }).finally(() => {
                if(toDo) {
                    setLoadingAction(false);
                } else {
                    setLoadingPDF(false);
                }
            });
    }

    const activateSharePatient = how => {
        setInfoPatientToShare(true);
        setHowShare(how);
    }

    const OpenModalFarmacia = () => {
        setContentModalPharmacy(<ContentModalPharmacy isOKSentPharmacy={isOKSentPharmacy} formErrorPharmacy={formErrorPharmacy} setEmailToSend={setEmailToSend} msgSentPharmacy={msgSentPharmacy} isOKSentPharmacy={isOKSentPharmacy} ifErrorSend={ifErrorSend} />)
        setOpenModalPharmacy(true);
    }

    const SendRecetToPharmacy = () => {
        if(!validateEmail(emailToSend)) {
            setFormErrorPharmacy(true);
            setIsOKSentPharmacy("messageUserData__fail");
            setMsgSentPharmacy("El email introducido no es correcto. ");
            setIfErrorSend(true);
        } else {
            setLoadingSend(true);
            axios.post(Global.url + 'send-pdf-pharmacy', { recetaCreated, emailToSend: emailToSend, enableQr: infoUser.enableQrCode }, { headers: { "authorization" : `Bearer ${getLocalStorage()}`} })
            .then(res => {
                if(res.data.status) {
                    setIsOKSent("messageUserData__success");
                    setMsgSent(res.data.message);
                    setIfErrorSend(false);
                    setOpenModalPharmacy(false);
                    setSentRecet(true);
                } else {
                    setIsOKSentPharmacy("messageUserData__fail");
                    setMsgSentPharmacy(res.data.message);
                }
            }).catch(err => {
                setIsOKSentPharmacy("messageUserData__fail");
                setMsgSentPharmacy("Hubo un error interno en el servidor, por favor intente más tarde. ");
            }).finally(() => {
                setLoadingSend(false);
            });
        }  
    }


    const DeletePDF = value => { 
        axios.post(Global.url + 'delete-pdf', { value: value }, { headers: { "authorization" : `Bearer ${getLocalStorage()}`} })
            .then(() => {
                // nothing to do
            }).catch(err => {
                setIsOKSent("messageUserData__fail");
                console.log(err);
        });
    }

    const movePage = direction => {
        if(direction) {
          if(actualPage !== countPages) {
            setOffset(offset + 10);
            setActualPage(actualPage + 1);
            setStartLimit(startLimit + 1);
          }
        } else {
          if(actualPage > 1) {
            setOffset(offset - 10);
            setActualPage(actualPage - 1);
            setStartLimit(startLimit - 1);
          }
        }
      }
    
    const goToPage = (result, page) => {
        setOffset(result);
        setActualPage(page);
    }

    useEffect(() => {
        setContentModalPharmacy(<ContentModalPharmacy isOKSentPharmacy={isOKSentPharmacy} formErrorPharmacy={formErrorPharmacy} setEmailToSend={setEmailToSend} msgSentPharmacy={msgSentPharmacy} isOKSentPharmacy={isOKSentPharmacy} ifErrorSend={ifErrorSend} />)
    }, [ifErrorSend, formErrorPharmacy, msgSentPharmacy]);

    return (
        <>
            <Header as='h3' className="headerUserPanel">
                Duplicar receta existente
            </Header>
            <Form>
                <Form.Input
                    onChange={e => searchPatient(e.target.value)}
                    fluid label='Paciente' 
                    name="patient"
                    value={searchValue}
                    error={formError.patient}
                    icon={searching ? "circle notch" : "search"}
                    placeholder='Buscar paciente (nombre, apellido o DNI)'
                />
                {gotValues &&
                    <div className="listValuesFound listValuesFound__Patients">
                        {resultsSearch.length === 0 && 
                            <div className="aValueFound">No se encontró ningún paciente que coincida. </div>
                        }
                        {resultsSearch.map((item) => (
                            <div 
                                onClick={() => selectPatient(item)} 
                                className="aValueFound"
                                key={item._id}
                            >
                                {item.name} {item.secondName} {item.lastname} {item.secondLastname} - <strong>{item.typeDNI}</strong>: {saniziteNumbers(item.dni)} - <strong>Obra social</strong>: {item.obrasocial}
                            </div>
                        ))}
                    </div>
                }
                {selectedPatient !== null &&
                    <div className="infoSelectedPatient">
                        <span className="textSelectedPatient"><strong>Paciente:</strong> <span>{selectedPatient.name} {selectedPatient.secondName} {selectedPatient.lastname} {selectedPatient.secondLastname}</span></span>
                        <span className="textSelectedPatient"><strong>Documento:</strong> <span>{selectedPatient.typeDNI} {saniziteNumbers(selectedPatient.dni)}</span></span>
                        <span className="textSelectedPatient"><strong>Obra social:</strong> <span>{selectedPatient.obrasocial} - {selectedPatient.nro}</span></span>
                    </div>
                }
                {doneMsg && 
                    <div className="messageDiv">
                        <Message className={isOK === "messageUserData__fail" ? "messageUserData messageUserData__fail" : "messageUserData messageUserData__success"}>
                            {msg}
                        </Message>
                    </div>
                }
                {doneMsg && isOK === "messageUserData__success" &&
                    <>
                        <div className="buttonsPDFGenerate">
                            <Button name="downloadPDF" loading={loadingPDF} disabled={loadingPDF} className="downloadPDF" onClick={() => GeneratePDF(false, false)}>Descargar {totalRecets === 1 ? "receta" : "1º receta"}</Button>
                            <Button name="sharePDF" className="sharePDF" onClick={() => activateSharePatient(false)}>Enviar {totalRecets === 1 ? "receta" : "recetas"} por email</Button>
                            {/* <Button name="shareWhatsApp" className="shareWhatsApp" onClick={() => activateSharePatient(true)}>Enviar {totalRecets === 1 ? "receta" : "recetas"} por WhatsApp <Icon name="whatsapp" /></Button> */}
                            {totalRecets !== 1 &&
                                <Message info>
                                    <Message.Content>Si necesitás descargar o enviar las otras recetas generadas, debes ir a Compartir Receta y seleccionar la receta correspondiente. </Message.Content>
                                </Message>
                            }
                        </div>
                        {infoPatientToShare &&
                            <div className="dataToShare">
                                {howShare &&
                                    <Form.Field>
                                        <div className="labelFormPhone">Número de celular a enviar por WhatsApp</div>
                                        <PhoneInput
                                            placeholder='Número de celular a enviar por WhatsApp'
                                            inputClass="inputStylePhone howShareInput"
                                            country='ar'
                                            preferredCountries={['ar', 'uy', 'cl', 'br', 'py', 'bo', 've', 'co', 'us', 'es', 'ec', 'pe', 'mx']}
                                            name="cellphone"
                                            localization={es}
                                            inputProps={{
                                                name: 'cellphone',
                                                required: true
                                            }}
                                            value={cellPatient}
                                            error={formError.cellphone}
                                            onChange={(value) => setCellPatient(value) }
                                        />
                                    </Form.Field>
                                }
                                {!howShare &&
                                    <Form.Input 
                                        className="howShareInput"
                                        fluid name="email"
                                        label='Email a enviar'
                                        placeholder='Email a enviar'
                                        error={formError.email}
                                        value={emailPatient}
                                        type="email"
                                        onChange={e => setEmailPatient(e.target.value) }
                                    />
                                }
                                <Button name="sendRecet" loading={loadingAction} disabled={loadingAction} className="sendRecet" onClick={() => GeneratePDF(true, false, howShare)}>Enviar</Button>
                                {sentRecet && 
                                    <div className="messageDiv">
                                        <Message className={isOKSent === "messageUserData__fail" ? "messageUserData messageUserData__fail" : "messageUserData messageUserData__success"}>
                                            {msgSent}
                                        </Message>
                                    </div>
                                }
                            </div>
                        }
                    </>
                }
            </Form>
            {openModalPharmacy &&
                <ModalComponent loading={loadingSend} openModal={openModalPharmacy} setOpenModal={setOpenModalPharmacy} titleModal="Ingrese el mail de la farmacia" size="tiny" contentYes="Enviar receta a la farmacia" functionToDo={SendRecetToPharmacy}>
                    {contentModalPharmacy}
                </ModalComponent>
            }
            <Table celled>
                <Table.Header>
                    <Table.Row>
                    <Table.HeaderCell colSpan='7'>Últimas recetas añadidas</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Header>
                    <Table.Row>
                    <Table.HeaderCell collapsing>Estado</Table.HeaderCell>
                    <Table.HeaderCell collapsing>Fecha de inicio</Table.HeaderCell>
                    <Table.HeaderCell>CIE10</Table.HeaderCell>
                    <Table.HeaderCell>Medicamento 1</Table.HeaderCell>
                    <Table.HeaderCell>Medicamento 2</Table.HeaderCell>
                    <Table.HeaderCell collapsing>Tiempo de validez</Table.HeaderCell>
                    <Table.HeaderCell collapsing>Opciones</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {allRecets?.length ?
                    allRecets.map((recet) => (
                        <RowTable key={recet._id} recet={recet} DuplicateReceta={DuplicateReceta} />
                    )) :
                    <Table.Row>
                        <Table.Cell colSpan="7"><div className="patientsNotFoundTable">No tenés ninguna receta cargada. </div></Table.Cell>
                    </Table.Row>
                    }
                </Table.Body>
                <Table.Footer>
                    <Table.Row>
                    <Table.HeaderCell colSpan='7'>
                        <Menu floated='right' pagination>
                            <Menu.Item as='a' icon onClick={() => movePage(false)}>
                                <Icon name='chevron left' />
                            </Menu.Item>
                            {Array.from(Array(2), (e, i) => (
                                ((startLimit+i) !== 0 && (startLimit+i) <= countPages) &&
                                (<Menu.Item as='a' key={startLimit+i} onClick={() => goToPage(((startLimit+i)*10-10), startLimit+i)} className={actualPage === (startLimit+i) ? "activeItemMenu" : "ItemMenu" }>{startLimit+i}</Menu.Item>)
                            ))}
                            <Menu.Item as='a' icon onClick={() => movePage(true)}>
                                <Icon name='chevron right' />
                            </Menu.Item>
                        </Menu>
                    </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>
            <ModalPrevisualizacion 
                valueQRCode={valueQRCode} 
                signature={signature} 
                selectedPatient={selectedPatient}
                openModal={openModal} 
                setOpenModal={setOpenModal}
                saveReceta={saveReceta} 
                infoUser={infoUser}
                formData={formData}
                selectedCie10={selectedCie10}    
            />
        </>
    )
}


function RowTable(props) {

    const { recet, DuplicateReceta, formData } = props;

    let colorDefault = "tableWaitingRecet";
    let stateRecet = "En revisión";
    if(recet.approved === "APPROVED") {
        colorDefault = "tableApprovedRecet";
        stateRecet = "Aprobada";
    } else if(recet.approved === "WAITING") {
        colorDefault = "tableWaitingRecet";
        stateRecet = "En Revisión";
    } else if(moment().isAfter(moment(recet.startDate).add(recet.duration, "days"))) {
        colorDefault = "tableExpiredRecet";
        stateRecet = "Expirada";
    }

    const durations = [
        { key: 30, value: 30, text: '30 días' },
        { key: 60, value: 60, text: '60 días' },
        { key: 90, value: 90, text: '90 días' },
      ]

    const updateDurationRecet = value => {
        recet.duration = value;
    }
    
    return(
      <Table.Row className={colorDefault}>
        <Table.Cell>{stateRecet}</Table.Cell>
        <Table.Cell>{moment(recet.startDate).format("DD/MM/YYYY")}</Table.Cell>
        <Table.Cell>{recet.diagnostic}</Table.Cell>
        <Table.Cell>{recet.remedio1}</Table.Cell>
        <Table.Cell>{recet.remedio2}</Table.Cell>
        <Table.Cell>
            <Select className="selectDurationTable" defaultValue={recet.duration} options={durations} onChange={(e, {value}) => updateDurationRecet(value)} />
        </Table.Cell>
        <Table.Cell>
            <Button className="tableBtn" onClick={() => DuplicateReceta(recet)}>Duplicar</Button>
        </Table.Cell>
      </Table.Row>
    )
  }


  function ContentModalPharmacy(props) {
    const { setEmailToSend, formErrorPharmacy, ifErrorSend, isOKSentPharmacy, msgSentPharmacy } = props;
    const [input, setInput] = useState("");
    useEffect(() => {
        setEmailToSend(input);
    }, [input]);
    return (
        <div>
            <Header as="h4">Ingrese el mail de la farmacia a la que querés enviar la receta: </Header>
            <Input error={formErrorPharmacy} icon='at' placeholder="Email de la farmacia" className="styleInputEmailPharmacy" type="email" name="emailToSend" onChange={e => setInput(e.target.value)} value={input} />
            {ifErrorSend &&
                <div className="centerMsgModal">
                    <Message className={isOKSentPharmacy === "messageUserData__fail" ? "messageUserData messageUserData__fail" : "messageUserData messageUserData__success"}>
                        {msgSentPharmacy}
                    </Message>
                </div>
            }
        </div>
    );
}