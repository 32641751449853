import React, { useState, useEffect } from 'react';

import Global from "../../utils/Global";
import axios from "axios";

import { Container, Segment, Dimmer, Loader } from "semantic-ui-react";

import "./Test.scss";

export default function Test() {

    const [results, setResults] = useState([]);
    const [done, setDone] = useState(false);

    useEffect(() => {
        let auxResult;
        axios.post(Global.url + 'step-1', null)
            .then(res => {
                auxResult = results;
                auxResult.push({
                    "title": res.data.title,
                    "message": res.data.message
                });
                setResults(auxResult);
                if(res.data.status) {
                    Step2();
                } else {
                    setDone(true);
                }
            }).catch(err => {
                console.log(err);
                setDone(true);
            })
    }, []);

    const Step2 = () => {
        let auxResult;
        axios.post(Global.url + 'step-2', null)
            .then(res => {
                auxResult = results;
                auxResult.push({
                    "title": res.data.title,
                    "message": res.data.message
                });
                setResults(auxResult);
                if(res.data.status) {
                    Step3();
                } else {
                    setDone(true);
                }
            }).catch(err => {
                console.log(err);
                setDone(true);
        })
    }

    const Step3 = () => {
        let auxResult;
        axios.post(Global.url + 'step-3', null)
            .then(res => {
                auxResult = results;
                auxResult.push({
                    "title": res.data.title,
                    "message": res.data.message
                });
                setResults(auxResult);
                setDone(true);
            }).catch(err => {
                console.log(err);
                setDone(true);
        })
    }

    return (
        <Container className="bgDashboard" style={{
            paddingTop: 40,
            fontSize: "1.5em"
        }}>
            
            {done === false &&
                <Dimmer.Dimmable as={Segment} className="installApp" dimmed={true}>
                    <Dimmer active inverted>
                        <Loader indeterminate size='big'>Instalando, esto puede llevar un tiempo...</Loader>
                    </Dimmer>
                </Dimmer.Dimmable>
            }
            {done === true &&
                results.map((result, i) => (
                    <div className="steps" key={i}>
                        {result.title}{result.message}
                    </div>
                ))
            }
        </Container>
    )
}
