import React, { useState, useEffect } from 'react';
import { Form, Header, Message, Button, Table, Menu, Icon } from "semantic-ui-react";
import ModalComponent from '../../../ModalComponent/ModalComponent';
import "./DownloadReceta.scss";
import PhoneInput from 'react-phone-input-2';
import es from 'react-phone-input-2/lang/es.json';
import moment from "moment";
import axios from "axios";
import Global from "../../../../utils/Global";
import { getLocalStorage } from '../../../../utils/localStorage';
import { saniziteNumbers } from "../../../../utils/saniziteValues";

export default function DuplicateReceta(props) {

    const { infoUser } = props;

    const [allRecets, setAllRecets] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingAction, setLoadingAction] = useState(false);

    const [sentRecet, setSentRecet] = useState(false);
    const [msgSent, setMsgSent] = useState("");
    const [isOKSent, setIsOKSent] = useState("messageUserData__fail");

    const [selectedRecetToSend, setSelectedRecetToSend] = useState(undefined);
    const [selectedRecetToDownload, setSelectedRecetToDownload] = useState(undefined);

    const [resultsSearch, setResultsSearch] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [searching, setSearching] = useState(false);
    const [gotValues, setGotValues] = useState(false);
    const [selectedPatient, setSelectedPatient] = useState(null);
    const [selectedCie10, setSelectedCie10] = useState(null);

    const [formError, setFormError] = useState({});

    const [howShare, setHowShare] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    const [offset, setOffset] = useState(0);
    const [countPages, setCountPages] = useState(0);
    const [actualPage, setActualPage] = useState(1);
    const [startLimit, setStartLimit] = useState(1);

    useEffect(() => {
        if(selectedPatient !== null) {
            axios.post(Global.url + 'count-recets-patient', { selectedPatient: selectedPatient, excluded: "CANCELED" }, { headers: { "authorization" : `Bearer ${getLocalStorage()}`} })
                .then(res => {
                    setCountPages(res.data.result);
                }).catch(err => {
                    setCountPages(0);
                });
        }
    }, [selectedPatient]);

    useEffect(() => {
        if(selectedPatient !== null) {
            axios.post(Global.url + 'load-recets-patient', { offset: offset, selectedPatient: selectedPatient, excluded: "CANCELED" }, { headers: { "authorization" : `Bearer ${getLocalStorage()}`} })
                .then(res => {
                    setAllRecets(res.data.result);
                }).catch(err => {
                    setAllRecets([]);
                });
        } else {
            setAllRecets([]);
        }
    }, [selectedPatient, countPages, actualPage]);


    const searchPatient = value => {
        setSearchValue(value);
        setSearching(true);
        setGotValues(false);
        if(value.length >= 2) {
            setTimeout(function() {
                axios.post(Global.url + 'search-patient', { search: value }, { headers: { "authorization" : `Bearer ${getLocalStorage()}`} })
                    .then(res => {
                        setResultsSearch(res.data.results);
                    }).catch(err => {
                        setResultsSearch([]);
                    }).finally(() => {
                        setSearching(false);
                        setGotValues(true);
                });
            }, 300);
        } else {
            setSearching(true);
            setGotValues(false);
        }
    }

    const selectPatient = item => {
        setSearchValue("");
        setSelectedPatient(item);
        setGotValues(false);
        setResultsSearch([]);
    }

    const DownloadReceta = (recet) => {
        if(!selectedPatient) {
            setFormError({ patient: "El campo paciente es obligatorio. "});
        } else {
            setSelectedRecetToDownload(recet._id);
            setLoading(true);
            axios.post(Global.url + 'download-pdf', { recet: recet, mobile: false, email: false, enableQr: infoUser.enableQrCode }, { headers: { "authorization" : `Bearer ${getLocalStorage()}`}, responseType: 'blob' })
            .then(res => {
                const url = window.URL.createObjectURL(res.data);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Receta__${recet._id}__${moment().format("DD-MM-YYYY")}.pdf`);
                document.body.appendChild(link);
                link.click();
            }).catch(err => {
                console.log(err);
            }).finally(() => {
                setSelectedRecetToDownload(undefined);
                setLoading(false);
                DeletePDF(null);
            });
        }
    }

    const SendPDF = howShare => {
        setLoadingAction(true);
        axios.post(Global.url + 'send-pdf', { recetaToSend: selectedRecetToSend, howShare: howShare, enableQr: infoUser.enableQrCode }, { headers: { "authorization" : `Bearer ${getLocalStorage()}`} })
            .then(res => {
                if(res.data.status) {
                    setIsOKSent("messageUserData__success");
                } else {
                    setIsOKSent("messageUserData__fail");
                }
                setMsgSent(res.data.message);
            }).catch(err => {
                setIsOKSent("messageUserData__fail");
                setMsgSent("Hubo un error interno en el servidor, por favor intente más tarde. ");
            }).finally(() => {
                setSentRecet(true);
                setLoadingAction(false);
                DeletePDF(null);
            });
    }

    const DeletePDF = value => { 
        axios.post(Global.url + 'delete-pdf', { value: value }, { headers: { "authorization" : `Bearer ${getLocalStorage()}`} })
            .then(() => {
                // nothing to do
            }).catch(err => {
                console.log(err);
            });
    }

    const movePage = direction => {
        if(direction) {
          if(actualPage !== countPages) {
            setOffset(offset + 10);
            setActualPage(actualPage + 1);
            setStartLimit(startLimit + 1);
          }
        } else {
          if(actualPage > 1) {
            setOffset(offset - 10);
            setActualPage(actualPage - 1);
            setStartLimit(startLimit - 1);
          }
        }
      }
    
    const goToPage = (result, page) => {
        setOffset(result);
        setActualPage(page);
    }

    const actionModalPage = (howShare, recet) => {
        let auxRecet = {
            patient: selectedPatient,
            doctor: infoUser,
            receta: recet 
        }
        setSelectedRecetToSend(auxRecet);
        setHowShare(howShare);
        setOpenModal(true);
    }

    return (
        <>
            <Header as='h3' className="headerUserPanel">
                Compartir receta existente
            </Header>
            <Form>
                <Form.Input
                    onChange={e => searchPatient(e.target.value)}
                    fluid label='Paciente' 
                    name="patient"
                    value={searchValue}
                    error={formError.patient}
                    icon={searching ? "circle notch" : "search"}
                    placeholder='Buscar paciente (nombre, apellido o DNI)'
                />
                {gotValues &&
                    <div className="listValuesFound listValuesFound__Patients">
                        {resultsSearch.length === 0 && 
                            <div className="aValueFound">No se encontró ningún paciente que coincida. </div>
                        }
                        {resultsSearch.map((item) => (
                            <div 
                                onClick={() => selectPatient(item)} 
                                className="aValueFound"
                                key={item._id}
                            >
                                {item.name} {item.secondName} {item.lastname} {item.secondLastname} - <strong>{item.typeDNI}</strong>: {saniziteNumbers(item.dni)} - <strong>Obra social</strong>: {item.obrasocial}
                            </div>
                        ))}
                    </div>
                }
                {selectedPatient !== null &&
                    <div className="infoSelectedPatient">
                        <span className="textSelectedPatient"><strong>Paciente:</strong> <span>{selectedPatient.name} {selectedPatient.secondName} {selectedPatient.lastname} {selectedPatient.secondLastname}</span></span>
                        <span className="textSelectedPatient"><strong>Documento:</strong> <span>{selectedPatient.typeDNI} {saniziteNumbers(selectedPatient.dni)}</span></span>
                        <span className="textSelectedPatient"><strong>Obra social:</strong> <span>{selectedPatient.obrasocial} - {selectedPatient.nro}</span></span>
                    </div>
                }
                {/* {sentRecet && 
                    <div className="messageDiv">
                        <Message className={isOKSent === "messageUserData__fail" ? "messageUserData messageUserData__fail" : "messageUserData messageUserData__success"}>
                            {msgSent}
                        </Message>
                    </div>
                } */}
            </Form>
            <Table celled>
                <Table.Header>
                    <Table.Row>
                    <Table.HeaderCell colSpan='7'>Últimas recetas añadidas</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Header>
                    <Table.Row>
                    <Table.HeaderCell collapsing>Estado</Table.HeaderCell>
                    <Table.HeaderCell collapsing>Fecha de inicio</Table.HeaderCell>
                    <Table.HeaderCell>CIE10</Table.HeaderCell>
                    <Table.HeaderCell>Medicamento 1</Table.HeaderCell>
                    <Table.HeaderCell>Medicamento 2</Table.HeaderCell>
                    <Table.HeaderCell collapsing>Fecha vencimiento</Table.HeaderCell>
                    <Table.HeaderCell collapsing>Opciones</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {allRecets?.length ?
                        allRecets.map((recet) => (
                            <RowTable 
                                key={recet._id}
                                loading={loading}
                                recet={recet}
                                DownloadReceta={DownloadReceta}
                                selectedRecetToDownload={selectedRecetToDownload}
                                actionModal={actionModalPage}
                            />
                        )) :
                        <Table.Row>
                            <Table.Cell colSpan="7"><div className="patientsNotFoundTable">No tenés ninguna receta cargada. </div></Table.Cell>
                        </Table.Row>
                    }
                </Table.Body>
                <Table.Footer>
                    <Table.Row>
                    <Table.HeaderCell colSpan='7'>
                        <Menu floated='right' pagination>
                        <Menu.Item as='a' icon onClick={() => movePage(false)}>
                            <Icon name='chevron left' />
                        </Menu.Item>
                        {Array.from(Array(2), (e, i) => (
                            ((startLimit+i) !== 0 && (startLimit+i) <= countPages) &&
                            (<Menu.Item as='a' key={startLimit+i} onClick={() => goToPage(((startLimit+i)*10-10), startLimit+i)} className={actualPage === (startLimit+i) ? "activeItemMenu" : "ItemMenu" }>{startLimit+i}</Menu.Item>)
                        ))}
                        <Menu.Item as='a' icon onClick={() => movePage(true)}>
                            <Icon name='chevron right' />
                        </Menu.Item>
                        </Menu>
                    </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>
            {selectedPatient !== null && 
                <ModalComponent loading={loading} titleModal="Enviar receta" contentYes="Cerrar" size="small" openModal={openModal} setOpenModal={setOpenModal} includeActions={false}>
                    <div className="dataToShare dataToShare__Modal">
                        {howShare &&
                            <Form.Field>
                                <div className="labelFormPhone">Número de celular a enviar por WhatsApp</div>
                                <PhoneInput
                                    placeholder='Número de celular a enviar por WhatsApp'
                                    inputClass="inputStylePhone howShareInput"
                                    country='ar'
                                    preferredCountries={['ar', 'uy', 'cl', 'br', 'py', 'bo', 've', 'co', 'us', 'es', 'ec', 'pe', 'mx']}
                                    name="cellphone"
                                    localization={es}
                                    inputProps={{
                                        name: 'cellphone',
                                        required: true
                                    }}
                                    value={selectedRecetToSend?.patient.cellphone}
                                    error={formError.cellphone}
                                    onChange={(value) => setSelectedRecetToSend({...selectedRecetToSend, "patient": { ...selectedRecetToSend["patient"], cellphone: value } }) }
                                />
                            </Form.Field>
                        }
                        {!howShare &&
                            <Form.Input 
                                className="howShareInput"
                                fluid name="email"
                                label='Email a enviar'
                                placeholder='Email a enviar'
                                error={formError.email}
                                value={selectedRecetToSend?.patient.email}
                                type="email"
                                onChange={e => setSelectedRecetToSend({...selectedRecetToSend, "patient": { ...selectedRecetToSend["patient"], [e.target.name]: e.target.value } }) }
                            />
                        }
                        <Button name="sendRecet" loading={loadingAction} disabled={loadingAction} className="sendRecet" onClick={() => SendPDF(howShare, selectedRecetToSend)}>Enviar</Button>
                        {sentRecet && 
                            <div className="messageDiv">
                                <Message className={isOKSent === "messageUserData__fail" ? "messageUserData messageUserData__fail" : "messageUserData messageUserData__success"}>
                                    {msgSent}
                                </Message>
                            </div>
                        }
                    </div>
                </ModalComponent>
            }
        </>
    )
}


function RowTable(props) {

    const { recet, DownloadReceta, actionModal, selectedRecetToDownload, loading } = props;

    let colorDefault = "tableWaitingRecet";
    let stateRecet = "En revisión";
    if(recet.approved === "APPROVED") {
        colorDefault = "tableApprovedRecet";
        stateRecet = "Aprobada";
    } else if(recet.approved === "WAITING") {
        colorDefault = "tableWaitingRecet";
        stateRecet = "En Revisión";
    } else if(moment().isAfter(moment(recet.startDate).add(recet.duration, "days"))) {
        colorDefault = "tableExpiredRecet";
        stateRecet = "Expirada";
    }
    
    return(
      <Table.Row className={colorDefault}>
        <Table.Cell>{stateRecet}</Table.Cell>
        <Table.Cell>{moment(recet.startDate).format("DD/MM/YYYY")}</Table.Cell>
        <Table.Cell>{recet.diagnostic}</Table.Cell>
        <Table.Cell>{recet.remedio1}</Table.Cell>
        <Table.Cell>{recet.remedio2}</Table.Cell>
        <Table.Cell>{moment(recet.startDate).add(30, "days").format("DD/MM/YYYY")}</Table.Cell>
        <Table.Cell className="buttonsOptions">
            <Button className="tableBtnOptions tableBtnOptions__Download" onClick={() => DownloadReceta(recet)} loading={selectedRecetToDownload === recet._id ? loading : false} disabled={selectedRecetToDownload === recet._id ? loading : false}>Descargar</Button>
            <Button className="tableBtnOptions tableBtnOptions__Email" onClick={() => actionModal(false, recet)}>Email</Button>
            {/* <Button className="tableBtnOptions tableBtnOptions__WhatsApp" onClick={() => actionModal(true, recet)}>WhatsApp</Button> */}
        </Table.Cell>
      </Table.Row>
    )
  }