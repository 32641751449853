import React, { useState, useEffect } from 'react';
import "./UpdateSuscription.scss";
import { Grid, Segment, List, Header, Button } from "semantic-ui-react";
import { isMobile } from "react-device-detect";
import axios from "axios";
import Global from "../../../utils/Global";
import { getLocalStorage } from "../../../utils/localStorage";
import { Prices } from "../../../utils/ValuesOptions";

export default function UpdateSuscription(props) {

    const { infoUser, setInfoUser, setActiveItem } = props;

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(infoUser.typeSuscription !== "BASIC") {
            setActiveItem("home");
        }
    });

    const updSus = type => {
        setLoading(true);
        axios.post(Global.url + 'update-subscriber', { suscription: type, infoUser: infoUser }, { headers: { "authorization" : `Bearer ${getLocalStorage()}`} })
            .then(res => {
                setTimeout(function() {
                    setLoading(false);
                    window.location.replace(res.data.result.sourceUrl);
                }, 1000);
            }).catch(err => {
                console.log(err);
        });
    }

    return (
        <>
            <div className={isMobile ? "subtitlePricingDiv subtitlePricingDiv__Mobile" : "subtitlePricingDiv"}>Contamos con dos tipos de suscripciones para utilizar nuestro servicio.</div>
            <Grid className="gridUser gridCentered gridUpdateSuscription" columns='equal'>
                <div className={isMobile ? "segmentsPricing segmentsPricing__Mobile" : "segmentsPricing"}>
                    <div className={isMobile ? "divSegment divSegment__Mobile divSegment__month divSegment__month__Mobile" : "divSegment divSegment__month"}>
                        <Segment className={isMobile ? "pricingSegment pricingSegment__1 pricingSegment__1__Mobile" : "pricingSegment pricingSegment__1"}>
                            <Header as='h2' className="titleHeaderSegment titleHeaderSegment__month">Suscripción mensual</Header>
                            <List bulleted divided className="listSegment">
                                <List.Item className="listElement">Pacientes y recetas ilimitadas. </List.Item>
                                {/* <List.Item className="listElement">Acceso a nuestras planillas. </List.Item> */}
                                <List.Item className="listElement">Primeros 7 días gratuito. </List.Item>
                                <List.Item className="listElement">Soporte las 24/7. </List.Item>
                                {/* <List.Item className="listElement">Con posibilidad de enviar por WhatsApp. </List.Item> */}
                            </List>
                            <Header.Subheader className={isMobile ? "priceSegUp priceSegment priceSegment__Mobile" : "priceSegUp priceSegment"}>ARS {Prices[0]} / mes</Header.Subheader>
                            <Header.Subheader as={Button} loading={loading} disabled={loading} className="priceSegUp priceSegmentRegister" onClick={() => updSus("month")}>Contratar ahora</Header.Subheader>
                            <div className={isMobile ? "extraDivPricing extraDivPricing__Mobile" : "extraDivPricing"}>Se puede desactivar la renovación automática en Config. de pagos en Mi Cuenta.</div>
                        </Segment>
                    </div>
                    <div className={isMobile ? "divSegment divSegment__Mobile divSegment__year divSegment__year__Mobile" : "divSegment divSegment__year"}>
                        <Segment className="pricingSegment pricingSegment__2">
                            <Header as='h2' className="titleHeaderSegment titleHeaderSegment__year">Suscripción anual</Header>
                            <div className="free3months">¡3 meses gratis!</div>
                            <List bulleted divided className="listSegment">
                                <List.Item className="listElement">Pacientes y recetas ilimitadas. </List.Item>
                                {/* <List.Item className="listElement">Acceso a nuestras planillas. </List.Item> */}
                                <List.Item className="listElement">Primeros 7 días gratuito. </List.Item>
                                <List.Item className="listElement">Soporte las 24/7. </List.Item>
                                {/* <List.Item className="listElement">Con posibilidad de enviar por WhatsApp. </List.Item> */}
                            </List>
                            <Header.Subheader className={isMobile ? "priceSegUp priceSegment priceSegment__Mobile" : "priceSegUp priceSegment"}>ARS {Prices[1]} / año</Header.Subheader>
                            <Header.Subheader as={Button} loading={loading} disabled={loading} className="priceSegUp priceSegmentRegister" onClick={() => updSus("year")}>Contratar ahora</Header.Subheader>
                            <div className={isMobile ? "extraDivPricing extraDivPricing__Mobile" : "extraDivPricing"}>Se puede desactivar la renovación automática en Config. de pagos en Mi Cuenta.</div>
                        </Segment>
                    </div>
                </div>
            </Grid>
            <div className="noThanks" onClick={() => setActiveItem("home")}>No, seguir con mi plan gratuito. </div>
        </>
    )
}
