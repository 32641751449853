import React from 'react';

import logo from "../../../assets/images/logo.png";
import { Grid, Card, Image } from "semantic-ui-react";
import ScrollAnimation from 'react-animate-on-scroll';
import { isMobile } from "react-device-detect";

import img1 from "../../../assets/images/imagen1.jpg";
import img2 from "../../../assets/images/imagen2.jpg";
import img3 from "../../../assets/images/imagen3.jpg";

import "./Whoarewe.scss";

export default function Whoarewe() {
    return (
        <div className="whoarewe">
            <div className="figure3 figure3__left" />
            <div className="figure3 figure3__right" />
            <div className={isMobile ? "titleWho titleWho__Mobile" : "titleWho" }>
                ¿Qué ofrece <Image src={logo} className="logoWhoAre" /> a los médicos?
            </div>
            <div className={isMobile ? "textContentWho textContentWho__Mobile"  : "textContentWho"}>
                Nuestra misión es optimizar y hacer eficiente el trabajo de los médicos y consultorios privados a través de la digitalización de las tareas cotidianas. Para cumplir dicha misión, ofrecemos un tablero digital para que puedas consultar, crear y guardar toda la información de tus pacientes y recetas como una base de datos y poder duplicarlas y/o enviar a tus pacientes con un simple clic, sin necesidad contacto físico y de escribir manualmente y constantemente lo mismo.
            </div>
            <div>
                <Grid className="gridContentWho" columns={isMobile ? 1 : "equal"}>
                    <Grid.Column>
                        <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                            <Card className="cardContentWho cardContentWho__teal">
                                <Card.Content extra>
                                   <Image src={img1} />
                                </Card.Content>
                                <Card.Content className="headerContentCard" header='Pacientes' />
                                <Card.Content description="Almacená el historial de tus pacientes." />
                            </Card>
                        </ScrollAnimation>
                    </Grid.Column>
                    <Grid.Column>
                        <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={400}>
                            <Card className="cardContentWho cardContentWho__blue">
                                <Card.Content extra>
                                    <Image src={img2} />
                                </Card.Content>
                                <Card.Content className="headerContentCard" header='Recetas' />
                                <Card.Content description="Creá, duplicá y enviá recetas." />
                            </Card>
                        </ScrollAnimation>
                    </Grid.Column>
                    {/* <Grid.Column>
                        <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={isMobile ? 300 : 900}>
                            <Card className="cardContentWho cardContentWho__violet">
                                <Card.Content extra>
                                    <Image src={img3} />
                                </Card.Content>
                                <Card.Content className="headerContentCard" header='Planillas' />
                                <Card.Content description="Con nuestros modelos de planillas, creá y almacená información médica de tus pacientes. " />
                            </Card>
                        </ScrollAnimation>
                    </Grid.Column> */}
                </Grid>
            </div>
        </div>
    )
}
