import React, { useState, useEffect } from 'react';
import { Grid, Menu } from "semantic-ui-react";
import { isMobile } from "react-device-detect";
import { useParams } from "react-router-dom";
import "./User.scss";
//components
import Personal from "./Personal";
import Privacity from "./Privacity";
import ConfigUser from "./ConfigUser";
import ConfigPayment from "./ConfigPayment";

export default function Home(props) {

    const { setInfoUser, infoUser, setActiveItem } = props;

    let { subpath } = useParams();

    const [menuUser, setMenuUser] = useState(subpath !== undefined ? subpath : "personal");

    return (
        <Grid className="gridUser" columns='equal'>
            <Grid.Column className={isMobile ? "menuVertical" : "menuUserGrid"} width={isMobile ? 16 : 4}>
                <Menu fluid vertical tabular={isMobile ? false : true} style={{ minHeight: isMobile ? "auto" : "67vh", height: "100%" }}>
                    <Menu.Item 
                        name="Datos personales"
                        content="Datos personales"
                        active={menuUser === "personal"}
                        onClick={() => setMenuUser("personal")}
                    />
                    <Menu.Item 
                        name="Privacidad"
                        active={menuUser === "privacity"}
                        onClick={() => setMenuUser("privacity")}
                    />
                    <Menu.Item 
                        name="Configuración de cuenta"
                        content="Configuración de cuenta"
                        active={menuUser === "config"}
                        onClick={() => setMenuUser("config")}
                    />
                    <Menu.Item 
                        name="Configuración de pagos"
                        content="Configuración de pagos"
                        active={menuUser === "payments"}
                        onClick={() => setMenuUser("payments")}
                    />
                </Menu>
            </Grid.Column>
            <Grid.Column className="contentUserGrid">
                {(() => {
                    if (menuUser === "personal") {
                        return <Personal setInfoUser={setInfoUser} infoUser={infoUser} />
                    } else if (menuUser === "config") {
                        return <ConfigUser infoUser={infoUser} />
                    } else if(menuUser === "privacity") {
                        return <Privacity setInfoUser={setInfoUser} infoUser={infoUser} />
                    } else if(menuUser === "payments") {
                        return <ConfigPayment setInfoUser={setInfoUser} infoUser={infoUser} setActiveItem={setActiveItem} />
                    } else {
                        return <Personal setInfoUser={setInfoUser} infoUser={infoUser} />
                    }
                })()}
            </Grid.Column>
        </Grid>
    )
}
