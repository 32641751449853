import React, { useState, useEffect } from 'react';
import { Header, Form, Message, Input, Button, Select, Divider } from "semantic-ui-react";
import produce from "immer";
import { isMobile } from "react-device-detect";
import axios from "axios";
import Global from "../../../../utils/Global";
import { getLocalStorage } from "../../../../utils/localStorage";
import ReactJson from 'react-json-view';
import "./Modules.scss";

export default function CreatePlanilla(props) {

    const { infoUser } = props;

    const [doneMsg, setDoneMsg] = useState(false);
    const [isOK, setIsOK] = useState("messageUserData__fail");
    const [msg, setMsg] = useState("");
    const [loading, setLoading] = useState(false);
    const [formError, setFormError] = useState({});
    const [formData, setFormData] = useState({
        "name": ""
    });

    const actionModule = action => {
        // if(!selectedRemedio) {
        //     setFormError({ remedio: "Este campo es obligatorio. "});
        // } else if(!selectedObraSocial) {
        //     setFormError({ obrasocial: "Este campo es obligatorio. "});
        // } else {
        //     setLoading(true);
        //     //TENEMOS QUE ENVIAR EL ID DE REMEDIO Y OBRA SOCIAL
        //     axios.post(Global.url + 'create-new-planilla', { formData: formData, remedio: selectedRemedio, obrasocial: selectedObraSocial }, { headers: { "authorization" : `Bearer ${getLocalStorage()}`} })
        //         .then(res => {
        //             setMsg(res.data.message);
        //             if(res.data.status) {
        //                 setIsOK("messageUserData__success");
        //                 setFormData({
        //                     "plan": "",
        //                     "discount": "",
        //                     "obs": ""
        //                 });
        //             } else {
        //                 setIsOK("messageUserData__fail");
        //             }
        //         }).catch(err => {
        //             setIsOK("messageUserData__fail");
        //             console.log(err);
        //         }).finally(() => {
        //             setDoneMsg(true);
        //             setLoading(false);
        //         });
        // }
    }

    return (
        <>
        <Header as='h3' className="headerUserPanel">
            Sección de módulos de planillas
        </Header>
        <Form>
            ACÁ VAN EL ABM DE MODULOS, TODO EN UNO.
            {doneMsg && 
                <div className="messageDiv">
                    <Message className={isOK === "messageUserData__fail" ? "messageUserData messageUserData__fail" : "messageUserData messageUserData__success"}>
                        {msg}
                    </Message>
                </div>
            }
            <Form.Button loading={loading} disabled={loading} onClick={() => actionModule()} className="btnSubmit btnChangePersonalDate">Guardar modelo de planilla</Form.Button>
        </Form>
        </>
    )
}
