import React, { useState, useEffect } from 'react';

import { Header, Form, Message } from "semantic-ui-react";

import { isMobile } from "react-device-detect";

import axios from "axios";
import Global from "../../../../utils/Global";
import { getLocalStorage } from "../../../../utils/localStorage";

import "./AddObraSocial.scss";

export default function AddObraSocial(props) {

    const { infoUser } = props;

    const [doneMsg, setDoneMsg] = useState(false);
    const [isOK, setIsOK] = useState("messageUserData__fail");
    const [msg, setMsg] = useState("");

    const [loading, setLoading] = useState(false);

    const [options, setOptions] = useState([]);
    const [currentValues, setCurrentValues] = useState([]);

    const [formError, setFormError] = useState({});
    const [formData, setFormData] = useState({
        "name": "",
        "plans": []
    });

    const setSelectedDropdown = (e, { value }) => {
        setCurrentValues(value);
    }

    const renderLabel = (label) => ({
        color: 'blue',
        content: `${label.text}`,
        icon: 'check',
    });
    
    const addItemDropdown = (e, { value }) => {
        let auxOptions = options;
        auxOptions.push({
            "key": value,
            "text": value,
            "value": value
        });
        setOptions(auxOptions);
    }

    const saveData = () => {
        if(!formData.name) {
            setFormError({ name: "Este campo es obligatorio. "});
        } else {
            formData.plans = currentValues;
            setLoading(true);
            axios.post(Global.url + 'add-new-obrasocial', formData, { headers: { "authorization" : `Bearer ${getLocalStorage()}`} })
                .then(res => {
                    setMsg(res.data.message);
                    if(res.data.status) {
                        setIsOK("messageUserData__success");
                        setFormData({
                            "name": "",
                            "plans": []
                        });
                    } else {
                        setIsOK("messageUserData__fail");
                    }
                }).catch(err => {
                    setIsOK("messageUserData__fail");
                    console.log(err);
                }).finally(() => {
                    setDoneMsg(true);
                    setLoading(false);
                });
        }
    }

    return (
        <>
        <Header as='h3' className="headerUserPanel">
            Agregar nueva obra social
        </Header>
        <Form>
            <Form.Group widths='equal'>
                <Form.Input 
                    fluid label='Nombre de la obra social' 
                    name="name"
                    required
                    value={formData.name}
                    placeholder='Nombre de la obra social'
                    onChange={e => setFormData({...formData, [e.target.name]: e.target.value}) }
                />
            </Form.Group>
            <Form.Group widths='equal'>
                <Form.Dropdown
                    options={options}
                    fluid label='Planes de la obra social'
                    name="plans"
                    search
                    multiple
                    selection
                    allowAdditions
                    value={currentValues}
                    placeholder='Planes de la obra social'
                    onAddItem={addItemDropdown}
                    renderLabel={renderLabel}
                    onChange={setSelectedDropdown}
                />
            </Form.Group>
            {doneMsg && 
                <div className="messageDiv">
                    <Message className={isOK === "messageUserData__fail" ? "messageUserData messageUserData__fail" : "messageUserData messageUserData__success"}>
                        {msg}
                    </Message>
                </div>
            }
            <Form.Button loading={loading} disabled={loading} onClick={() => saveData()} className="btnSubmit btnChangePersonalDate">Agregar nueva obra social</Form.Button>
        </Form>
        </>
    )
}
