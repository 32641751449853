import React, { useState } from 'react';
import { Form, Header, Message, Select } from "semantic-ui-react";
import PhoneInput from 'react-phone-input-2';
import es from 'react-phone-input-2/lang/es.json';
import moment from "moment";
import axios from "axios";
import Global from "../../../../utils/Global";
import { getLocalStorage } from '../../../../utils/localStorage';
import { validateEmail, validateNumber } from '../../../../utils/Validations';
import { dniOptions, sexOptions } from '../../../../utils/ValuesOptions';
import "./New.scss";

export default function New(props) {

    const { setInfoUser } = props;

    const [doneMsg, setDoneMsg] = useState(false);
    const [isOK, setIsOK] = useState("messageUserData__fail");
    const [msg, setMsg] = useState("");

    const [loading, setLoading] = useState(false);

    const [formError, setFormError] = useState({});
    const [formData, setFormData] = useState({
        "name": "",
        "secondName": "",
        "lastname": "",
        "secondLastname": "",
        "dateBirth": "",
        "sex": "",
        "typeDNI": "",
        "dni": "",
        "obrasocial": "",
        "nro": "",
        "cellphone": "",
        "email": "",
        "qrPhotoPami": "",
        "codePamiQR": ""
    });

    const saveData = () => {
        if(!formData.name) {
            setFormError({ name: "Este campo es obligatorio. "});
        } else if(!formData.lastname) {
            setFormError({ lastname: "Este campo es obligatorio. "});
        } else if(moment(formData.dateBirth).isValid() && moment(formData.dateBirth).isSameOrAfter(moment().format("YYYY-MM-DD"))) {
            setFormError({ dateBirth: "La fecha de nacimiento no puede ser posterior a "+moment().format("YYYY-MM-DD")});
        } else if(!formData.sex || (formData.sex !== "M" && formData.sex !== "F")) {
            setFormError({ sex: "El sexo introducido no es correcto. "});
        } else if(!formData.typeDNI) {
            setFormError({ typeDNI: "Debe introducir el tipo de documento del paciente. "});
        } else if(!formData.dni && !validateNumber(formData.dni)) {
            setFormError({ dni: "El DNI introducido no es correcto. Solo se debe introducir números. "});
        } else if(formData.email && !validateEmail(formData.email)) {
            setFormError({ emailJob: "El email no es correcto. Si no desea introducir nada, déjelo en blanco. "});
        } else if(formData.cellphone && !validateNumber(formData.cellphone)) {
            setFormError({ cellphone: "El teléfono de celular no es válido. Si no desea introducir nada, déjelo en blanco. "});
        } else {
            setLoading(true);
            axios.post(Global.url + 'add-new-patient', formData, { headers: { "authorization" : `Bearer ${getLocalStorage()}`} })
                .then(res => {
                    setMsg(res.data.message);
                    if(res.data.status) {
                        setInfoUser(prevValue => ({...prevValue, "limitUser": res.data.infoUserUpdated }));
                        setIsOK("messageUserData__success");
                        setFormData({
                            "name": "",
                            "secondName": "",
                            "lastname": "",
                            "secondLastname": "",
                            "dateBirth": "",
                            "sex": "",
                            "typeDNI": "",
                            "dni": "",
                            "obrasocial": "",
                            "nro": "",
                            "cellphone": "",
                            "email": "",
                            "qrPhotoPami": "",
                            "codePamiQR": ""
                        });
                    } else {
                        setIsOK("messageUserData__fail");
                    }
                }).catch(err => {
                    setIsOK("messageUserData__fail");
                    console.log(err);
                }).finally(() => {
                    setDoneMsg(true);
                    setLoading(false);
                });
        }
    }

    return (
        <>
        <Header as='h3' className="headerUserPanel">
            Crear nuevo paciente
        </Header>
        <Form>
            <Form.Group widths='equal'>
                <Form.Input 
                    fluid label='Nombre' 
                    name="name"
                    required
                    value={formData.name}
                    placeholder='Nombre'
                    onChange={e => setFormData({...formData, [e.target.name]: e.target.value}) }
                />
                <Form.Input 
                    fluid label='Segundo nombre' 
                    name="secondName"
                    value={formData.secondName}
                    placeholder='Segundo nombre'
                    onChange={e => setFormData({...formData, [e.target.name]: e.target.value}) }
                />
            </Form.Group>
            <Form.Group widths='equal'>
                <Form.Input 
                    fluid label='Apellido'
                    name="lastname"
                    required
                    value={formData.lastname}
                    placeholder='Apellido'
                    onChange={e => setFormData({...formData, [e.target.name]: e.target.value}) }
                />
                <Form.Input 
                    fluid label='Segundo apellido'
                    name="secondLastname"
                    value={formData.secondLastname}
                    placeholder='Segundo apellido'
                    onChange={e => setFormData({...formData, [e.target.name]: e.target.value}) }
                />
            </Form.Group>
            <Form.Group widths='equal'>
                <Form.Field 
                    control={Select}
                    options={sexOptions}
                    search
                    required
                    label="Sexo"
                    placeholder="Sexo"
                    name="sex"
                    value={formData.sex}
                    error={formError.sex}
                    onChange={(e, {value}) => setFormData({...formData, sex: value}) }
                />
                <Form.Input 
                    fluid label='Fecha de nacimiento'
                    name="dateBirth"
                    type="date"
                    max={moment().subtract(1, "days").format("YYYY-MM-DD")}
                    value={formData.dateBirth}
                    error={formError.dateBirth}
                    placeholder='Fecha de nacimiento'
                    onChange={e => setFormData({...formData, [e.target.name]: e.target.value}) }
                />
            </Form.Group>
            <Form.Group widths='equal'>
                <Form.Field 
                    control={Select}
                    options={dniOptions}
                    search
                    required
                    label="Tipo de documento"
                    placeholder="Tipo de documento"
                    name="typeDNI"
                    value={formData.typeDNI}
                    error={formError.typeDNI}
                    onChange={(e, {value}) => setFormData({...formData, typeDNI: value}) }
                />
                <Form.Input 
                    fluid label='Número de documento'
                    name="dni"
                    type="number"
                    required
                    value={formData.dni}
                    error={formError.dni}
                    placeholder='Documento de identidad, sin puntos ni coma.'
                    onChange={e => setFormData({...formData, [e.target.name]: e.target.value}) }
                />
            </Form.Group>
            <Form.Group widths='equal'>
                <Form.Input 
                    fluid name="obrasocial" 
                    label='Obra social (si posee)' 
                    value={formData.obrasocial}
                    error={formError.obrasocial}
                    placeholder='Si no posee obra social, déjelo en blanco.'
                    onChange={e => setFormData({...formData, [e.target.name]: e.target.value}) }
                />
                <Form.Input 
                    fluid 
                    name="nro" 
                    label='Número de obra social (si posee)' 
                    error={formError.nro}
                    value={formData.nro}
                    placeholder='Si no posee obra social, déjelo en blanco.'
                    onChange={e => setFormData({...formData, [e.target.name]: e.target.value}) }
                />
            </Form.Group>
            <Form.Group widths='equal'>
            <Form.Input 
                    fluid name="qrPhotoPami" 
                    label='Link de la credencial de PAMI (Sacado del QR, si corresponde)' 
                    value={formData.qrPhotoPami}
                    error={formError.qrPhotoPami}
                    placeholder='Si no posee ninnguna dirección, no subir nada.'
                    onChange={e => setFormData({...formData, [e.target.name]: e.target.value}) }
                />
                <Form.Input 
                    fluid 
                    name="codePamiQR" 
                    label='Número de 3 dígitos de PAMI (si corresponde)' 
                    error={formError.codePamiQR}
                    value={formData.codePamiQR}
                    placeholder='Si no posee PAMI, déjelo en blanco.'
                    onChange={e => setFormData({...formData, [e.target.name]: e.target.value}) }
                />
            </Form.Group>
            <Form.Group widths='equal'>
                <Form.Input 
                    fluid name="email"
                    label='Email de contacto (para enviarle las recetas)'
                    placeholder='Email de contacto'
                    error={formError.email}
                    value={formData.email}
                    type="email"
                    onChange={e => setFormData({...formData, [e.target.name]: e.target.value}) }
                />
                <Form.Field>
                    <div className="labelFormPhone">Teléfono celular de contacto</div>
                    <PhoneInput
                        placeholder='Teléfono celular de contacto'
                        inputClass="inputStylePhone"
                        country='ar'
                        preferredCountries={['ar', 'uy', 'cl', 'br', 'py', 'bo', 've', 'co', 'us', 'es', 'ec', 'pe', 'mx']}
                        name="cellphone"
                        localization={es}
                        inputProps={{
                            name: 'cellphone',
                            required: true
                        }}
                        value={formData.cellphone}
                        error={formError.cellphone}
                        onChange={(value) => setFormData({ ...formData, "cellphone": value })}
                    />
                </Form.Field>
                {/* <Form.Input 
                    fluid name="cellphone"
                    label='Teléfono celular de contacto (para enviarle las recetas por WhatsApp)'
                    placeholder='Teléfono celular de contacto'
                    error={formError.cellphone}
                    value={formData.cellphone}
                    onChange={e => setFormData({...formData, [e.target.name]: e.target.value}) }
                /> */}
            </Form.Group>
            {doneMsg && 
                <div className="messageDiv">
                    <Message className={isOK === "messageUserData__fail" ? "messageUserData messageUserData__fail" : "messageUserData messageUserData__success"}>
                        {msg}
                    </Message>
                </div>
            }
            <Form.Button loading={loading} disabled={loading} onClick={() => saveData()} className="btnSubmit btnChangePersonalDate">Crear nuevo paciente</Form.Button>
        </Form>
        </>
    )
}
