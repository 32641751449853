import React, { useState, useEffect } from 'react';
import { Form, Header, Message, Button, Table, Menu, Icon } from "semantic-ui-react";
import "./CancelReceta.scss";
import moment from "moment";
import axios from "axios";
import Global from "../../../../utils/Global";
import { getLocalStorage } from '../../../../utils/localStorage';
import { saniziteNumbers } from "../../../../utils/saniziteValues";
import ModalComponent from '../../../ModalComponent/ModalComponent';

export default function CancelReceta() {

    const [allRecets, setAllRecets] = useState([]);
    const [loading, setLoading] = useState(false);

    const [doneMsg, setDoneMsg] = useState(false);
    const [isOK, setIsOK] = useState("messageUserData__fail");
    const [msg, setMsg] = useState("");

    const [openModal, setOpenModal] = useState(false);
    const [contentModal, setContentModal] = useState("");

    const [resultsSearch, setResultsSearch] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [searching, setSearching] = useState(false);
    const [gotValues, setGotValues] = useState(false);
    const [selectedPatient, setSelectedPatient] = useState(null);

    const [selectedReceta, setSelectedReceta] = useState(null);

    const [formError, setFormError] = useState({});

    const [loadingCancel, setLoadingCancel] = useState(false);

    const [offset, setOffset] = useState(0);
    const [countPages, setCountPages] = useState(0);
    const [actualPage, setActualPage] = useState(1);
    const [startLimit, setStartLimit] = useState(1);

    useEffect(() => {
        if(selectedPatient !== null) {
            axios.post(Global.url + 'count-recets-patient', { selectedPatient: selectedPatient, excluded: ["EXPIRED", "APPROVED"] }, { headers: { "authorization" : `Bearer ${getLocalStorage()}`} })
                .then(res => {
                    setCountPages(res.data.result);
                }).catch(err => {
                    setCountPages(0);
                });
        }
    }, [selectedPatient]);

    useEffect(() => {
        if(selectedPatient !== null) {
            axios.post(Global.url + 'load-recets-patient', { offset: offset, selectedPatient: selectedPatient, excluded: ["EXPIRED", "APPROVED"] }, { headers: { "authorization" : `Bearer ${getLocalStorage()}`} })
                .then(res => {
                    setAllRecets(res.data.result);
                }).catch(err => {
                    setAllRecets([]);
                });
        } else {
            setAllRecets([]);
        }
    }, [selectedPatient, countPages, actualPage]);


    const searchPatient = value => {
        setSearchValue(value);
        setSearching(true);
        setGotValues(false);
        if(value.length >= 2) {
            setTimeout(function() {
                axios.post(Global.url + 'search-patient', { search: value }, { headers: { "authorization" : `Bearer ${getLocalStorage()}`} })
                    .then(res => {
                        setResultsSearch(res.data.results);
                    }).catch(err => {
                        setResultsSearch([]);
                    }).finally(() => {
                        setSearching(false);
                        setGotValues(true);
                });
            }, 300);
        } else {
            setSearching(true);
            setGotValues(false);
        }
    }

    const selectPatient = item => {
        setSearchValue("");
        setSelectedPatient(item);
        setGotValues(false);
        setResultsSearch([]);
    }

    const AnularReceta = (recet) => {
        if(!selectedPatient) {
            setFormError({ patient: "El campo paciente es obligatorio. "});
        } else {
            setSelectedReceta(recet);
            setContentModal(<InfoAnularReceta recet={recet} selectedPatient={selectedPatient} />);
            setOpenModal(true);
        }
    }

    const ConfirmAnular = () => {
        setLoadingCancel(true);
        axios.post(Global.url + 'cancel-recet', { recet: selectedReceta }, { headers: { "authorization" : `Bearer ${getLocalStorage()}`} })
            .then(res => {
                setSelectedPatient(null);
                setSelectedReceta(null);
                if(res.data.status) {
                    setIsOK(res.data.result);
                }
                setMsg(res.data.message);
            }).catch(err => {
                setIsOK("messageUserData__fail");
                setMsg("Hubo un error en el servidor, por favor intente más tarde. ");
            }).finally(() => {
                setOpenModal(false);
                setDoneMsg(true);
                setLoadingCancel(false);
            });
    }

    const movePage = direction => {
        if(direction) {
          if(actualPage !== countPages) {
            setOffset(offset + 10);
            setActualPage(actualPage + 1);
            setStartLimit(startLimit + 1);
          }
        } else {
          if(actualPage > 1) {
            setOffset(offset - 10);
            setActualPage(actualPage - 1);
            setStartLimit(startLimit - 1);
          }
        }
      }
    
    const goToPage = (result, page) => {
        setOffset(result);
        setActualPage(page);
    }

    return (
        <>
            <Header as='h3' className="headerUserPanel">
                Anular receta existente
            </Header>
            <Form>
                <Form.Input
                    onChange={e => searchPatient(e.target.value)}
                    fluid label='Paciente' 
                    name="patient"
                    value={searchValue}
                    error={formError.patient}
                    icon={searching ? "circle notch" : "search"}
                    placeholder='Buscar paciente (nombre, apellido o DNI)'
                />
                {gotValues &&
                    <div className="listValuesFound listValuesFound__Patients">
                        {resultsSearch.length === 0 && 
                            <div className="aValueFound">No se encontró ningún paciente que coincida. </div>
                        }
                        {resultsSearch.map((item) => (
                            <div 
                                onClick={() => selectPatient(item)} 
                                className="aValueFound"
                                key={item._id}
                            >
                                {item.name} {item.secondName} {item.lastname} {item.secondLastname} - <strong>{item.typeDNI}</strong>: {saniziteNumbers(item.dni)} - <strong>Obra social</strong>: {item.obrasocial}
                            </div>
                        ))}
                    </div>
                }
                {selectedPatient !== null &&
                    <div className="infoSelectedPatient">
                        <span className="textSelectedPatient"><strong>Paciente:</strong> <span>{selectedPatient.name} {selectedPatient.secondName} {selectedPatient.lastname} {selectedPatient.secondLastname}</span></span>
                        <span className="textSelectedPatient"><strong>Documento:</strong> <span>{selectedPatient.typeDNI} {saniziteNumbers(selectedPatient.dni)}</span></span>
                        <span className="textSelectedPatient"><strong>Obra social:</strong> <span>{selectedPatient.obrasocial} - {selectedPatient.nro}</span></span>
                    </div>
                }
                {doneMsg && 
                    <div className="messageDiv">
                        <Message className={isOK === "messageUserData__fail" ? "messageUserData messageUserData__fail" : "messageUserData messageUserData__success"}>
                            {msg}
                        </Message>
                    </div>
                }
            </Form>
            <Table celled>
                <Table.Header>
                    <Table.Row>
                    <Table.HeaderCell colSpan='6'>Últimas recetas añadidas</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Header>
                    <Table.Row>
                    <Table.HeaderCell collapsing>Fecha de inicio</Table.HeaderCell>
                    <Table.HeaderCell>CIE10</Table.HeaderCell>
                    <Table.HeaderCell>Medicamento 1</Table.HeaderCell>
                    <Table.HeaderCell>Medicamento 2</Table.HeaderCell>
                    <Table.HeaderCell>Fecha de vencimiento</Table.HeaderCell>
                    <Table.HeaderCell collapsing>Opciones</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {allRecets.length ?
                    allRecets.map((recet) => (
                        <RowTable key={recet._id} recet={recet} AnularReceta={AnularReceta} />
                    )) :
                    <Table.Row>
                        <Table.Cell colSpan="6"><div className="patientsNotFoundTable">No tenés ninguna receta cargada. </div></Table.Cell>
                    </Table.Row>
                    }
                </Table.Body>
                <Table.Footer>
                    <Table.Row>
                    <Table.HeaderCell colSpan='6'>
                        <Menu floated='right' pagination>
                        <Menu.Item as='a' icon onClick={() => movePage(false)}>
                            <Icon name='chevron left' />
                        </Menu.Item>
                        {Array.from(Array(2), (e, i) => (
                            ((startLimit+i) !== 0 && (startLimit+i) <= countPages) &&
                            (<Menu.Item as='a' key={startLimit+i} onClick={() => goToPage(((startLimit+i)*10-10), startLimit+i)} className={actualPage === (startLimit+i) ? "activeItemMenu" : "ItemMenu" }>{startLimit+i}</Menu.Item>)
                        ))}
                        <Menu.Item as='a' icon onClick={() => movePage(true)}>
                            <Icon name='chevron right' />
                        </Menu.Item>
                        </Menu>
                    </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>
            <ModalComponent openModal={openModal} setOpenModal={setOpenModal} titleModal="Anular receta" size="tiny" contentYes="Anular receta" functionToDo={ConfirmAnular} loading={loadingCancel}>
                {contentModal}
            </ModalComponent>
        </>
    )
}


function RowTable(props) {

    const { recet, AnularReceta } = props;

    return(
      <Table.Row className={recet.approved === "CANCELED" ? "tableCanceledRecet" : "tableWaitingRecet"}>
        <Table.Cell>{moment(recet.startDate).format("DD/MM/YYYY")}</Table.Cell>
        <Table.Cell>{recet.diagnostic}</Table.Cell>
        <Table.Cell>{recet.remedio1}</Table.Cell>
        <Table.Cell>{recet.remedio2}</Table.Cell>
        <Table.Cell>{moment(recet.startDate).add(30, 'days').format("DD/MM/YYYY")}</Table.Cell>
        <Table.Cell>
            {recet.approved === "CANCELED" ? <div>Anulada</div> : <Button className="tableCancelBtn" onClick={() => AnularReceta(recet)}>Anular</Button> }
        </Table.Cell>
      </Table.Row>
    )
  }

function InfoAnularReceta(props) {
    const { recet, selectedPatient } = props;
    return (
        <>
        <Message warning>
            <Message.Header>Advertencia</Message.Header>
            <Message.Content>Esta opción es irreversible. </Message.Content>
        </Message>
        <Table>
            <Table.Body>
                <Table.Row>
                    <Table.Cell>
                        <div><strong>Fecha</strong></div>
                    </Table.Cell>
                    <Table.Cell>
                        <div>{moment(recet.startDate).format("DD/MM/YYYY")}</div>
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        <div><strong>Paciente</strong></div>
                    </Table.Cell>
                    <Table.Cell>
                        <div>{selectedPatient.name} {selectedPatient.lastname}</div>
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        <div><strong>Diagnóstico</strong></div>
                    </Table.Cell>
                    <Table.Cell>
                        <div>{recet.diagnostic}</div>
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        <div><strong>Medicamento 1</strong></div>
                    </Table.Cell>
                    <Table.Cell>
                        <div>{recet.remedio1}</div>
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        <div><strong>Medicamento 2</strong></div>
                    </Table.Cell>
                    <Table.Cell>
                        <div>{recet.remedio2}</div>
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        <div><strong>Fecha de vencimiento</strong></div>
                    </Table.Cell>
                    <Table.Cell>
                        <div>{moment(recet.startDate).add(30, 'days').format("DD/MM/YYYY")}</div>
                    </Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>
        </>
    )
}