import React from 'react';
import "./Header.scss";
import { NavLink } from "react-router-dom";
import { isMobile } from "react-device-detect";
import logo from "../../assets/images/logo.png";
import { Grid, Button, Image } from "semantic-ui-react";

export default function Header(props) {

    const { isLogged, isLoaded } = props;

    return (
        <Grid id="header" columns="equal">
            <Grid.Column className={isMobile ? "titlePageMobile" : "titlePage"} width={isMobile ? 16 : 6} floated="left">
                <NavLink to="/"><Image src={logo} className={isMobile ? "logoMobile" : "logo"} /></NavLink>
            </Grid.Column>
            <Grid.Column floated="right" className={isMobile ? "userMenuMobile" : "userMenu"}>
                {isLoaded &&
                    (() => {
                        if (isLogged)
                            return (
                                <>
                                <Button as={NavLink} to="/dashboard" className="btnLogin">Ir al tablero</Button>
                                <Button as={NavLink} to="/logout" className="btnLogout" color='red'>Cerrar sesión</Button>
                                </>
                            )
                        else
                            return (
                                <>
                                <NavLink to="/" className="navLinksHeader">¿Quiénes somos?</NavLink>
                                <NavLink to="/" className="navLinksHeader">Precios</NavLink>
                                <Button as={NavLink} to="/login" className="btnLogin">Iniciar sesión</Button>
                                <Button as={NavLink} to="/register" className="btnRegister">Registrarse</Button>
                                </>
                            )
                    })()
                }
            </Grid.Column>
        </Grid>
    )
}
