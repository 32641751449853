import React, { useState, useEffect } from 'react';

import "./RecoverPassword.scss";

import Global from "../../utils/Global";
import axios from "axios";

import { isMobile } from "react-device-detect";

import { Form, Message, Icon, Button, Input, Container, Header } from 'semantic-ui-react';

export default function RecoverPassword(props) {

    const { setIsLogged, isLogged, match } = props;

    useEffect(() => {
        if(isLogged) {
            props.history.push("/dashboard");
        }
    }, [isLogged]);

    const [doneMsg, setDoneMsg] = useState(false);
    const [headerMsg, setHeaderMsg] = useState("");
    const [contentMsg, setContentMsg] = useState("");
    const [successMsg, setSuccessMsg] = useState("warning");

    const [loading, setLoading] = useState(false);
    const [visiblePass, setVisiblePass] = useState(false);

    const [formError, setFormError] = useState({});
    const [formData, setFormData] = useState({
        "password": "",
        "repeatPassword": ""
    });

    const sendNewPassword = () => {
        if(!formData.password || !formData.repeatPassword) {
            setFormError({ password: "Debes introducir una contraseña. "});
        } else if(formData.password < 8) {
            setFormError({ password: "La contraseña debe tener al menos 8 carácteres. "});
        } else if(formData.password !== formData.repeatPassword) {
            setFormError({ repeatPassword: "Las nuevas contraseñas no coinciden. "});
        } else {
            setFormError({});
            setLoading(true);
            axios.post(Global.url + 'confirm-recover-password', { formData: formData, code: match.params.token })
                .then(res => {
                    setSuccessMsg(res.data.success);
                    setContentMsg(res.data.message);
                    setHeaderMsg(res.data.header);
                    if(res.data.success) {
                        setTimeout(function() {
                            setIsLogged(false);
                            props.history.push("/login");
                        }, 1500);
                    }
                }).catch(err => {
                    setSuccessMsg("error");
                    setContentMsg("Hubo un error interno del servidor. Por favor, intente más tarde. ");
                    setHeaderMsg("¡Ups! Algo falló");
                }).finally(() => {
                    setDoneMsg(true);
                    setLoading(false);
                });
        }
    }

    return (
        <Container className={isMobile ? "recoverContainerMobile" : "recoverContainer"}>
            <Header as="h3">
                Recuperar contraseña
            </Header>
            <Form className={isMobile ? "formRecoverPassMobile" : "formRecoverPass"}>
                <Form.Field
                    id='form-input-password'
                    control={Input}
                    label='Ingrese nueva contraseña'
                    placeholder='Ingrese nueva contraseña'
                    required
                    type={visiblePass ? "text" : "password"}
                    name="password"
                    error={formError.password}
                    icon={<Icon name={visiblePass ? 'eye slash' : 'eye'} link onClick={() => setVisiblePass(prevValue => !prevValue)} />}
                    onChange={e => setFormData({ ...formData, [e.target.name]: e.target.value })}
                />
                <Form.Field
                    id='form-input-repeat-password'
                    control={Input}
                    label='Repita nueva contraseña'
                    placeholder='Repita nueva contraseña'
                    required
                    type={visiblePass ? "text" : "password"}
                    name="repeatPassword"
                    error={formError.repeatPassword}
                    icon={<Icon name={visiblePass ? 'eye slash' : 'eye'} link onClick={() => setVisiblePass(prevValue => !prevValue)} />}
                    onChange={e => setFormData({ ...formData, [e.target.name]: e.target.value })}
                />
                <Button loading={loading} disabled={loading} className="btnLoginNow" onClick={() => sendNewPassword()} color='red'>
                    Recuperar contraseña
                </Button>
            </Form>
            {doneMsg &&
                <Message color={successMsg} className="msgStyle">
                    <Message.Header>{headerMsg}</Message.Header>
                    <Message.Content>{contentMsg}</Message.Content>
                </Message>
            }
        </Container>
    )
}
