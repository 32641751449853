function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

function validateUser(user) {
    var re = /^[a-zA-Z0-9_\-]{4,}$/;
    return re.test(String(user).toLowerCase());
}

function validateNumber(number) {
    var re = /^[0-9\-\+\., ]{4,}$/;
    return re.test(String(number).toLowerCase());
}

export { validateEmail, validateUser, validateNumber };