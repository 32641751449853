import { useState, useEffect } from 'react';
import axios from 'axios';
import Global from "./Global";
import { getLocalStorage } from './localStorage';

export default function UserLogged() {
    const [isLogged, setIsLogged] = useState(false);
    const [isLoaded, setIsLoaded] = useState(undefined);
    useEffect(() => {
        (async () => {
            try {
                const result = await axios.post(Global.url + "check-token-signed", null, { headers: { "authorization" : `Bearer ${getLocalStorage()}`} });
                setIsLogged(result.data.status);
                setIsLoaded(result.data.loaded);
            } catch(err) {
                setIsLogged(false);
                setIsLoaded(true);
            }
        })();
    }, []);
    return { setIsLogged, isLogged, isLoaded };
}
