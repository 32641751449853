import React, { useState, useEffect } from 'react';

import "./Farmacias.scss";

import axios from "axios";
import Global from "../../utils/Global";

import { Container, Grid, Menu } from "semantic-ui-react";
import { isMobile } from "react-device-detect";
import { getLocalStorage } from '../../utils/localStorage';

//components dashboard
import Home from "../../components/Farmacias/Home/Home";

import LoadingPage from "../../components/LoadingPage";

export default function Farmacias(props) {

    const { isLogged, isLoaded } = props;

    const [infoPharmacy, setInfoPharmacy] = useState(null);
    const [farmaciaLoaded, setFarmaciaLoaded] = useState(false);
    const [activeItem, setActiveItem] = useState("home");

    useEffect(() => {
        if(!isLogged && isLoaded) {
            props.history.push("/login");
        }
    }, [isLogged, isLoaded]);


    useEffect(() => {
        if(isLogged) {
            axios.post(Global.url + 'get-data-pharmacy', null, { headers: { "authorization" : `Bearer ${getLocalStorage()}`} })
            .then(res => {
                if(res.data.status && res.data.authorized) {
                    setInfoPharmacy(res.data.result);
                    setFarmaciaLoaded(true);
                } else {
                    props.history.push("/dashboard");
                }
            }).catch(err => {
                props.history.push("/");
            });
        }
    }, [isLogged]);


    if(isLogged && isLoaded && farmaciaLoaded && infoPharmacy !== null) {
        return (
            <Container className="bgDashboard bgDashboard__Admin">
                <Menu secondary pointing className={isMobile ? "menuDashboard menuDashboardMobile" : "menuDashboard menuDashboard__Admin"} style={{ height: isMobile ? "100%" : "50px !important" }}>
                    <Menu.Item
                        name='Inicio de Farmacia'
                        content="Inicio de farmacia"
                        active={activeItem === "home"}
                        onClick={() => setActiveItem("home")}
                        className={isMobile ? "itemMobile" : "itemNoMobile"}
                    />
                    { !isMobile &&
                        <Menu.Menu position="right">
                            <Menu.Item
                                name='Salir'
                                active={activeItem === "logout"}
                                onClick={() => setActiveItem("logout")}
                                className={isMobile ? "itemMobile" : "itemNoMobile"}
                            />
                        </Menu.Menu>
                    }
                </Menu>
                <Grid className="gridDashboard">
                    <Grid.Column width={16} className="dashboardContent">
                        {activeItem === "home" &&
                            <Home infoPharmacy={infoPharmacy} />
                        }
                    </Grid.Column>
                </Grid>
            </Container>
        )
    } else {
        return <LoadingPage />;
    }
    
}
