import React from 'react';

import "./LoadingPage.scss";

import { Segment, Dimmer, Loader } from "semantic-ui-react";

export default function LoadingPage() {
    return (
        <Dimmer.Dimmable as={Segment}  className="loadingPage" dimmed={true}>
            <Dimmer active inverted>
                <Loader size='big'>Cargando...</Loader>
            </Dimmer>
        </Dimmer.Dimmable>
    )
}
